import { DateTime } from 'luxon'
import { useCallback, useMemo, useState } from 'react'
import { Button, Card, Form, Table } from 'react-bootstrap'
import { CommonProperties } from '../common/common.interfaces'
import { SalsifyConfigurationCombinedTableRow } from './salsify.interfaces'
import { notify } from '../notify/notify'

interface SalsifyConfigurationComponentProperties extends CommonProperties {
  configs: SalsifyConfigurationCombinedTableRow[]
  salsifyConfigsTableService: any
  setLastRefresh: any
}

const SalsifyConfigurationComponent = (props: SalsifyConfigurationComponentProperties) => {
  const [changes, setChanges] = useState(undefined)
  const [error, setError] = useState(undefined)

  const handleCadenceChange = useCallback((e) => {
    let newChanges = changes ?? new Map()
    if(+(e.target.value) < 16){
      setError('Cadence must be greater than 15 minutes!')
    } else {
      setError(undefined)
    }
    newChanges.set(e.target.id, +(e.target.value))
    setChanges(new Map(newChanges))
  }, [changes])

  const handleSaveClicked = useCallback((e) => {
    const save = async () => {
      if(!changes || error) return
      try {
        for(const key of changes.keys()){
          const splitId = key.split('|')
          const pKey = splitId[0]
          const rKey = splitId[1]
          const source = props.configs.find(c => c.partitionKey === pKey && c.rowKey === rKey)
          if(source.cadenceInMinutes !== changes.get(key)){
            await props.salsifyConfigsTableService.upsertEntity({...source, cadenceInMinutes: changes.get(key)}, 'Replace')
            notify('success', `Successfully updated ${pKey} cadence from ${source.cadenceInMinutes} to ${changes.get(key)}!`)
            props.setLastRefresh(DateTime.utc().toMillis())
          }
        }
      }catch(error){
        notify('error', `Failed to update! ${error}`)
      }
    }
    save()
  }, [changes])
  
  const buildConfiguration = useMemo(() => {
    const tr = []
    for(const record of props.configs){
      const payload = record.response ? JSON.parse(record.response) : undefined
      tr.push(
        <tr>
          <td>{record.partitionKey}</td>
          <td>{record.lastRun} - {Math.ceil(Math.abs(DateTime.fromISO(record.lastRun).setZone('local').diffNow('minutes').minutes))} minute(s) ago</td>
          <td><Form.Control min={16} onChange={handleCadenceChange} type='number' id={`${record.partitionKey}|${record.rowKey}`} style={{width: '50px'}} defaultValue={record.cadenceInMinutes}></Form.Control></td>
          <td>{record.reportStatus ?? 'N/A'}</td>
          <td>{payload?.url ? <a href={payload.url}>Download Report</a> : 'N/A'}</td>
        </tr>
      )
    }

    let changesView = (<></>)
    if(changes){
      const changeText = []
      for(const key of changes.keys()){
        const splitId = key.split('|')
        const pKey = splitId[0]
        const rKey = splitId[1]
        const source = props.configs.find(c => c.partitionKey === pKey && c.rowKey === rKey)
        if(source.cadenceInMinutes !== changes.get(key)){
          changeText.push(
            <div><strong>{pKey}</strong> changing from <strong>{source.cadenceInMinutes}</strong> to <strong>{changes.get(key)}</strong></div>
          )
        }
      }
      if(changeText.length > 0){
        changesView = (
          <>
            <Card>
              <Card.Header>
                <Card.Title>Save Preview</Card.Title>
              </Card.Header>
              <Card.Body>
                {changeText}
              </Card.Body>
            </Card>
            <hr/>
          </>
        )
      }
    }

    return (
      <>
      <Button onClick={handleSaveClicked} variant='success'>Save</Button>
      <hr/>
      {changesView}
      <div style={{textAlign: 'center', color: 'darkred'}}><strong>{error}</strong></div>
      
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Record Type</th>
            <th>Process Last Ran</th>
            <th>Cadence in Minutes</th>
            <th>Report Status</th>
            <th>Download Report</th>
          </tr>
        </thead>
        <tbody>
          {tr}
        </tbody>
      </Table>
      </>
    )
  }, [props.configs, handleCadenceChange, changes, handleSaveClicked, error])

  return buildConfiguration
}

export default SalsifyConfigurationComponent
