import { ScanEvents } from "../../common/common.types"

export const fedexDelayReason = [
  {id: 1, reason: 'Weather delay'},
  {id: 2, reason: 'Barcode label unreadable and replaced'},
  {id: 3, reason: 'Delivery delayed, scheduled for next business day'},
  {id: 4, reason: 'Delivery updated, scheduled for next business day'},
  {id: 5, reason: 'No attempt made, delivery scheduled for next business day'},
  {id: 6, reason: 'Future delivery requested'},
  {id: 7, reason: 'Customer not available or business closed'}
]

export const findFedExDelayReason = (scanEvents: ScanEvents[]): string => {
  const reasonCounts = new Map<string, number>();

  // Count occurrences of each unique reason
  scanEvents?.forEach((event) => {
    if (event.exceptionDescription) {
      const matchedReason = fedexDelayReason.find(
        (reason) => reason.reason.toLowerCase() === event.exceptionDescription.toLowerCase()
      );
      if (matchedReason) {
        reasonCounts.set(
          matchedReason.reason,
          (reasonCounts.get(matchedReason.reason) || 0) + 1
        );
      }
    }
  });

  // Format the output
  const formattedReasons = Array.from(reasonCounts.entries()).map(
    ([reason, count]) => (count > 1 ? `(${count}x) ${reason}` : reason)
  );

  // Join reasons with a new line
  return formattedReasons.length > 0 ? formattedReasons.join('\n') : '';
};