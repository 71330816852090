import { useCallback, useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { notify } from '../notify/notify'

interface AmazonSFPConfigAdvancedEditProps {
  configName: string
  content: string
  visible: boolean
  closeModal: any
  saveConfig: any
}

const AmazonSFPConfigAdvancedEdit = (props: AmazonSFPConfigAdvancedEditProps) => {
  const [updatedContent, setUpdatedContent] = useState(undefined)

  useEffect(() => {
    setUpdatedContent(props.content)
  }, [])

  const contentUpdated = useCallback((e) => {
    setUpdatedContent(e.target.value)
  }, [])

  const saveContent = useCallback((e) => {
    try{
      const parsed = JSON.parse(updatedContent)
      props.saveConfig(JSON.stringify(parsed, null, 2), props.configName)
    }catch(error){
      notify('error', `Failed to parse content as JSON.`)
      console.log(error)
    }
  }, [updatedContent])

  return (
    <Modal show={props.visible} onHide={props.closeModal} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{props?.configName?.split('/')?.pop()} Advanced JSON Edit</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Form>
        <Form.Control onChange={contentUpdated} as="textarea" defaultValue={props.content} rows={12} />
      </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.closeModal}>
          Cancel
        </Button>
        <Button variant='success' onClick={saveContent}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default AmazonSFPConfigAdvancedEdit
