import { createContext, useState, useContext, ReactNode } from 'react';

interface SpinnerContextType {
  loading: boolean;
  showSpinner: () => void;
  hideSpinner: () => void;
  toggleSpinner: (state: boolean) => void;
}

const SpinnerContext = createContext<SpinnerContextType | undefined>(undefined);

export const SpinnerProvider = ({ children }: { children: ReactNode }) => {
  const [loading, setLoading] = useState(false);

  const showSpinner = () => setLoading(true);
  const hideSpinner = () => setLoading(false);
  const toggleSpinner = (state: boolean) => setLoading(state);

  return (
    <SpinnerContext.Provider value={{ loading, showSpinner, hideSpinner, toggleSpinner }}>
      {children}
    </SpinnerContext.Provider>
  );
};

export const useSpinner = (): SpinnerContextType => {
  const context = useContext(SpinnerContext);
  if (!context) {
    throw new Error('useSpinner must be used within a SpinnerProvider');
  }
  return context;
};
