import { CarrierInvoice } from './carrier.invoice.audit.interfaces'

const FedexFields = {
  AccountNumber: 'Payer Account',
  InvoiceDate: 'Invoice Date (mm/dd/yyyy)',
  InvoiceNumber: 'Invoice Number',
  NetDue: 'Net Charge Amount USD',
  OrderNumber: 'Reference Notes Line 1',
  Products: 'PO Number',
  TrackingNumber: 'Shipment Tracking Number'
}

export function mapFedexToCarrierInvoices(data: string): CarrierInvoice[] {
  const carrierInvoices: CarrierInvoice[] = []
  try {
    const lines = data.split('\n')
    let headers = lines[0].split('\t')

    const accountNumberIndex = headers.indexOf(FedexFields.AccountNumber)
    const invoiceDateIndex = headers.indexOf(FedexFields.InvoiceDate)
    const invoiceNumberIndex = headers.indexOf(FedexFields.InvoiceNumber)
    const trackingNumberIndex = headers.indexOf(FedexFields.TrackingNumber)
    const netDueIndex = headers.indexOf(FedexFields.NetDue)
    const orderNumberIndex = headers.indexOf(FedexFields.OrderNumber)
    const orderProductsIndex = headers.indexOf(FedexFields.Products)
    let index = 0
    for(const line of lines){
      index += 1
      if(index === 1) continue
  
      let lineSplit = line.split('\t')

      carrierInvoices.push({
        Account: lineSplit[accountNumberIndex].replace(/"/g, ''),
        InvoiceDate: lineSplit[invoiceDateIndex].replace(/"/g, ''),
        InvoiceNumber: lineSplit[invoiceNumberIndex].replace(/"/g, ''),
        TrackingNumber: lineSplit[trackingNumberIndex].replace(/"/g, ''),
        NetDue: `$${lineSplit[netDueIndex].replace(/"/g, '')}`,
        OrderNumber: lineSplit[orderNumberIndex].replace(/"/g, ''),
        Products: lineSplit[orderProductsIndex].replace(/"/g, ''),
        NetDueNumber: +lineSplit[netDueIndex].replace(/"/g, '')
      })
    }
  }catch(error){
    console.error(error)
  }

  return carrierInvoices
}