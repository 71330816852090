import { createContext, useContext, useState, useEffect, useMemo } from 'react';
import { useMsal } from '@azure/msal-react';
import { InteractiveBrowserCredential } from '@azure/identity';
import { CLIENT_ID, TENANT_ID } from '../authConfig';
import { TableClient } from '@azure/data-tables';
import { DATA_SERVICES_TABLES } from '../common/common.constants';
interface UserData {
  roles: string[];
  friendlyUserName: string;
  foundUser: any;
}

interface UserContextProp {
  userData: UserData | null;
  loading: boolean;
}

// Create the UserContext
const UserContext = createContext<UserContextProp | undefined>(undefined)

export const useUserContext = () => useContext(UserContext)

const UserContextProvider = ({ children }) => {
  const { accounts } = useMsal()
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);

  const memoizedAcct = useMemo(() => accounts, [accounts]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (memoizedAcct.length === 0) return;

        const credential = new InteractiveBrowserCredential({
          tenantId: TENANT_ID,
          clientId: CLIENT_ID,
          authorityHost: "https://login.microsoft.com",
        })

        const rolesTableClient = new TableClient(
          DATA_SERVICES_TABLES,
          "crystalBallRoles",
          credential
        )

        const queryOptions = {
          queryOptions: {
            filter: `PartitionKey eq 'user' and RowKey eq '${encodeURIComponent(memoizedAcct[0].username)}'`
          }
        }

        const users = rolesTableClient.listEntities(queryOptions);
        let foundUser = null;
        for await (const record of users) {
          foundUser = record
        }

        let roles = null;
        let friendlyUserName = null;

        if(!foundUser){
          await rolesTableClient.upsertEntity(
            {
              partitionKey: 'user', 
              rowKey: encodeURIComponent(memoizedAcct[0].username), 
              roles: JSON.stringify(['new']), 
              username: memoizedAcct[0].username
            }, 
            'Replace'
          )
          roles = ['new']
          friendlyUserName = memoizedAcct[0].username
        } else {
          roles = JSON.parse(foundUser.roles)
          friendlyUserName = foundUser.username ?? memoizedAcct[0].username
        }

        const user_data = {
          roles,
          friendlyUserName,
          foundUser
        }

        setUserData(user_data)
      } catch (error) {
        console.error("Error fetching user data:", error)
      } finally {
        setLoading(false);
      }
    }

    fetchUserData()
  }, [memoizedAcct])

  return (
    <UserContext.Provider value={ {userData, loading} }>
      {children}
    </UserContext.Provider>
  )
}

export default UserContextProvider
