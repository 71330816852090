import { Table as Row, Col, Container} from "react-bootstrap"
import { ProjectedGraphDataProvider, useProjectedGraphDataContext } from "../contexts/amazonPageSpeedViewsProjectedDataContext";
import AmazonProjectedViewGraph from "./amazonPageSpeedViewsProjectedGraphComponent";
import AmazonViewProjectedSummary from "./amazonPageViewsSpeedViewsProjectedWeekComponent";
import AmazonDayByDayProjected from "./amazonProjectedDayByDayViewsComponent";
import ProjectedASINTableComponent from "./amazonProjectedASINTable";

const AmazonProjectedMetricsTab = () => {

  const {
    rollingError
  } = useProjectedGraphDataContext()

  if (rollingError) return <div>{rollingError.message}</div>

  return (
    <div>
      <Row className="mb-4">
        <Col md={8}>
          <AmazonProjectedViewGraph />
        </Col>
        <Col md={4}>
          <AmazonViewProjectedSummary />
          <AmazonDayByDayProjected />
        </Col>
      </Row>
      <Row>
        <ProjectedASINTableComponent />
      </Row>
    </div>
  )
}

export default function AmazonProjectedMetricsTabWrapper() {
  return (
    <ProjectedGraphDataProvider>
      <AmazonProjectedMetricsTab />
    </ProjectedGraphDataProvider>
  );
}