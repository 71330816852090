import { DateTime } from "luxon"

const Footer = () => {
  return (
    <footer className='d-flex justify-content-center align-items-center'>
      Copyright {DateTime.now().year}&copy; Net Health Shops, LLC. All trademarks and images are copyright of Net Health Shops, LLC.
    </footer>
  )
}

export default Footer