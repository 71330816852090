import { Table as Container, Row, Col, Tabs, Tab} from "react-bootstrap"
import AmazonProjectedMetricsTabWrapper from "./components/amazonProjectedMetricsTab"
import AmazonCurrentMetricsTabWrapper from "./components/amazonCurrentMetricsTab"

const AmazonPageSpeedViews = () => {

  return (
    <Container className="p-3 fs-4">
      <Row className="justify-content-md-center fs-1 mb-4">Oversize Amazon Page Speed Views</Row>
      <Tabs
        defaultActiveKey='current-metrics'
        id="justify-tab-example"
        className="mb-3"
        justify
      >
        <Tab eventKey="current-metrics" title="Current Metrics">
          <AmazonCurrentMetricsTabWrapper />
        </Tab>
        <Tab eventKey="projected-metrics" title="Projected Metrics">
          <AmazonProjectedMetricsTabWrapper />
        </Tab>
      </Tabs>
    </Container>
      
  )
}

export default function AmazonPageSpeedViewsWrapper() {
  return (
    <AmazonPageSpeedViews />
  );
}