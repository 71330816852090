import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import DesktopShipperBatchRecordsComponent from './records/DesktopShipperBatchRecordsComponent'
import DesktopShipperBatchConfigurationComponent from './config/DesktopShipperBatchConfigurationComponent'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { ButtonGroup, ToggleButton } from 'react-bootstrap'
import '../../src/react-tabs.css'


const PAGE_TABS = {
  Orders: {
    Title: 'Orders',
    Key: 'orders'
  },
  Configuration: {
    Title: 'Batch Configuration',
    Key: 'batchconfig'
  }
}

const radios = [
  { name: 'Development', value: 'development' },
  { name: 'Production', value: 'production' }
]

const DesktopShipperComponent = () => {
  const [environment, setEnvironment] = useState('production')

  const navigate = useNavigate()


  const view = useMemo(() => {
    return (
      <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
        <h1>Desktopshipper</h1>
        <hr/>
        <ButtonGroup>
          {radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant={idx % 2 ? 'outline-success' : 'outline-danger'}
              name="radio"
              value={radio.value}
              checked={environment === radio.value}
              onChange={(e) => setEnvironment(e.currentTarget.value)}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
        <Tabs>
          <TabList>
            <Tab key='orderTab'>{PAGE_TABS.Orders.Title}</Tab>
            <Tab key='configTab'>{PAGE_TABS.Configuration.Title}</Tab>
          </TabList>
          <TabPanel key='orderTabPanel'>
            <DesktopShipperBatchRecordsComponent environment={environment} />
          </TabPanel>
          <TabPanel key='configTabPanel'>
            <DesktopShipperBatchConfigurationComponent environment={environment} />
          </TabPanel>
        </Tabs>
      </div>
    )
  }, [environment])

  return view
}

export default DesktopShipperComponent
