export const DATA_SERVICES_TABLES_DEV = 'https://dataservicessandboxdev.table.core.windows.net/'
export const DATA_SERVICES_TABLES = 'https://dataservicessandbox.table.core.windows.net/'
export const DATA_SERVICES_BLOBS_DEV = 'https://dataservicessandboxdev.blob.core.windows.net/'
export const DATA_SERVICES_BLOBS = 'https://dataservicessandbox.blob.core.windows.net/'
export const CHANNELS_BLOBS_DEV = 'https://channelssandboxdev.blob.core.windows.net/'
export const CHANNELS_BLOBS = 'https://channelssandbox.blob.core.windows.net/'


export const CUSTOM_JSON_THEME = {
  main: 'line-height:1.3;color:#ffffff;background:#272822;overflow:auto;',
  error: 'line-height:1.3;color:#66d9ef;background:#272822;overflow:auto;',
  key: 'color:#5CC9C3;',
  string: 'color:#D0A572;',
  value: 'color:#a6e22e;',
  boolean: 'color:#ac81fe;',
}
