import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { useCallback, useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'

interface TemplateComponentProperties {
  content: string
  modalVisible: boolean
  closeModal: any //function
  saveTemplate: any //function
}

const TemplateComponent = (props: TemplateComponentProperties) => {
  const [content, setContent] = useState(props.content)

  useEffect(() => {
    setContent(props.content)
  }, [])

  const handleContentChange = useCallback((e) => {
    setContent(e)
  }, [])

  const handleFormSubmit = useCallback((e) => {
    e.preventDefault()
  }, [])

  const handleSaveClicked = useCallback((e) => {
    props.saveTemplate(content ?? props.content)
  }, [content, props])
  
  return (
    <Modal show={props.modalVisible} animation={false} onHide={props.closeModal} className='modal-xl'>
      <Modal.Header closeButton>
        <Modal.Title>
          Posting Template
        </Modal.Title>
      </Modal.Header>
        
      <Modal.Body>
        <Form>
          <Form.Group onSubmit={handleFormSubmit}>
            <CKEditor data={props.content} onChange={(event, editor)=> { handleContentChange(editor.getData()) }} editor={ClassicEditor}></CKEditor>
            <Form.Text className='text-muted'>The formatted description of the posting.</Form.Text>
          </Form.Group>
          <Button variant='success' type='button' onClick={handleSaveClicked} >Save</Button>
          <Button variant='danger' onClick={props.closeModal}>Cancel</Button>
        </Form>
      </Modal.Body>
    </Modal>                    
  )
}

export default TemplateComponent
