import { useCallback, useMemo, useState } from 'react';
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import ShipDetailResetToPendingSoftware from './resets/resetToPendingSoftware';
import ShipDetailResetToShipped from './resets/resetToShipped';
import ShipDetailResetToPendingInvoice from './resets/resetToPendingInvoice';
import ResetDataInputComponent from './resets/resetDataInputComponent';
import { InteractiveBrowserCredential } from '@azure/identity';
import { TENANT_ID, CLIENT_ID } from '../authConfig';
import { BlobServiceClient } from '@azure/storage-blob';
import { DATA_SERVICES_BLOBS_DEV, DATA_SERVICES_BLOBS } from '../common/common.constants';
import { useSpinner } from '../spinner/SpinnerContext';

const RADIO = [
  { name: 'Development', value: 'development', enabled: true },
  { name: 'Production', value: 'production', enabled: true }
]

const SALESFORCE_URLS = {
  'development': 'https://nethealthshopsllc--testing.sandbox.lightning.force.com',
  'production': 'https://nethealthshopsllc.lightning.force.com'
}

export default function ShipDetailResetComponent() {
  const [environment, setEnvironment]: [string, any] = useState('development')
  const [ids, setIds] = useState(undefined)
  const [modalVisible, setModalVisible] = useState(false)
  const { showSpinner, hideSpinner } = useSpinner()

  const credential = new InteractiveBrowserCredential({
    tenantId: TENANT_ID,
    clientId: CLIENT_ID,
    authorityHost: 'https://login.microsoft.com'
  })
  
  const handleIdsChanged = useCallback((ids: string[]) => {
    try {
      setIds(ids)
    }catch(error){
      console.log(error)
    }
  }, [])

  const handleCloseDataInput = useCallback(() => {
    setModalVisible(false)
  }, [])

  const handleShowDataInput = useCallback((e) => {
    setModalVisible(true)
  }, [])

  const handleWriteShipDetails = useCallback( (data: any[]) => {
    const writeBlobs = async function (data: any[]) {
      console.log(data)
      if(!data || data.length === 0) return
      const blobServiceClient = new BlobServiceClient(environment === 'development' ? DATA_SERVICES_BLOBS_DEV : DATA_SERVICES_BLOBS, credential)
      const containerClient = blobServiceClient.getContainerClient('storage')
  
      showSpinner()
      try {
        for(const blob of data){
          const blobName = `fulfillment-service/inbound/crystal-ball/resets/${blob.Id}.json`
          const blobClient = containerClient.getBlockBlobClient(blobName)
          const dataString = JSON.stringify(blob)
          await blobClient.upload(dataString, dataString.length)
        }
      }catch(error){
        console.error(error)
      }
      setIds(undefined)
      hideSpinner()
    }
    writeBlobs(data)
  }, [])

  const handleEnvironmentChange = useCallback((e) => {
    setEnvironment(e.currentTarget.value)
  }, [])

  const render = useMemo(() => {
    return <div>
      <h2>Ship Detail Reset</h2>
      <ButtonGroup>
          {RADIO.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant={idx % 2 ? 'outline-success' : 'outline-danger'}
              name="radio"
              value={radio.value}
              checked={environment === radio.value}
              onChange={handleEnvironmentChange}
              disabled={radio.enabled !== true}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
        <hr/>
      <Button variant='success' onClick={handleShowDataInput}>Add IDs</Button>
      <hr/>
      <ResetDataInputComponent modalVisible={modalVisible} closeModal={handleCloseDataInput} setIds={handleIdsChanged}></ResetDataInputComponent>
      <Tabs>
        <TabList>
          <Tab key='fulfillmentSoftware'>Fulfillment Software</Tab>
          <Tab key='shipped'>Shipped</Tab>
          <Tab key='pendingInvoice'>Pending Invoice</Tab>
        </TabList>
        <TabPanel key='fulfillmentSoftware'>
          <ShipDetailResetToPendingSoftware ids={ids} salesforceUrl={SALESFORCE_URLS[environment]} handleWriteShipDetails={handleWriteShipDetails}></ShipDetailResetToPendingSoftware>
        </TabPanel>
        <TabPanel key='shipped'>
          <ShipDetailResetToShipped ids={ids} salesforceUrl={SALESFORCE_URLS[environment]} handleWriteShipDetails={handleWriteShipDetails}></ShipDetailResetToShipped>
        </TabPanel>
        <TabPanel key='pendingInvoice'>
          <ShipDetailResetToPendingInvoice ids={ids} salesforceUrl={SALESFORCE_URLS[environment]} handleWriteShipDetails={handleWriteShipDetails}></ShipDetailResetToPendingInvoice>
        </TabPanel>
      </Tabs>
    </div>
  }, [
    handleIdsChanged, 
    ids, 
    handleCloseDataInput, 
    handleShowDataInput, 
    modalVisible, 
    environment, 
    handleEnvironmentChange, 
    handleWriteShipDetails
  ])

  return render
}
