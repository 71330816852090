import { Spinner } from "react-bootstrap";
import { useSpinner } from "./SpinnerContext";
import { useEffect } from "react";

export const CustomSpinner = () => {
  const { loading } = useSpinner();
  useEffect(() => {
    if (loading) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };

  }, [loading]);

  return (
    loading && (
      <div id='spinner' className="">
          <div className="d-flex">
            <Spinner animation="grow" variant="primary" />
            <Spinner animation="grow" variant="secondary" />
            <Spinner animation="grow" variant="success" />
            <Spinner animation="grow" variant="danger" />
            <Spinner animation="grow" variant="warning" />
            <Spinner animation="grow" variant="info" />
            <Spinner animation="grow" variant="light" />
            <Spinner animation="grow" variant="dark" />
          </div>
      </div>
    )
  );
};

