import { DateTime } from 'luxon'

export function sortData(data, sortColumn, sortOrder) {
  if (!data || !Array.isArray(data) || data.length === 0) return [];

  return data.sort((a, b) => {
    // Handle missing or invalid values
    const valueA = a[sortColumn] ?? '';
    const valueB = b[sortColumn] ?? '';

    // Handle date sorting
    if (sortColumn === 'orderDate') {
      const dateA = DateTime.fromISO(a['orderDateTime']); // Use the correct key
      const dateB = DateTime.fromISO(b['orderDateTime']);
    
      if (!dateA.isValid || !dateB.isValid) {
        console.warn('Invalid date format:', { a, b });
        return 0; // Treat invalid dates as equal
      }
    
      return sortOrder === 'asc'
        ? dateA.toMillis() - dateB.toMillis()
        : dateB.toMillis() - dateA.toMillis();
    }

    // Handle string sorting
    if (typeof valueA === 'string' && typeof valueB === 'string') {
      return sortOrder === 'asc'
        ? valueA.localeCompare(valueB)
        : valueB.localeCompare(valueA);
    }

    // Handle numeric sorting
    if (typeof valueA === 'number' && typeof valueB === 'number') {
      return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
    }

    // Default to treating as strings if types don't match
    return sortOrder === 'asc'
      ? valueA.toString().localeCompare(valueB.toString())
      : valueB.toString().localeCompare(valueA.toString());
  });
}