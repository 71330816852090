import React, { useCallback, useMemo, useState } from 'react'
import { Button, Dropdown, Form } from 'react-bootstrap'

interface CustomMenuProperties {
  title?: string
  items?: any[]
  selected?: string
}

interface DropdownItem {
  key: string
  id: string
  value: string
  clicked: any
}

// @ts-ignore
const CustomToggle = React.forwardRef(({children, onClick}, ref) => {
  return (
    <a
      href=""
      // @ts-ignore
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        // @ts-ignore
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  )
})

// @ts-ignore
const CustomMenu = React.forwardRef(({ children, style, className, 'aria-labelledby': labeledBy, title }, ref) => {
  const [value, setValue] = useState('')
  const [length, setLength] = useState(undefined)

  const childrenList = useMemo(() => {
    const childrenArray = React.Children.toArray(children)
    const childrenArrayFiltered = childrenArray.filter(
      (child) =>
        // @ts-ignore
        !value || child.props.children.toLowerCase().includes(value.toLowerCase()),
    )
    setLength(childrenArrayFiltered.length)
    return (
      <ul className="list-unstyled">
        {childrenArrayFiltered}
      </ul>
    )
  }, [value])

  return (
    <div
      // @ts-ignore
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      <Form.Control
        autoFocus
        className="mx-3 my-2 w-auto"
        placeholder="Type to filter..."
        onChange={(e) => setValue(e.target.value)}
        value={value}
      />
      <div style={{textAlign: 'center', color: 'darkgray'}}>
        {length} item(s)
      <hr></hr>
      </div>
      {childrenList}
    </div>
  )
})

const CustomMenuComponent = (props: CustomMenuProperties) => {
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        {!props.selected ? `Select ${props.title}...` : props.selected }
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        {props.items as any}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default CustomMenuComponent
