    /**
     * UPS: 
     * 0 Account Number, 
     * 1 Invoice Number, 
     * 2 Invoice Date, 
     * 3 Tracking Number, 
     * 4 Pickup Number,
     * 5 Reference Number 1, "Our order number"
     * 6 Reference Number 2,
     * 7 Reference Number 3,
     * 8 Reference Number 4,
     * 9 Reference Number 5,
     * 10 Shipping System / Adjustment, 
     * 11 Zone,
     * 12 Service Type,
     * 13 Published Charge,
     * 14 Incentives,
     * 15 Net Amount Due "Use this"
     */

import { CarrierInvoice } from './carrier.invoice.audit.interfaces';

export const UpsFields = {
  AccountNumber: 0,
  InvoiceNumber: 1,
  InvoiceDate: 2,
  InvoiceDate2: 31,
  TrackingNumber: 4,
  OrderNumber: 6,
  Products: 7,
  NetAmountDue: 27
}

export function mapUpsToCarrierInvoices(data: string): CarrierInvoice[] {
  const carrierInvoices: CarrierInvoice[] = []
  try {
    /** ups file does not split by \r\n */
    const lines = data.replace(/"/g, '').split('\n')
    let index = 0
    for(const line of lines){
      index += 1
      if(index === 1) continue
  
      /** UPS contains a field with double quotes because there's a comma in there. */
      const lineSplit = line.split(',')
      carrierInvoices.push({
        Account: lineSplit[UpsFields.AccountNumber],
        InvoiceDate: `Invoiced: ${lineSplit[UpsFields.InvoiceDate]} Due: ${lineSplit[UpsFields.InvoiceDate2]}`,
        InvoiceNumber: lineSplit[UpsFields.InvoiceNumber],
        TrackingNumber: lineSplit[UpsFields.TrackingNumber],
        NetDue: lineSplit[UpsFields.NetAmountDue],
        OrderNumber: lineSplit[UpsFields.OrderNumber],
        Products: lineSplit[UpsFields.Products]?.replace(/�/g, ''),
        NetDueNumber: +lineSplit[UpsFields.NetAmountDue]
      })
    }
  }catch(error){
    console.error(error)
  }

  return carrierInvoices
}