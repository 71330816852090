import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Button, Col, Container, Form, Spinner } from "react-bootstrap";
import { apiRequest } from "../utility/axios";
import { notify } from "../notify/notify";
import { useForm } from "react-hook-form";

function SupplierContactForm() {
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Function to send a message to the parent page
  const sendMessageToParent = (data) => {
    const message = {
      event: "supplier_contact_form",
      data,
    };

    // Send the message to the parent
    window.parent.postMessage(message, "*");
  };

  const formMutation = useMutation({
    mutationFn: async (body: any) => {
      const response = await apiRequest<any>("POST", `/api/supplierContactForm`, body);
      return response;
    },
    onMutate: () => {
      setIsSubmitting(true);
    },
    onSuccess: async () => {
      setSubmitted(true);
    },
    onError: (error) => {
      notify("error", error.message);
    },
    onSettled: () => {
      setIsSubmitting(false);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => formMutation.mutate(data);

  return (
    <Container fluid className="sd_html fs-3 d-flex align-items-center justify-content-center">
      <Col xs={10}>
        {!submitted && (
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <Form.Label>Company</Form.Label>
              <Form.Control
                className="fs-4"
                placeholder="Enter your company name"
                isInvalid={!!errors.companyName}
                disabled={isSubmitting}
                {...register("companyName", { required: true, maxLength: 80 })}
              />
              <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                className="fs-4"
                placeholder="Enter your name (First Last)"
                isInvalid={!!errors.contactName}
                disabled={isSubmitting}
                {...register("contactName", { required: true, maxLength: 80 })}
              />
              <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                className="fs-4"
                placeholder="Enter your phone number (e.g., 202-456-1414)"
                isInvalid={!!errors.phone}
                disabled={isSubmitting}
                {...register("phone", {
                  required: true,
                  pattern: {
                    value: /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
                    message: "Invalid phone number format",
                  },
                })}
              />
              <Form.Control.Feedback type="invalid">{errors?.phone?.message as string}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                className="fs-4"
                placeholder="Enter your Email address (e.g., example@gmail.com)"
                isInvalid={!!errors.email}
                disabled={isSubmitting}
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
                    message: "Invalid email address",
                  },
                })}
              />
              <Form.Control.Feedback type="invalid">{errors?.email?.message as string}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Message</Form.Label>
              <Form.Control
                className="fs-4"
                placeholder="Enter your message"
                as="textarea"
                rows={6}
                disabled={isSubmitting}
                {...register("message")}
              />
            </Form.Group>

            <div className="d-flex justify-content-center mt-5">
              <button className='sd_button sd_button_primary border border-0' type="submit" disabled={isSubmitting}>
                {isSubmitting ? <Spinner animation="border" /> : "Submit"}
              </button>
            </div>
          </Form>
        )}

        {submitted && (
          <div className="text-center">
            Thank you for submiting your contact information,
            <br />
            our support will contact you soon!
          </div>
        )}
      </Col>
    </Container>
  );
}

export default SupplierContactForm;
