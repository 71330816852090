import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import AmazonDeliveryMetricsRowComponent from './amazonDeliveryMetricsRow';
import { useAmazonDeliveryMetricsContext } from '../contexts/amazonDeliveryMetricsContext';
import { sortData } from '../utilities/fedexTableSortDataFunction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const AmazonDeliveryMetricsTableComponent: React.FC = ({}) => {

  const {
    currentData,
    filteredData
  } = useAmazonDeliveryMetricsContext()

  const [expandedRow, setExpandedRow] = useState<string | null>(null);
  const [sortColumn, setSortColumn] = useState<string>('orderDate'); // Default sorting by 'orderDate'
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc'); // Default sorting order is ascending

  const toggleExpand = (rowId: string) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
  }

  const dataToDisplay = filteredData && filteredData.length > 0 ? filteredData : currentData || []

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      // If the column is already sorted, toggle the order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      // If a new column is clicked, sort in ascending order by default
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const sortedData = sortData(dataToDisplay, sortColumn, sortOrder)

  return (
    <div className="table-responsive" style={{ maxHeight: '700px', overflowY: 'auto' }}>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}></th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Order Id</th>
            <th 
              className={`text-center`} 
              style={{ position: 'sticky', top: 0}} 
              onClick={() => handleSort('orderDate')}
            >
              Order Date {sortColumn === 'orderDate' && (
                <FontAwesomeIcon 
                  icon={sortOrder === 'asc' ? faChevronUp : faChevronDown} 
                />
              )}
            </th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>SKU</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Promised Ship Date</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Promised Delivery Date (No Extension)</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Promised Delivery Date (With Extension)</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Actual Ship Date (First Scan)</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Actual Delivery Date</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Carrier Name</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Ship Method</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Tracking Id</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Unit Count</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Actual Zip</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Origin Zip</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Destination Zip</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Delivered late without a promise extension?</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Canceled by seller?</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Invalid tracking?</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Delivered late with a promise extension?</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Shipped late (carrier first scan)?</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Order Day of Week</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>DC</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>API Create Time</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>API Ship Date</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Tier Size</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Determined Reason</th>
          </tr>
        </thead>
        <tbody>
          {sortedData?.map((row) => (
            <AmazonDeliveryMetricsRowComponent
              key={row.orderId}
              row={row}
              isExpanded={expandedRow === row.orderId}
              onToggleExpand={toggleExpand}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default AmazonDeliveryMetricsTableComponent