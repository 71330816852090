import React from 'react';
import { ScanEvents } from '../../common/common.types'; // Assume you have this imported from your types
import { Table } from 'react-bootstrap'
import { DateTime } from 'luxon';

type ScanEvenetsRowProps = {
  row: ScanEvents[]
};

const FedExScanEventRowComponent: React.FC<ScanEvenetsRowProps> = ({ row }) => {

  return (
    <div className="table-responsive" style={{ maxHeight: '200px', overflowY: 'auto' }}>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Date</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Event Description</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Location</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Derived Status</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Exception Description</th>
            <th className={`text-center`} style={{ position: 'sticky', top: 0}}>Delay Detail?</th>
          </tr>
        </thead>
        <tbody>
          {row.map((detail, index) => (
            <tr key={index}>
              <td className={`text-center`}>{DateTime.fromISO(detail.date).toFormat('MM/dd/yyyy hh:mm a')}</td>
              <td className={`text-center`}>{detail.eventDescription}</td>
              <td className={`text-center`}>
                {
                  [
                    detail.scanLocation?.postalCode,
                    detail.scanLocation?.city,
                    detail.scanLocation?.stateOrProvinceCode,
                    detail.scanLocation?.countryCode
                  ].filter(Boolean).join(' ')
                }
              </td>
              <td className={`text-center`}>{detail.derivedStatus}</td>
              <td className={`text-center`}>{detail.exceptionDescription}</td>
              <td className="text-center">
                {
                  [
                    detail.delayDetail?.status,
                    detail.delayDetail?.type,
                    detail.delayDetail?.subType
                  ].filter(Boolean).join(' ')
                }
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default FedExScanEventRowComponent;