import ReactDOM from "react-dom/client";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { getMsalConfig } from "./authConfig";
import { ToastContainer } from "react-toastify";
import { SpinnerProvider } from "./spinner/SpinnerContext";
import { CustomSpinner } from "./spinner/Spinner";
import UserContextProvider from "./user/UserContext";
import React from "react";
import App from "./App";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// import reportWebVitals from './reportWebVitals'

const msalInstance = new PublicClientApplication(getMsalConfig("/"));

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <div className="p-0" style={{ fontSize: "16px" }}>
          <ReactQueryDevtools />
        </div>
        <SpinnerProvider>
          <UserContextProvider>
            <App />
          </UserContextProvider>
          <CustomSpinner />
        </SpinnerProvider>
      </QueryClientProvider>
      <ToastContainer />
    </MsalProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
