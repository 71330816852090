import React, { useState } from 'react';
import { PrimeSpeedRow } from '../../common/common.types';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import ProjectedASINRowComponent from './amazonProjectedASINRow';
import { DateTime } from 'luxon';
import { useProjectedGraphDataContext } from '../contexts/amazonPageSpeedViewsProjectedDataContext';

const ProjectedASINTableComponent = ({}) => {

  const ONE_DAY_PERCENTAGE_REQUIREMENT = 10;
  const TWO_DAY_PERCENTAGE_REQUIREMENT = 45;
  const {filteredRollingData} = useProjectedGraphDataContext()

  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'ascending' | 'descending' } | null>(null);

  const todaysDate = DateTime.local().toUTC()
  const startOfThisWeek = todaysDate.startOf('week').minus({ days: 1 })
  const endOfThisWeek = startOfThisWeek.plus({day: 6})

  // Utility function to calculate total views and views per day
  const calculateTotalViews = (row: PrimeSpeedRow) =>
    row.viewDetails.reduce(
      (acc, detail) =>
        acc + detail.twoDayViews + detail.greaterThanTwoDayViews,
      0
    );

  // Calculate 1 Day % of total views
  const calculateOneDayPercent = (row: PrimeSpeedRow) => {
    const totalViews = calculateTotalViews(row);
    const oneDayViews = row.viewDetails.reduce((acc, detail) => acc + detail.oneDayViews, 0);
    return totalViews > 0 ? (oneDayViews / totalViews) * 100 : 0;
  };
  
  // Calculate 2 Day % of total views
  const calculateTwoDayPercent = (row: PrimeSpeedRow) => {
    const totalViews = calculateTotalViews(row);
    const twoDayViews = row.viewDetails.reduce((acc, detail) => acc + detail.twoDayViews, 0);
    return totalViews > 0 ? (twoDayViews / totalViews) * 100 : 0;
  };

  const calculateViewsPerDay = (row: PrimeSpeedRow) => {
    const totalViews = calculateTotalViews(row);
    const days = endOfThisWeek.diff(startOfThisWeek, "days").days
    return days > 0 ? totalViews / days : 0;
  };

  const sortedData = React.useMemo(() => {
    let sortableData = [...filteredRollingData];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        let aValue, bValue;

        switch (sortConfig.key) {
          case 'oneDayPercent':
            aValue = calculateOneDayPercent(a);
            bValue = calculateOneDayPercent(b);
            break;
          case 'twoDayPercent':
            aValue = calculateTwoDayPercent(a);
            bValue = calculateTwoDayPercent(b);
            break;
          case 'viewsPerDay':
            aValue = calculateViewsPerDay(a);
            bValue = calculateViewsPerDay(b);
            break;
          case 'oneDayViews':
            aValue = a.viewDetails.reduce((acc, detail) => acc + detail.oneDayViews, 0);
            bValue = b.viewDetails.reduce((acc, detail) => acc + detail.oneDayViews, 0);
            break;
          case 'twoDayViews':
            aValue = a.viewDetails.reduce((acc, detail) => acc + detail.twoDayViews, 0);
            bValue = b.viewDetails.reduce((acc, detail) => acc + detail.twoDayViews, 0);
            break;
          case 'totalViews':
            aValue = calculateTotalViews(a);
            bValue = calculateTotalViews(b);
            break;
          default:
            aValue = a[sortConfig.key as keyof PrimeSpeedRow];
            bValue = b[sortConfig.key as keyof PrimeSpeedRow];
        }

        if (isNaN(aValue)) aValue = 0;
        if (isNaN(bValue)) bValue = 0;

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  }, [filteredRollingData, sortConfig]);

  // Function to handle sorting, with 3rd click resetting sort
  const requestSort = (key: string) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig && sortConfig.key === key) {
      if (sortConfig.direction === 'ascending') {
        direction = 'descending';
      } else if (sortConfig.direction === 'descending') {
        // On 3rd click, reset sort
        setSortConfig(null);
        return;
      }
    }
    setSortConfig({ key, direction });
  };

  const getSortArrow = (key: string) => {
    if (!sortConfig || sortConfig.key !== key) {
      return <FontAwesomeIcon icon={faSort} />;
    }
    return sortConfig.direction === 'ascending' ? (
      <FontAwesomeIcon icon={faSortUp} />
    ) : (
      <FontAwesomeIcon icon={faSortDown} />
    );
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="table-responsive" style={{ maxHeight: '600px', overflowY: 'auto' }}>
            <Table striped bordered hover>
              <thead className="thead-light">
                <tr>
                  <th className={`text-center`} onClick={() => requestSort('asin')} style={{ position: 'sticky', top: 0, background: '#f8f9fa', cursor: 'pointer' }}>
                    ASIN {getSortArrow('asin')}
                  </th>
                  <th className={`text-center`} onClick={() => requestSort('sku')} style={{ position: 'sticky', top: 0, background: '#f8f9fa', cursor: 'pointer' }}>
                    SKU {getSortArrow('sku')}
                  </th>
                  <th className={`text-center`} onClick={() => requestSort('title')} style={{ position: 'sticky', top: 0, background: '#f8f9fa', cursor: 'pointer' }}>
                    Title {getSortArrow('title')}
                  </th>
                  <th className={`text-center`} onClick={() => requestSort('revenue')} style={{ position: 'sticky', top: 0, background: '#f8f9fa', cursor: 'pointer' }}>
                    Revenue {getSortArrow('revenue')}
                  </th>
                  <th className={`text-center`} onClick={() => requestSort('units')} style={{ position: 'sticky', top: 0, background: '#f8f9fa', cursor: 'pointer' }}>
                    Units {getSortArrow('units')}
                  </th>
                  <th className={`text-center`} onClick={() => requestSort('oneDayPercent')} style={{ position: 'sticky', top: 0, background: '#f8f9fa', cursor: 'pointer' }}>
                    1 Day % {getSortArrow('oneDayPercent')}
                  </th>
                  <th className={`text-center`} onClick={() => requestSort('twoDayPercent')} style={{ position: 'sticky', top: 0, background: '#f8f9fa', cursor: 'pointer' }}>
                    2 Day % {getSortArrow('twoDayPercent')}
                  </th>
                  <th className={`text-center`} onClick={() => requestSort('oneDayViews')} style={{ position: 'sticky', top: 0, background: '#f8f9fa', cursor: 'pointer' }}>
                    1 Day Views {getSortArrow('oneDayViews')}
                  </th>
                  <th className={`text-center`} onClick={() => requestSort('twoDayViews')} style={{ position: 'sticky', top: 0, background: '#f8f9fa', cursor: 'pointer' }}>
                    2 Day Views {getSortArrow('twoDayViews')}
                  </th>
                  <th className={`text-center`} onClick={() => requestSort('totalViews')} style={{ position: 'sticky', top: 0, background: '#f8f9fa', cursor: 'pointer' }}>
                    Total Views {getSortArrow('totalViews')}
                  </th>
                  <th className={`text-center`} onClick={() => requestSort('viewsPerDay')} style={{ position: 'sticky', top: 0, background: '#f8f9fa', cursor: 'pointer' }}>
                    Views Per Day {getSortArrow('viewsPerDay')}
                  </th>
                  <th className={`text-center`} style={{ position: 'sticky', top: 0, background: '#f8f9fa' }}>Prime</th>
                  <th className={`text-center`} style={{ position: 'sticky', top: 0, background: '#f8f9fa' }}>Bolingbrook</th>
                  <th className={`text-center`} style={{ position: 'sticky', top: 0, background: '#f8f9fa' }}>Eau Claire</th>
                  <th className={`text-center`} style={{ position: 'sticky', top: 0, background: '#f8f9fa' }}>Allentown</th>
                  <th className={`text-center`} style={{ position: 'sticky', top: 0, background: '#f8f9fa' }}>Tracy</th>
                  <th className={`text-center`} style={{ position: 'sticky', top: 0, background: '#f8f9fa' }}>Memphis</th>
                  <th className={`text-center`} style={{ position: 'sticky', top: 0, background: '#f8f9fa' }}>Size</th>
                </tr>
              </thead>
              <tbody>
                {sortedData.map((item, index) => (
                  <ProjectedASINRowComponent
                    key={`projected-${item.asin}-${index}`}
                    row={item}
                    ONE_DAY_PERCENTAGE_REQUIREMENT={ONE_DAY_PERCENTAGE_REQUIREMENT}
                    TWO_DAY_PERCENTAGE_REQUIREMENT={TWO_DAY_PERCENTAGE_REQUIREMENT}
                  />
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ProjectedASINTableComponent;