import { useQuery } from "@tanstack/react-query";
import { apiRequest } from "../utility/axios";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useEffect, useMemo, useState } from "react";
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from "recharts";
import { useSpinner } from "../spinner/SpinnerContext";

export enum ChannelOrderType {
  ChadFBA = "chad_fba",
  ChadSF = "chad_sf",
  TargetDirect = "target_direct",
  WayfairDirect = "wayfair_direct",
}

export enum McOrderType {
  McFBA = "mc_FBA",
  McSF = "mc_SF",
  McTarget = "mc_target",
  McWayfair = "mc_wayfair",
}

type OrderCountResponse = Record<ChannelOrderType | McOrderType, number>;

const AccountHealth = () => {
  const [dayRange, setDayRange] = useState(1);
  const { toggleSpinner } = useSpinner();

  const { isLoading, isPending, error, data, isFetching } = useQuery<OrderCountResponse>({
    queryKey: ["accountHealth", dayRange],
    queryFn: async () => {
      const response = await apiRequest<OrderCountResponse>("GET", `/api/accountHealth?dayRange=${dayRange}`);
      return response;
    },
    staleTime: 60000 * 5, // Data is considered fresh for 5 minutes
  });

  console.log("🚀 ~ AccountHealth ~ data:", data);

  const chartData = useMemo(() => {
    if (!data) {
      return []; // Return an empty array if data is not set
    }

    return [
      {
        name: `FBA ${((data.mc_FBA / data.chad_fba) * 100).toFixed(2)}%`,
        MarketPlace: data.chad_fba,
        MC: data.mc_FBA,
        amt: Math.max(data.chad_fba, data.mc_FBA),
      },
      {
        name: `SF ${((data.mc_SF / data.chad_sf) * 100).toFixed(2)}%`,
        MarketPlace: data.chad_sf,
        MC: data.mc_SF,
        amt: Math.max(data.chad_sf, data.mc_SF),
      },
      {
        name: `Target ${((data.mc_target / data.target_direct) * 100).toFixed(2)}%`,
        MarketPlace: data.target_direct,
        MC: data.mc_target,
        amt: Math.max(data.target_direct, data.mc_target),
      },
      {
        name: `Wayfair ${((data.mc_wayfair / data.wayfair_direct) * 100).toFixed(2)}%`,
        MarketPlace: data.wayfair_direct,
        MC: data.mc_wayfair,
        amt: Math.max(data.wayfair_direct, data.mc_wayfair),
      },
    ];
  }, [data]);

  const handleDayRangeChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10);
    setDayRange(selectedValue); // Update dayRange state
  };

  useEffect(() => {
    toggleSpinner(isPending || isFetching || isLoading);
  }, [isPending, isFetching, isLoading]);

  if (error) return <div>{error.message}</div>;
  if (!data) return <div></div>;

  return (
    <>
      <Container fluid className="fs-5 d-flex justify-content-center">
        <Row className="mb-5">
          <Col>
            <Form.Select className="fs-5" value={dayRange} onChange={handleDayRangeChange}>
              <option value="1">Last 1 day</option>
              <option value="7">Last 7 days</option>
              <option value="15">Last 15 days</option>
            </Form.Select>
            <BarChart
              width={600}
              height={400}
              data={chartData}
              margin={{
                top: 20,
                right: 40,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="MC" fill="#8884d8" />
              <Bar dataKey="MarketPlace" fill="#82ca9d" />
            </BarChart>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AccountHealth;
