import { Form, Row, Col, Container } from "react-bootstrap"
import { useAmazonDeliveryMetricsContext } from "../../contexts/amazonDeliveryMetricsContext";

const AmazonDeliveryDateFilterComponent = ({}) => {

  const{
    setSelectedStartDate,
    setSelectedEndDate,
    selectedStartDate,
    selectedEndDate
  } = useAmazonDeliveryMetricsContext()

  return(
  <Container>
    <Row className="justify-content-evenly">
      <Col>
        <Form>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="startDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={selectedStartDate || ''}
                  onChange={(e) => setSelectedStartDate(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="endDate">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={selectedEndDate || ''}
                  onChange={(e) => setSelectedEndDate(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  </Container>
  )
}

export default AmazonDeliveryDateFilterComponent;