import { Button, Row, Col, Container } from "react-bootstrap"

const AmazonDeliveryMetricsExportComponent = ({ exportTableFile, exportDetailedFile }) => { 
  return(
    <Container>
      <Row className="justify-content-evenly">
        <Col>
          <Button variant="outline-primary" onClick={exportTableFile}>Export Table to Xlsx</Button>
        </Col>
        <Col>
          <Button variant="outline-primary" onClick={exportDetailedFile}>Export Table to Xlsx With Scan Details</Button>
        </Col>
      </Row>
    </Container>
  )
}

export default AmazonDeliveryMetricsExportComponent