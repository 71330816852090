import { Navigate } from 'react-router-dom'
import { useIsAuthenticated } from '@azure/msal-react'

const ProtectedRoute = ({ element }) => {
  const isAuthenticated = useIsAuthenticated()
  console.log("🚀 ~ ProtectedRoute ~ isAuthenticated:", isAuthenticated)
  return isAuthenticated ? element : <Navigate to="/login" replace />
}

export default ProtectedRoute;
