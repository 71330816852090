import { Table as Container, Row, Col} from "react-bootstrap"
import { PageSpeedViewsProvider, usePageSpeedViewsContext } from "../contexts/amazonPageSpeedViewsContext"
import AmazonViewSummary from "./amazonPageSpeedViewsSummaryComponent"
import AmazonViewGraph from "./amazonPageSpeedViewsGraphComponent"
import AmazonViewGraphSummary from "./amazonPageSpeedViewsGraphSummaryComponent"
import DraftGraphViewGraphSummary from "./amazonPageSpeedViewsDraftGraphSummary"
import AmazonFilterForm from "./amazonPageSpeedViewsFilterDataComponent"
import UploadAmazonViews from "./amazonPageSpeedViewsUploadDataComponent"
import ASINTableComponent from "./amazonASINTableComponent"

const AmazonCurrentMetricsTab = () => {

  const {
    draftData,
    error,
    summaries,
    graphData,
    graphSummary,
    draftGraphSummary,
    ONE_DAY_PERCENTAGE_REQUIREMENT,
    TWO_DAY_PERCENTAGE_REQUIREMENT,
    selectedStartDate,
    setSelectedStartDate,
    selectedEndDate,
    setSelectedEndDate,
    showPrime,
    showOversize,
    showBolingbrook,
    setShowPrime,
    setShowBolingbrook,
    setOversize,
    handleApplyFilters,
    handleClearFilters,
    handleFileChange,
    handleAmazonViewsExport,
    uploadFile,
    togglePrime
  } = usePageSpeedViewsContext()

  if (error) return <div>{error.message}</div>

  return (
      <div>
        <Row className="mb-4">
          <Col md={3}>
            <AmazonViewSummary summaries={summaries} />
          </Col>
          <Col md={9}>
            <AmazonViewGraph
              graphData={graphData}
              ONE_DAY_PERCENTAGE_REQUIREMENT={ONE_DAY_PERCENTAGE_REQUIREMENT}
              TWO_DAY_PERCENTAGE_REQUIREMENT={TWO_DAY_PERCENTAGE_REQUIREMENT}
            />
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col md={3}>
              <AmazonViewGraphSummary graphSummary={graphSummary} />
          </Col>
          <Col md={3}>
              <DraftGraphViewGraphSummary draftGraphSummary={draftGraphSummary} />
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col md={{ span: 6, offset: 3 }}>
            <AmazonFilterForm
              selectedStartDate={selectedStartDate}
              setSelectedStartDate={setSelectedStartDate}
              selectedEndDate={selectedEndDate}
              setSelectedEndDate={setSelectedEndDate}
              showPrime={showPrime}
              setShowPrime={setShowPrime}
              showBolingbrook={showBolingbrook}
              setShowBolingbrook={setShowBolingbrook}
              showOversize={showOversize}
              setOversize={setOversize}
              handleApplyFilters={handleApplyFilters}
              handleClearFilters={handleClearFilters}
              handleAmazonViewsExport={handleAmazonViewsExport}
            />
          </Col>
          <Col md={{ span: 1, offset: 2 }}>
            <UploadAmazonViews 
              handleFileChange={handleFileChange} 
              uploadFile={uploadFile}
            />
          </Col>
        </Row>
        <ASINTableComponent
          data={draftData}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          ONE_DAY_PERCENTAGE_REQUIREMENT={ONE_DAY_PERCENTAGE_REQUIREMENT}
          TWO_DAY_PERCENTAGE_REQUIREMENT={TWO_DAY_PERCENTAGE_REQUIREMENT}
          togglePrime={togglePrime}
        />
      </div>
      
  )
}

export default function AmazonCurrentMetricsTabWrapper() {
  return (
    <PageSpeedViewsProvider>
      <AmazonCurrentMetricsTab />
    </PageSpeedViewsProvider>
  );
}