import { Col, Container, Row } from "react-bootstrap";
import AmazonDeliveryMetricsTableComponent from "../components/amazonDeliveryMetricsTable";
import AmazonDeliveryDateFilterComponent from "../components/filters/amazonDeliveryDateFilterComponent";
import UploadAmazonDeliveryMetricsComponent from "../components/amazonDeliveryMetricsUpload";
import AmazonDeliveryMetricsExportComponent from "../components/amazonDeliveryMetricsXlsxExport";
import { AmazonDeliveryMetricsProvider, useAmazonDeliveryMetricsContext } from "../contexts/amazonDeliveryMetricsContext";
import AmazonDeliveryApplyClearFilterComponent from "../components/filters/amazonDeliveryApplyClearFiltersComponent";
import AmazonDeliveryReasonFilterComponent from "../components/filters/amazonDeliveryReasonFilterComponent";

const AmazonDeliveryMetrics = () => {

  const {
    error,
    handleFileChange,
    uploadFile,
    handleAmazonDeliveryMetricsDetailedExport,
    handleAmazonDeliveryMetricsTableExport
  } = useAmazonDeliveryMetricsContext()

  if (error) return <div>{error.message}</div>

  return (
    <Container fluid className="mt-4 text-center">
      <Row className="justify-content-md-center mb-5">
        <Col md="auto" className="fs-2 fw-bold">Amazon Delivery Metrics</Col>
      </Row>
      <Row className="justify-content-md-center mb-3">
        <Col md="auto">
        <AmazonDeliveryDateFilterComponent />
        <AmazonDeliveryReasonFilterComponent />
        <AmazonDeliveryApplyClearFilterComponent />
        </Col>
        <Col md="auto">
          <UploadAmazonDeliveryMetricsComponent
            handleFileChange={handleFileChange}
            uploadFile={uploadFile}
          />
        </Col>
      </Row>
      <Row className="justify-content-md-center mb-3">
        <Col md="auto">
          <AmazonDeliveryMetricsExportComponent 
            exportDetailedFile={handleAmazonDeliveryMetricsDetailedExport}
            exportTableFile={handleAmazonDeliveryMetricsTableExport}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <AmazonDeliveryMetricsTableComponent />
        </Col>
      </Row>
    </Container>
  )
}

export default function AmazonDeliveryMetricsWrapper() {
  return (
    <AmazonDeliveryMetricsProvider>
      <AmazonDeliveryMetrics />
    </AmazonDeliveryMetricsProvider>
  );
}