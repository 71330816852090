import { ProcessingPercentageMap } from "../common/common.interfaces";

export type IagTrackingRow = {
  fulfillmentName: string;
  iagId: string;
  iagName: string;
  status: string;
  expediteTracking: string;
  expediteTrackingStatus: string;
  startDate?: boolean;
};

export type IagPipeLineStatusRes = {
  status: string;
  processingStatus: string;
  finalOutput: string;
};

export type JobStatus = {
  processingStatus: string;
  processingPrecentage: number;
  finalOutput: any;
  finalStatus: string;
  isRunning: boolean;
};

export const iagProcessingPercentageMap: ProcessingPercentageMap = {
  "Query IAG response:": 10,
  "Map IAG response:": 20,
  "Map Product response:": 30,
  "Check Product response:": 40,
  "Create Product response:": 50,
  "Update Product response:": 60,
  "Send ASN response:": 70,
  "Update ASN response:": 80,
  "Update IAG Mapper response:": 90,
  "Update IAG Status response:": 100,
};