const INCREMENT = 1024
export function getBlobSizeString(size: number): string {
  if(typeof size === 'undefined') return ''
  if(size === 0) return '0B'
  let sizeText = 'B'
  if(size > INCREMENT){
    size = size / INCREMENT
    sizeText = 'KB'
  }
  if(size > INCREMENT) {
    size = size / INCREMENT
    sizeText = 'MB'
  }
  if(size > INCREMENT){
    size = size / INCREMENT
    sizeText = 'GB'
  }
  if(size > INCREMENT){
    size = size / INCREMENT
    sizeText = 'TB'
  }
  return `${size.toFixed(2)}${sizeText}`
}

export async function blobToString(blob: any): Promise<string> {
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onloadend = (ev) => {
      resolve(ev.target.result as string);
    };
    fileReader.onerror = reject;
    fileReader.readAsText(blob);
  });
}