import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { calculateProjectedSummary } from "../../common/common.helpers";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { useProjectedGraphDataContext } from "../contexts/amazonPageSpeedViewsProjectedDataContext";

const AmazonDayByDayProjected = () => {

  const {
    projectedDayByDayData,
    setProjectedSummary
  } = useProjectedGraphDataContext()

  const [editableData, setEditableData] = useState(projectedDayByDayData)
  const [isEditable, setIsEditable] = useState(false)
  const [copyTooltipText, setCopyTooltipText] = useState("Copy");

  const totals = useMemo(() => {
    return editableData.reduce(
      (acc, day) => {
        acc.totalOneDay += day.totalOneDayViews || 0;
        acc.totalTwoDay += day.totalTwoDayViews || 0;
        acc.totalMoreThanTwoDay += day.totalGreaterThanTwoDayViews || 0;
        acc.totalViews +=
          (day.totalTwoDayViews || 0) +
          (day.totalGreaterThanTwoDayViews || 0);
        return acc;
      },
      { totalOneDay: 0, totalTwoDay: 0, totalMoreThanTwoDay: 0, totalViews: 0 }
    );
  }, [editableData]);

  const handleEditClick = () => {
    setIsEditable(!isEditable)
  };

  const handleCopy = () => {

    // Define table headers
    const headers = ["Date", "1 Day", "2 Day", "More Than 2 Days", "Total"];

    // Extract table data
    const tableData = editableData.map((dayData) => {
      return [
        DateTime.fromISO(dayData.date).toFormat("MM/dd/yyyy"),
        dayData.totalOneDayViews,
        dayData.totalTwoDayViews,
        dayData.totalGreaterThanTwoDayViews,
        dayData.totalTwoDayViews + dayData.totalGreaterThanTwoDayViews,
      ].join("\t");
    });

    // Add totals row
    tableData.push(
      [
        "Totals",
        totals.totalOneDay,
        totals.totalTwoDay,
        totals.totalMoreThanTwoDay,
        totals.totalViews,
      ].join("\t")
    );

    // Combine headers and rows
    const tableString = [headers.join("\t"), ...tableData].join("\n");
  
    // Copy to clipboard
    navigator.clipboard.writeText(tableString).then(
      () => {
        setCopyTooltipText("Copied!"); // Update tooltip text
        setTimeout(() => setCopyTooltipText("Copy"), 2000); // Reset after 2 seconds
      },
      (err) => {
        setCopyTooltipText("Failed to copy!"); // Show error in tooltip
        setTimeout(() => setCopyTooltipText("Copy"), 2000); // Reset after 2 seconds
        console.error(err);
      }
    );
  };

  useEffect(() => {
    if (!isEditable && editableData !== projectedDayByDayData) {
      setEditableData(projectedDayByDayData);
    }
    const newSummary = calculateProjectedSummary(editableData);
    setProjectedSummary(newSummary);
  }, [projectedDayByDayData, isEditable, editableData]);

  const handleInputChange = (e, date, type) => {
    const value = parseFloat(e.target.value) || 0; // Convert to number, default to 0 if empty
    setEditableData((prevData) => {
      const updatedData = prevData.map((day) =>
        day.date === date ? { ...day, [type]: value } : day
      );
      // Prevent unnecessary state updates
      return JSON.stringify(updatedData) === JSON.stringify(prevData)
        ? prevData
        : updatedData;
    });
  };

  return(
    <div className="p-3 shadow-sm bg-light rounded">
      <div className="d-flex align-items-center">
        <p className="fs-2 mb-2 me-3 bold">Projected Day By Day</p>
        <OverlayTrigger
          placement="right"
          trigger={["hover", "focus"]}
          overlay={<Tooltip>{copyTooltipText}</Tooltip>}
        >
          <FontAwesomeIcon
            icon={faClipboard}
            className="text-muted"
            style={{
              padding: "8px", // Increase clickable area
              cursor: "pointer",
              display: "flex", // Ensure proper alignment
              alignItems: "center",
              justifyContent: "center",
              opacity: "1",
              transition: "opacity 0.2s ease-in-out",
            }}
            onClick={handleCopy}
          />
        </OverlayTrigger>
        <OverlayTrigger
          placement="right"
          trigger={["hover", "focus"]}
          overlay={<Tooltip>Edit</Tooltip>}
        >
          <FontAwesomeIcon
            icon={faPencil}
            className="text-muted"
            style={{
              padding: "8px", // Increase clickable area
              cursor: "pointer",
              display: "flex", // Ensure proper alignment
              alignItems: "center",
              justifyContent: "center",
              opacity: isEditable ? "1" : "0", // Always visible while editing
              transition: "opacity 0.2s ease-in-out",
            }}
            onMouseEnter={(e) => {
              if (!isEditable) e.currentTarget.style.opacity = "1";
            }}
            onMouseLeave={(e) => {
              if (!isEditable) e.currentTarget.style.opacity = "0";
            }}
            onClick={handleEditClick}
          />
        </OverlayTrigger>
      </div>
      <Table>
        <thead>
          <tr>
            <th className={`text-center`}>
              Date
            </th>
            <th className={`text-center`}>
              1 Day
            </th>
            <th className={`text-center`}>
              2 Day
            </th>
            <th className={`text-center`}>
              More Than 2 Days
            </th>
            <th className={`text-center`}>
              Total
            </th>
          </tr>
        </thead>
        <tbody>
        {editableData.map((dayData) => (
            <tr key={dayData.date}>
              <td className="text-center">
                {DateTime.fromISO(dayData.date).toFormat('MM/dd/yyyy')}
              </td>
              <td className="text-center">
                {isEditable ? (
                  <input
                    type="number"
                    style={{width: "70px"}}
                    value={dayData.totalOneDayViews}
                    onChange={(e) => handleInputChange(e, dayData.date, "totalOneDayViews")}
                  />
                ) : (
                  dayData.totalOneDayViews
                )}
              </td>
              <td className="text-center">
                {isEditable ? (
                  <input
                    type="number"
                    style={{width: "70px"}}
                    value={dayData.totalTwoDayViews}
                    onChange={(e) => handleInputChange(e, dayData.date, "totalTwoDayViews")}
                  />
                ) : (
                  dayData.totalTwoDayViews
                )}
              </td>
              <td className="text-center">
                {isEditable ? (
                  <input
                    type="number"
                    style={{width: "70px"}}
                    value={dayData.totalGreaterThanTwoDayViews}
                    onChange={(e) => handleInputChange(e, dayData.date, "totalGreaterThanTwoDayViews")}
                  />
                ) : (
                  dayData.totalGreaterThanTwoDayViews
                )}
              </td>
              <td className="text-center">
                {(dayData.totalTwoDayViews + dayData.totalGreaterThanTwoDayViews)}
              </td>
            </tr>
          ))}
        <tr>
          <td className={`text-center`}>Totals</td>
          <td className={`text-center`}>{totals.totalOneDay}</td>
          <td className={`text-center`}>{totals.totalTwoDay}</td>
          <td className={`text-center`}>{totals.totalMoreThanTwoDay}</td>
          <td className={`text-center`}>{totals.totalViews}</td>
        </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default AmazonDayByDayProjected;