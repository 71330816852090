import { InteractiveBrowserCredential } from '@azure/identity'
import { TENANT_ID, CLIENT_ID } from '../authConfig'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { BlobServiceClient } from '@azure/storage-blob'
import { DATA_SERVICES_BLOBS, DATA_SERVICES_BLOBS_DEV } from '../common/common.constants'
import { blobToString } from '../blobs/blobs.service'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Button, ButtonGroup, Form, ToggleButton } from 'react-bootstrap'
import NewJobComponent from './NewJobComponent'
import { DateTime } from 'luxon'
import TemplateComponent from './TemplateComponent'
import { CircleFill } from 'react-bootstrap-icons'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { useSpinner } from '../spinner/SpinnerContext'
import { notify } from '../notify/notify'

interface JobsBlob {
  details: JobBlobDetails[]
}

interface JobBlobDetails {
  id: string
  /** The title to display on the SD.com page */
  title: string
  /** The HTML */
  description: string
  /** The indeed url to the posting */
  indeedUrl: string
  /** Determines whether or not to display the posting */
  active: boolean
}

const STORAGE_SHOPIFY_SD_BLOB_PATH = 'hr-service/careers/shopify/sunnydazedecor'
const STORAGE_TEMPLATE = 'hr-service/careers/job-postings-template.html'
const PUBLIC_SHOPIFY_SD_BLOB_PATH = 'careers/shopify/sunnydazedecor'

const JobsComponent = () => {
  const { showSpinner, hideSpinner } = useSpinner();
  const [environment, setEnvironment] = useState('production')
  const [jobs, setJobs]: [Array<JobBlobDetails>, any] = useState([])
  const [visibilityStateMap, setVisibilityMap] = useState(new Map())
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [lastLoad, setLastLoad] = useState(undefined)
  const [template, setTemplate] = useState(undefined)
  const [templateVisible, setTemplateVisible] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('Both')

  const credential = new InteractiveBrowserCredential({
    tenantId: TENANT_ID,
    clientId: CLIENT_ID,
    authorityHost: 'https://login.microsoft.com'
  })

  const storageBlobServiceClient = new BlobServiceClient(environment === 'development' ? DATA_SERVICES_BLOBS_DEV : DATA_SERVICES_BLOBS, credential)
  const storageContainerService = storageBlobServiceClient.getContainerClient('storage')
  const publicBlobServiceClient = new BlobServiceClient(environment === 'development' ? DATA_SERVICES_BLOBS_DEV : DATA_SERVICES_BLOBS, credential)
  const publicContainerService = publicBlobServiceClient.getContainerClient('public')
  const templateBlobClient = storageContainerService.getBlobClient(STORAGE_TEMPLATE)
  const storageBlobClient = storageContainerService.getBlobClient(`${STORAGE_SHOPIFY_SD_BLOB_PATH}/careers.json`)
  const publicBlobClient = publicContainerService.getBlobClient(`${PUBLIC_SHOPIFY_SD_BLOB_PATH}/careers.json`)

  /** Loading */
  useEffect(() => {
    const loadBlobs = async () => {
      showSpinner()
      try {

        let storageContent: JobsBlob = undefined
        try {
          const storageData = await storageBlobClient.download()
          const storageDownloaded = await blobToString(await storageData.blobBody)
          storageContent = JSON.parse(storageDownloaded)
        }catch(error){
          console.log('Failed to load storage blob.', `${STORAGE_SHOPIFY_SD_BLOB_PATH}/careers.json`)
        }

        const visibilityMap = visibilityStateMap ?? new Map()
        for(const item of storageContent.details){
          if(!visibilityMap.has(item)){
            visibilityMap.set(item.id, true)
          }
        }
        setVisibilityMap(visibilityMap)

        if(storageContent){
          setJobs(storageContent.details)
        }

      }catch(error){
        console.log(error)
      }
      hideSpinner()
    }
    loadBlobs()
  }, [environment, lastLoad])

  /** Load Template */
  useEffect(() => {
    const loadTemplateAync = async () => {
      /** Get the content from the storage blob */
      let templateContent: string = undefined
      try {
        const templateData = await templateBlobClient.download()
        templateContent = await blobToString(await templateData.blobBody)
      }catch(error){
        console.log('Failed to load storage template blobs.', STORAGE_TEMPLATE)
      }
      if(templateContent){
        setTemplate(templateContent)
      }
    }
    loadTemplateAync()
  }, [jobs])

  function handleOnDragEnd(result) {
    if (!result.destination) return
    const items = Array.from(jobs)
    const [reorderedItem] = items.splice(result.source.index, 1)
    items.splice(result.destination.index, 0, reorderedItem)
    setUnsavedChanges(true)
    setJobs(items)
  }

  const handleVisbilityMapChange = useCallback((e) => {
    const newMap = new Map(visibilityStateMap)
    newMap.set(e.target.id, !newMap.get(e.target.id))
    setVisibilityMap(newMap)
  }, [visibilityStateMap])

  const showModal = useCallback((e) => {
    setModalVisible(true)
  }, [])

  const closeModal = useCallback((e) => {
    setModalVisible(false)
  }, [])

  const saveJob = useCallback((e) => {
    const saveJobAsync = async () => {
      showSpinner()
      const newJobs = [e, ...jobs]
      setJobs(newJobs)
  
      /** Save all of the jobs */
      await storageBlobClient.getBlockBlobClient().upload(JSON.stringify({details: newJobs}), JSON.stringify({details: newJobs}).length)
      /** Save only the active jobs to the public folder */
      const activeJobs = newJobs.filter(j => j.active === true)
      await publicBlobClient.getBlockBlobClient().upload(JSON.stringify({details: activeJobs}), JSON.stringify({details: activeJobs}).length)

      notify('success', `New posting saved for ${e.title}!`)

      setLastLoad(DateTime.utc().toMillis())
      setUnsavedChanges(false)
      setModalVisible(false)
      hideSpinner()
    }
    saveJobAsync()

  }, [jobs])

  const saveJobs = useCallback((e) => {
    const saveJobsAsync = async () => {
      showSpinner()
      /** Save all of the jobs */
      await storageBlobClient.getBlockBlobClient().upload(JSON.stringify({details: jobs}), JSON.stringify({details: jobs}).length)
      /** Save only the active jobs to the public folder */
      const activeJobs = jobs.filter(j => j.active === true)
      await publicBlobClient.getBlockBlobClient().upload(JSON.stringify({details: activeJobs}), JSON.stringify({details: jobs}).length)

      notify('success', 'Postings saved successfully!')

      setLastLoad(DateTime.utc().toMillis())
      setUnsavedChanges(false)
      hideSpinner()
    }
    saveJobsAsync()
  }, [jobs])

  const deleteJob = useCallback((e) => {
    const deleteJobAsync = async () => {
      if(confirm('Are you sure you wish to delete this posting permanently? All information will be removed forever for this posting.')){
        const jobsToSave = []
        for(const job of jobs){
          if(job.id !== e.target.id){
            jobsToSave.push(job)
          }
        }

        showSpinner()
        /** Save all of the jobs */
        await storageBlobClient.getBlockBlobClient().upload(JSON.stringify({details: jobsToSave}), JSON.stringify({details: jobsToSave}).length)
        /** Save only the active jobs to the public folder */
        const activeJobs = jobsToSave.filter(j => j.active === true)
        await publicBlobClient.getBlockBlobClient().upload(JSON.stringify({details: activeJobs}), JSON.stringify({details: activeJobs}).length)

        notify('success', 'Posting was deleted successfully!')

        setLastLoad(DateTime.utc().toMillis())
        setUnsavedChanges(false)
        hideSpinner()
      }
    }
    deleteJobAsync()
  }, [jobs])

  const showTemplateModal = useCallback((e) => {
    setTemplateVisible(true)
  }, [])
  
  const closeTemplateModal = useCallback((e) => {
    setTemplateVisible(false)
  }, [])

  const saveTemplate = useCallback((e) => {
    const saveTemplateAsync = async () => {

      /** Save the template blob */
      await templateBlobClient.getBlockBlobClient().upload(e, e.length)

      setTemplate(e)
      setTemplateVisible(false)
      notify('success', 'Template saved successfully!')
    }
    saveTemplateAsync()

  }, [])

  const handleAnyJobChanged = useCallback((e) => {
    const splitId = e.target.id.split('___')
    const id = splitId[0]
    const field = splitId[1]
    const newJobs = [...jobs]
    const job = newJobs.find(j => j.id === id)
    job[field] = field === 'active' ? e.target.checked : e.target.value
    newJobs[e.target.id] = {...job}
    setUnsavedChanges(true)
    setJobs(newJobs)
  }, [jobs])

  const buildView = useMemo(() => {
    //outline-success outline-danger
    let filteredJobs = [...jobs]
    if(selectedFilter === 'Active'){
      filteredJobs = filteredJobs.filter(j => j.active === true)
    } else if(selectedFilter === 'Inactive'){
      filteredJobs = filteredJobs.filter(j => j.active === false)
    }

    return (
      <>
      {<NewJobComponent template={template} closeModal={closeModal} modalVisible={modalVisible} saveJob={saveJob} otherJobTitles={jobs.map(j => j.title.toLowerCase()) ?? []} showToast={notify} />}
      {<TemplateComponent closeModal={closeTemplateModal} content={template} modalVisible={templateVisible} saveTemplate={saveTemplate}></TemplateComponent>}
      <div style={{paddingLeft: '20px'}}>
        <h1>Job Postings</h1>
        <hr/>
        {unsavedChanges === true ? <div style={{color: 'red', fontWeight: 'bold'}}>Unsaved Changes!</div> : ''}<br/>
        <Button variant="success" onClick={showModal} title='Shows the new posting modal.'>New Posting</Button>&nbsp;&nbsp;
        <Button onClick={saveJobs} title='Saves all of the postings in the order they are in below.'>Save Postings</Button>&nbsp;&nbsp;
        <Button variant="link" onClick={showTemplateModal} title='Saves all of the postings in the order they are in below.'>Modify Template</Button><br/><br></br>
        <ButtonGroup>
          <ToggleButton key='0' id='radio-0' type="radio" variant='outline-success' name="radio" value='Active' checked={selectedFilter === 'Active'} onChange={(e) => setSelectedFilter('Active')}>
          Active
          </ToggleButton>
          <ToggleButton key='1' id='radio-1' type="radio" variant='outline-success' name="radio" value='Inactive' checked={selectedFilter === 'Inactive'} onChange={(e) => setSelectedFilter('Inactive')}>
          Inactive
          </ToggleButton>
          <ToggleButton key='2' id='radio-2' type="radio" variant='outline-success' name="radio" value='Both' checked={selectedFilter === 'Both'} onChange={(e) => setSelectedFilter('Both')}>
          Both
          </ToggleButton>
        </ButtonGroup>
        <br/>
        <em>Postings will appear on the <a href="https://sunnydazedecor.com/pages/careers">Sunnydaze Decor</a> site in the order they are here in the list below.</em>
      </div>
      {
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="postings" direction="vertical">
            {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps} style={{ width: '100%', padding: '20px'}} >
                  {filteredJobs.map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id} index={index}>
                        {(provided) => (
                          <div key={index} style={{overflow: 'hidden', transition: 'all 5s'}} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            {index === 0 ? '' : <br/>}
                            <div key={item.id} style={{color: 'grey', border: 'solid 1px lightgray', background: 'white', padding: '10px', borderRadius: '5px'}}>
                              <h3 id={item.id} onClick={handleVisbilityMapChange}>{item.active ? <CircleFill color='green' style={{borderRadius: '2em'}}></CircleFill> : <CircleFill color='gray' style={{borderRadius: '2em'}}></CircleFill>} {item.title}</h3>
                              <Form hidden={visibilityStateMap.get(item.id) ?? true} style={{cursor: 'default'}}>
                                <Form.Group>
                                  <br/>
                                  <Button onClick={saveJobs} title='Saves all of the postings in the order they are in below.'>Quick Save</Button>&nbsp;&nbsp;
                                  <Button id={item.id} variant='danger' onClick={deleteJob} title='Deletes the posting permanently.'>Delete</Button>&nbsp;&nbsp;

                                  <br/><br/>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Label>Title</Form.Label>
                                  <Form.Control key={`${item.id}___title`} id={`${item.id}___title`}  type='text' placeholder='Enter Title' defaultValue={item.title} style={{fontSize: '18px'}} minLength={5} onChange={handleAnyJobChanged}></Form.Control>
                                  <Form.Text className='text-muted'>The title will be displayed on the site as the Accordion header.</Form.Text>
                                </Form.Group>
                                <Form.Group>
                                  <br/>
                                  <Form.Label>Description</Form.Label>
                                  <CKEditor key={`${item.id}___description`} data={item.description} onChange={(event, editor) => { handleAnyJobChanged({ target: { id: `${item.id}___description`, value: editor.getData() } }) } } editor={ClassicEditor}></CKEditor>
                                  <Form.Text className='text-muted'>The formatted description of the posting.</Form.Text>
                                </Form.Group>
                                <Form.Group>
                                  <br/>
                                  <Form.Label>Indeed URL</Form.Label>
                                  <Form.Control key={`${item.id}___indeedUrl`} id={`${item.id}___indeedUrl`}  type='url' placeholder='https://www.indeed.com/' defaultValue={item.indeedUrl} onChange={handleAnyJobChanged}></Form.Control>
                                  <Form.Text className='text-muted'>The URL that the button on the website will navigate the user to.</Form.Text>
                                </Form.Group>
                                <Form.Group>
                                  <br/>
                                  <Form.Label>Active</Form.Label>
                                  <div style={{fontSize: '20px'}}>
                                    <Form.Check key={`${item.id}___active`} id={`${item.id}___active`} type='switch' checked={item.active} onChange={handleAnyJobChanged}></Form.Check>
                                  </div>
                                  <Form.Text className='text-muted'>Allows the content to still be saved, but not visible on the website.</Form.Text>
                                </Form.Group>
                                <Form.Group>
                                  <br/>
                                  <Button onClick={saveJobs} title='Saves all of the postings in the order they are in below.'>Quick Save</Button>
                                  <br/>
                                </Form.Group>
                              </Form>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
              )
            }
          </Droppable>
        </DragDropContext>
      }
      </>
    )
  }, [
    jobs, notify, handleOnDragEnd, handleVisbilityMapChange, visibilityStateMap, unsavedChanges, modalVisible,
    saveTemplate, template, templateVisible, closeTemplateModal, handleAnyJobChanged, selectedFilter
  ])

  return (
    <>{buildView}</>
  )
}

export default JobsComponent
