import { BrowserUtils } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
// import { useNavigate } from 'react-router-dom'

const Logout = () => {
  const { instance } = useMsal();

  useEffect(() => {
    const clearSessionCache = () => {
      // Clear MSAL cache
      instance.logoutRedirect({
        account: instance.getActiveAccount(),
        postLogoutRedirectUri: "/login",
        onRedirectNavigate: () => !BrowserUtils.isInIframe(),
      });

      // Clear sessionStorage and localStorage (if applicable)
      sessionStorage.clear(); // Clear sessionStorage
      localStorage.clear(); // Clear localStorage if used

      // Optionally clear any specific keys
      // sessionStorage.removeItem("specificKey");
      // localStorage.removeItem("specificKey");
    };

    clearSessionCache();
  }, [instance]);

  return (
    <Container fluid={true} className="vh-100 d-flex">
      <Row className="flex-column flex-grow-1 my-auto">
        <Col className="d-flex mx-auto justify-content-center fs-4">
          <div>Logging out...</div>
        </Col>
      </Row>
    </Container>
  );
};

export default Logout;
