import { TableClient } from '@azure/data-tables'
import { InteractiveBrowserCredential } from '@azure/identity'
import { TENANT_ID, CLIENT_ID } from '../../authConfig'
import { DATA_SERVICES_TABLES, DATA_SERVICES_TABLES_DEV } from '../../common/common.constants'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DateTime } from 'luxon'
import { Button, Card, Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import { CommonProperties } from '../../common/common.interfaces'
import { notify } from '../../notify/notify'

interface DesktopShipperBatchConfigurationComponentProperties extends CommonProperties {
  environment: string
}

function isNumbersOnly(text) {
  // Use a regular expression to check if the text contains only numbers
  return /^\d+$/.test(text);
}

const DAYS_OF_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
const TIMES_OF_DAY = ['06:00', '10:00', '13:00', '15:00']

const DesktopShipperBatchConfigurationComponent = (props: DesktopShipperBatchConfigurationComponentProperties) => {
  const [configs, setConfigs] = useState(undefined)
  const [lastRefresh, setLastRefresh] = useState(DateTime.utc().setZone('America/Chicago').toISO())
  const [validationErrors, setValidationErrors] = useState({})
  const [updateMap, setUpdateMap] = useState(undefined)

  const credential = new InteractiveBrowserCredential({
    tenantId: TENANT_ID,
    clientId: CLIENT_ID,
    authorityHost: 'https://login.microsoft.com'
  })

  /** Loading */
  useEffect(() => {
    const load = async () => {
      const records:any[] = []
      const iterator = new TableClient(props.environment === 'production' ? DATA_SERVICES_TABLES : DATA_SERVICES_TABLES_DEV, 'batchConfiguration', credential).listEntities<any>().byPage()
      const newMap = new Map()
      for await(const page of iterator){
        for await (const value of page.values()){
          value.numberOfOrders = !value.numberOfOrders ? undefined : +value.numberOfOrders
          records.push({...value})
          if(!newMap.has(`${value.partitionKey}___${value.rowKey}`)){
            newMap.set(`${value.partitionKey}___${value.rowKey}`, {...value})
          }
        }
      }
      setUpdateMap(newMap)
      setConfigs(records)
    }
    load()
  }, [lastRefresh, props.environment])

  const handleTextChanged = useCallback((e) => {
    const splitId = e.target.id.split('___')
    const pKey = splitId[0]
    const rKey = splitId[1]
    const errors = {...validationErrors}
    const mapKey = `${pKey}___${rKey}`
    const newMap = new Map(updateMap)
    const updateRecord = {...updateMap.get(mapKey)}
    if(e.target.value.trim() === '0'){
      errors[mapKey] = {}
      errors[mapKey].error = '* Cannot use zero! Please use the Unset button if you are removing the order amount per batch.'
      setValidationErrors(errors)
      return
    } else {
      if(!isNumbersOnly(e.target.value)){
        errors[mapKey] = {}
        errors[mapKey].error = '* Invalid Number! Please use the Unset button if you are removing the order amount per batch.'
        setValidationErrors(errors)
      } else {
        delete errors[mapKey]
        setValidationErrors(errors)
        updateRecord.numberOfOrders = +e.target.value
      }
      newMap.set(mapKey, updateRecord)
      setUpdateMap(newMap)   
    }
  }, [updateMap, validationErrors])

  const handleUnsetClicked = useCallback((e) => {
    const splitId = e.target.id.split('___')
    const rKey = splitId[1]
    const newMap = new Map(updateMap)
    const updateRecord = {...updateMap.get(e.target.id)}
    if(typeof updateRecord.numberOfOrders === 'undefined'){
      /** Do nothing */
      return
    }
    updateRecord.numberOfOrders = undefined
    newMap.set(e.target.id, {...updateRecord})
    setUpdateMap(newMap)
  }, [updateMap])

  const handleSaveClicked = useCallback((e) => {
    const save = async () => {
      for(const key of updateMap.keys()){
        const splitId = key.split('___')
        const updateRecord = updateMap.get(key)
        const regularRecord = configs.find(r => r.partitionKey === splitId[0] && r.rowKey === splitId[1])
        let requiresUpdate = false
        const message = []
        if(updateRecord.partitionKey === 'timing'){
          const updatedDaysOfWeek = JSON.parse(updateRecord.daysOfWeek).sort()
          const regularDaysOfWeek = JSON.parse(regularRecord.daysOfWeek).sort()
          if(JSON.stringify(updatedDaysOfWeek) !== JSON.stringify(regularDaysOfWeek)){
            message.push(`${splitId[1].toUpperCase()} days of the week set to ${updatedDaysOfWeek.join(', ')} successfully!`)
            requiresUpdate = true
          }
          const updatedTimeOfDay = JSON.parse(updateRecord.timeOfDay).sort()
          const regularTimeOfDay = JSON.parse(regularRecord.timeOfDay).sort()
          if(JSON.stringify(updatedTimeOfDay) !== JSON.stringify(regularTimeOfDay)){
            message.push(`${splitId[1].toUpperCase()} time of day set to ${updatedTimeOfDay.join(', ')} successfully!`)
            requiresUpdate = true
          }
          if(updateRecord.endOfDayTime !== regularRecord.endOfDayTime){
            message.push(`${splitId[1].toUpperCase()} end of day set to ${updateRecord.endOfDayTime} successfully!`)
            requiresUpdate = true
          }
        } else {
          if(updateRecord.numberOfOrders !== regularRecord.numberOfOrders){
            message.push(`${splitId[1]} batch chunk size set to ${updateRecord.numberOfOrders ?? 'nothing'} successfully!`)
            requiresUpdate = true
          }
        }
        if(requiresUpdate){
          await new TableClient(props.environment === 'production' ? DATA_SERVICES_TABLES : DATA_SERVICES_TABLES_DEV, 'batchConfiguration', credential).upsertEntity({...updateRecord}, 'Replace')
          notify('success', message.join(' '))
        }
      }
      setLastRefresh(DateTime.utc().setZone('America/Chicago').toISO())
    }
    save()
  }, [updateMap, configs])

  const buildView = useMemo(() => {
    if(typeof configs === 'undefined') return
    if(typeof updateMap === 'undefined') return

    const timingDivs = []
    const configDivs = []
    let index = 0
    for(const record of configs){
      const mapKey = `${record.partitionKey}___${record.rowKey}`
      const updateRecord = updateMap.get(mapKey)
      const needsUpdate = JSON.stringify(updateRecord) !== JSON.stringify(record)
      const hasError = typeof validationErrors[mapKey] !== 'undefined'
      if(record.partitionKey === 'config'){
        configDivs.push(<div key={record.rowKey}>
          <Form.Group>
            <Form.Label>{record.rowKey}</Form.Label>
            <Form.Control onChange={handleTextChanged} key={mapKey} id={mapKey} tabIndex={0} type='text' style={{width: '5%', borderColor: hasError ? 'red' : needsUpdate ? 'blue' : 'gray'}} value={!updateRecord.numberOfOrders ? '' : updateRecord.numberOfOrders}></Form.Control>
            <Button disabled={typeof record.numberOfOrders === 'undefined'} tabIndex={index+999} onClick={handleUnsetClicked} id={mapKey}>Unset</Button>
            {needsUpdate ? <em>Was {record.numberOfOrders ?? 'nothing'}</em> : ''}
            {validationErrors[mapKey] ? <><br/><em style={{color: 'red'}}>{validationErrors[mapKey].error}</em></> : ''}
          </Form.Group>
          <hr/>
        </div>)
        index += 1
      } else if(record.partitionKey === 'timing'){
        const daysOfWeek = JSON.parse(updateRecord.daysOfWeek)
        const timesOfDay = JSON.parse(updateRecord.timeOfDay)

        const dayButtons = []
        for(const day of DAYS_OF_WEEK){
          dayButtons.push(<ToggleButton key={`${mapKey}___${day}`} id={`${mapKey}___${day}`} value={day} type='checkbox' variant={daysOfWeek.includes(day) ? 'success' : 'secondary'}>{day}</ToggleButton>)
        }

        const timeButtons = []
        for(const time of TIMES_OF_DAY){
          timeButtons.push(<ToggleButton key={`${mapKey}___${time}`} id={`${mapKey}___${time}`} value={time} type='checkbox' variant={timesOfDay.includes(time) ? 'primary' : 'secondary'}>{time}</ToggleButton>)
        }

        timingDivs.push(<div key={`${mapKey}___div`}>
          <strong>{record.rowKey.toUpperCase()}</strong>
          <br/>
          <ToggleButtonGroup key='tgDays' type='checkbox' value={daysOfWeek} className='mb-2'>
          {dayButtons}
          </ToggleButtonGroup>
          <br/>
          <ToggleButtonGroup key='tgTimes' type='checkbox' value={timesOfDay} className='mb-2'>
          {timeButtons}
          </ToggleButtonGroup>
          <Form.Group>
            <Form.Label>End of Day Run</Form.Label>
            <Form.Control type='text' readOnly id={`${mapKey}___eod`} key={`${mapKey}___eod`} value={updateRecord.endOfDayTime} style={{width: '50px', borderColor: hasError ? 'red' : needsUpdate ? 'blue' : 'gray'}}></Form.Control>
          </Form.Group>
          <br/> <em>The end of day run batches all orders left over at that time regardless of batch chunk size. Please ask an admin to update these values.</em>
        </div>)
      }
    }

    const toUpdateDivs = []
    let hasUpdates = false
    for(const key of updateMap.keys()){
      const updateRecord = updateMap.get(key)
      const splitKey = key.split('___')
      const regularRecord = configs.find(r => r.partitionKey === splitKey[0] && r.rowKey === splitKey[1])
      if(updateRecord.numberOfOrders !== regularRecord.numberOfOrders){
        toUpdateDivs.push(<div><strong>{splitKey[1]}</strong> Chunk Size to be updated from <strong>{regularRecord.numberOfOrders ?? 'nothing'}</strong> to <strong>{updateRecord.numberOfOrders ?? 'nothing'}</strong></div>)
        hasUpdates = true
      }
      if(typeof updateRecord.daysOfWeek !== 'undefined'){
        const updatedDaysOfWeek = JSON.parse(updateRecord.daysOfWeek).sort()
        const regularDaysOfWeek = JSON.parse(regularRecord.daysOfWeek).sort()
        if(JSON.stringify(updatedDaysOfWeek) !== JSON.stringify(regularDaysOfWeek)){
          toUpdateDivs.push(<div><strong>{splitKey[1].toUpperCase()}</strong> Days of Week to be updated from <strong>{regularDaysOfWeek.join(', ')}</strong> to <strong>{updatedDaysOfWeek.join(', ')}</strong></div>)
          hasUpdates = true
        }
      }
      if(typeof updateRecord.timeOfDay !== 'undefined'){
        let updatedTimesOfDay = JSON.parse(updateRecord.timeOfDay)
        updatedTimesOfDay = updatedTimesOfDay.sort()

        let regularTimesOfDay = JSON.parse(regularRecord.timeOfDay)
        regularTimesOfDay = regularTimesOfDay.sort()
        if(JSON.stringify(updatedTimesOfDay) !== JSON.stringify(regularTimesOfDay)){
          toUpdateDivs.push(<div><strong>{splitKey[1].toUpperCase()}</strong> Time of Day to be updated from <strong>{regularTimesOfDay.join(', ')}</strong> to <strong>{updatedTimesOfDay.join(', ')}</strong></div>)
          hasUpdates = true
        }
      }
      if(typeof updateRecord.endOfDayTime !== 'undefined'){
        if(updateRecord.endOfDayTime !== regularRecord.endOfDayTime){
          toUpdateDivs.push(<div><strong>{splitKey[1].toUpperCase()}</strong> End of Day to be updated from <strong>{regularRecord.endOfDayTime}</strong> to <strong>{updateRecord.endOfDayTime}</strong></div>)
          hasUpdates = true
        }
      }
    }
    return(
      <>
      <Card>
        <Card.Header>
          <Card.Title>
            Timing (READ-ONLY)
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {timingDivs}
        </Card.Body>
      </Card>
      <hr/>
      {hasUpdates && <>
          <Card>
            <Card.Header>
              <Card.Title>
                Save Preview
              </Card.Title>
            </Card.Header>
            <Card.Body>
              {toUpdateDivs}
            </Card.Body>
          </Card>
          <br/>
        </>
      }
      <Card>
        <Card.Header>
          <Card.Title key={`orderbatchamounts`}>
            Order Batch Amounts
          </Card.Title>
        </Card.Header>
        <Card.Body>
          Orders per batch chunk. An empty/unset field denotes any amount when the batch runs.
          {configDivs}
          <Button disabled={!hasUpdates} variant="success" onClick={handleSaveClicked}>Save</Button><br/>
          
          </Card.Body>
        </Card>
        
      </>
    )
  }, [configs, validationErrors, updateMap, handleSaveClicked, handleTextChanged])

  return (
    <>
      <div>
        {buildView}
      </div>
    </>


  )
}

export default DesktopShipperBatchConfigurationComponent