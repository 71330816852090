import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
interface NewJobComponentProperties {
  modalVisible: boolean
  closeModal: any //pass through function
  saveJob: any //pass through function
  otherJobTitles: string[]
  template: string
  showToast: any
}

const NewJobComponent = (props: NewJobComponentProperties) => {
  const [content, setContent] = useState(undefined)
  const [title, setTitle] = useState(undefined)
  const [indeedUrl, setIndeedUrl] = useState(undefined)
  const [active, setActive] = useState(false)

  useEffect(() => {
    setContent(props.template)
  }, [])

  const handleTitleChange = useCallback((e) => {
    setTitle(e.target.value)
  }, [])

  const handleIndeedUrlChange = useCallback((e) => {
    setIndeedUrl(e.target.value)
  }, [])

  const handleActiveChange = useCallback((e) => {
    setActive(e.target.checked)
  }, [])

  const handleFormSubmit = useCallback((e) => {
    e.preventDefault()
  }, [])

  const handleSaveJob = useCallback((e) => {
    if(title && title.trim().length >= 5 && indeedUrl && indeedUrl.trim().length > 7){
      props.saveJob({
        id: DateTime.utc().toMillis().toString(),
        title,
        description: content ?? props.template,
        indeedUrl,
        active
      })
    }
  }, [title, content, indeedUrl, active, props.template, props.otherJobTitles])

  return (
    <Modal show={props.modalVisible} animation={false} onHide={props.closeModal} className='modal-xl'>
      <Modal.Header closeButton>
        <Modal.Title>
          New Posting
        </Modal.Title>
      </Modal.Header>
        
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control onChange={handleTitleChange} type='text' placeholder='Enter Title' required minLength={5}></Form.Control>
            <Form.Text className='text-muted'>The title will be displayed on the site as the Accordion header. This is a unique identifier for this process.</Form.Text>
          </Form.Group>
          <Form.Group>
            <br/>
            <Form.Label>Description</Form.Label>
            <CKEditor data={props.template} onChange={(event, editor)=> { setContent(editor.getData()) }} editor={ClassicEditor}></CKEditor>
            <Form.Text className='text-muted'>The formatted description of the posting.</Form.Text>
          </Form.Group>
          <Form.Group>
            <br/>
            <Form.Label>Indeed URL</Form.Label>
            <Form.Control type='url' placeholder='https://www.indeed.com/' onChange={handleIndeedUrlChange} required></Form.Control>
            <Form.Text className='text-muted'>The URL that the button on the website will navigate the user to.</Form.Text>
          </Form.Group>
          <Form.Group>
            <br/>
            <Form.Label>Active</Form.Label>
            <div style={{fontSize: '20px'}}>
              <Form.Check onChange={handleActiveChange} type='switch'></Form.Check>
            </div>
            <Form.Text className='text-muted'>Allows the content to still be saved, but not visible on the website.</Form.Text>
          </Form.Group>
          <Form.Group>
          <br/>
          <Button variant='success' type='submit' onClick={handleSaveJob}>Save</Button>
          <Button variant='danger' onClick={props.closeModal}>Cancel</Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>                    
  )
}

export default NewJobComponent