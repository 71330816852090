import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSpinner } from '../spinner/SpinnerContext'
import { ContainerClient } from '@azure/storage-blob'
import { Button, Form, Table } from 'react-bootstrap'
import { blobToString } from '../blobs/blobs.service'
import AmazonSFPConfigEdit from './AmazonSFPConfigEdit'
import { DateTime } from 'luxon'
import AmazonSFPConfigListingsEdit from './AmazonSFPConfigListingsEdit'
import AmazonSFPConfigAdvancedEdit from './AmazonSFPConfigAdvancedEdit'
import { notify } from '../notify/notify'
interface AmazonSFPConfigComponentProps {
  containerClient: ContainerClient
}
export interface AmazonSFPConfig {
  days: string[]
  setTimeCentral: string
  lastSet: string
  enabled: boolean
  setListingTo: boolean
  emailTo: string[]
  listings: {listingName: string, Id: string}[]
}

const AmazonSFPConfigComponent = (props: AmazonSFPConfigComponentProps) => {
  const { showSpinner, hideSpinner } = useSpinner()
  const [configs, setConfigs]: [{name: string, content: AmazonSFPConfig, lastEdit: string}[], any] = useState(undefined)
  const [editConfig, setEditConfig]: [{name: string, content: AmazonSFPConfig, lastEdit: string}, any] = useState(undefined)
  const [showEditModal, setShowEditModal] = useState(false)
  const [showEditListingsModal, setShowEditListingsModal] = useState(false)
  const [showAdvancedEditModal, setShowAdvancedEditModal] = useState(false)
  const [refresh, setRefresh] = useState(undefined)
  const [hideDisabled, setHideDisabled] = useState(true)
  const [saveConfig, setSaveConfig]: [{name: string, content: AmazonSFPConfig}, any] = useState(undefined)
  
  /** Updates configs when saveConfig is set by any save functions. */
  useEffect(() => {
    const save = async function() {
      if(!saveConfig) return
      const blockBlobClient = props.containerClient.getBlockBlobClient(saveConfig.name)
      const content = JSON.stringify(saveConfig.content, null, 2)
      await blockBlobClient.upload(content, content.length)
      notify('success', `Successfully saved ${saveConfig.name}`)
      setRefresh(DateTime.utc().setZone('America/Chicago').toISO())
    }
    save()
  }, [saveConfig])

  /** Loads configs initially and when refresh is set. */
  useEffect(() => {
    const readBlobs = async function () {
      showSpinner()
      try {
        const blobItems = props.containerClient.listBlobsFlat({prefix: 'inventory-service/amazon/'})
        const newConfigs: {name: string, content: any, lastEdit: string}[] = []
        for await(const blobItem of blobItems){
          const blobClient = props.containerClient.getBlobClient(blobItem.name)
          const data = await blobClient.download()
          const downloaded = await blobToString(await data.blobBody)
          const json = JSON.parse(downloaded)
          newConfigs.push({
            name: blobItem.name,
            content: json,
            lastEdit: DateTime.fromJSDate(blobItem.properties.lastModified).setZone('America/Chicago').toFormat('MM/dd/yyyy t')
          })
        }
        setConfigs(newConfigs)
      }catch(error){
        console.error(error)
      }
      hideSpinner()
    }
    readBlobs()
  }, [props.containerClient, refresh])

  /** Displays the Edit Settings Modal */
  const handleEditClicked = useCallback((e) => {
    const config = configs.find(c => c.name === e.target.id)
    setEditConfig({name: config.name, content: config.content})
    setShowEditModal(true)
  }, [configs])

  /** Displays the Edit Listings Modal */
  const handleEditListingsClicked = useCallback((e) => {
    const config = configs.find(c => c.name === e.target.id)
    setEditConfig({name: config.name, content: config.content})
    setShowEditListingsModal(true)
  }, [configs])

  /** Displays the Advanced JSON Edit Modal */
  const handleAdvancedEditClicked = useCallback((e) => {
    const config = configs.find(c => c.name === e.target.id)
    setEditConfig({name: config.name, content: config.content})
    setShowAdvancedEditModal(true)
  }, [configs])

  /** Generates the table that displays configuration properties and actions. */
  const view = useMemo(() => {
    if(!configs || !props.containerClient) return 
    const rows = []
    for(const config of configs){
      if(hideDisabled === true && config.content.enabled === false) continue

      rows.push(<tr key={config.name}>
        <td><strong>{config.name.split('/').pop()}</strong></td>
        <td style={{color: config.content.enabled ? 'green' : 'red'}}>{config.content.enabled ? 'Enabled' : 'Disabled'}</td>
        <td>{config.lastEdit}</td>
        <td>{DateTime.fromISO(config.content.lastSet).setZone('America/Chicago').toFormat('MM/dd/yyyy t')}</td>
        <td>{config.content.listings?.length}</td>
        <td>{config.content.days?.join(', ')}</td>
        <td>{config.content.setTimeCentral}</td>
        <td style={{color: config.content.setListingTo ? 'green' : 'red'}}>{config.content.setListingTo ? 'Enabled' : 'Disabled'}</td>
        <td><Button id={config.name} onClick={handleEditListingsClicked}>Listings</Button></td>
        <td><Button id={config.name} onClick={handleEditClicked}>Settings</Button></td>
        <td><Button id={config.name} onClick={handleAdvancedEditClicked}>JSON</Button></td>
      </tr>)
    }
    return <>
      <Table striped hover>
        <thead>
          <tr>
            <th>Config File Name</th>
            <th>Config Enabled</th>
            <th>Config Modified On</th>
            <th>Last Run On</th>
            <th># Listings</th>
            <th>Days</th>
            <th>Run At (Central)</th>
            <th>SFP Set To</th>
            <th>Modify Listings</th>
            <th>Modify Settings</th>
            <th>JSON Edit</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    </>
  }, [configs, props.containerClient, hideDisabled])

  /** Closes the Edit Settings Modal without saving changes. */
  const handleCloseModal = useCallback((e) => {
    setEditConfig(undefined)
    setShowEditModal(false)
  }, [])

  /** Saves the changes made from the Edit Settings Modal. */
  const handleSaveConfig = useCallback((newConfig: any, configName: string) => {
    setSaveConfig({content: newConfig, name: configName})
    setEditConfig(undefined)
    setShowEditModal(false)
  }, [configs])

  /** Closes the Edit Listings Modal without saving changes. */
  const handleCloseListingsModal = useCallback((e) => {
    setEditConfig(undefined)
    setShowEditListingsModal(false)
  }, [])

  /** Opens the Edit Settings Modal as a new configuration. */
  const handleNewConfigurationClicked = useCallback((e) => {
    setEditConfig({})
    setShowEditModal(true)
  }, [])

  /** Saves the changes made from the Edit Listings Modal. */
  const handleSaveListings = useCallback((listings, configName) => {
    const config = configs.find(c => c.name === configName)
    config.content.listings = listings
    setEditConfig(undefined)
    setShowEditListingsModal(false)
    setSaveConfig({content: config.content, name: configName})
  }, [configs])

  /** Closes the Advanced Edit JSON Modal without saving changes. */
  const handleCloseAdvancedEditModal = useCallback((e) => {
    setEditConfig(undefined)
    setShowAdvancedEditModal(false)
  }, [])

  /** Saves the changes made from the Advanced Edit JSON Modal. */
  const handleSaveAdvancedEditModal = useCallback((content: string, configName: string) => {
    setEditConfig(undefined)
    setShowAdvancedEditModal(false)
    setSaveConfig({content: JSON.parse(content), name: configName})
  }, [])

  /** Toggles showing/hiding Disabled configurations. */
  const handleHideDisabledChanged = useCallback((e) => {
    setHideDisabled(e.target.checked)
  }, [])

  return (
    <>
      <Button variant='success' onClick={handleNewConfigurationClicked}>New Configuration</Button>
      <Form.Check type='switch' label='Hide Disabled' defaultChecked={hideDisabled} onChange={handleHideDisabledChanged}></Form.Check>
      {view}
      <AmazonSFPConfigAdvancedEdit closeModal={handleCloseAdvancedEditModal} configName={editConfig?.name} content={JSON.stringify(editConfig?.content, null, 2)} saveConfig={handleSaveAdvancedEditModal} visible={showAdvancedEditModal}></AmazonSFPConfigAdvancedEdit>
      <AmazonSFPConfigEdit existingConfigNames={configs?.map(c => c.name)} closeModal={handleCloseModal} config={editConfig} saveConfig={handleSaveConfig} visible={showEditModal}></AmazonSFPConfigEdit>
      <AmazonSFPConfigListingsEdit closeModal={handleCloseListingsModal} configName={editConfig?.name} listings={editConfig?.content?.listings} saveConfig={handleSaveListings} visible={showEditListingsModal}></AmazonSFPConfigListingsEdit>
    </>
  )
}

export default AmazonSFPConfigComponent