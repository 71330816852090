import { DateTime } from 'luxon'
import { useCallback, useMemo, useState } from 'react'
import { Card, Accordion, Container, Row, Col, Button, Dropdown, Form } from 'react-bootstrap'
import JSONPretty from 'react-json-pretty'
import { CUSTOM_JSON_THEME } from '../common/common.constants'
import { CommonProperties } from '../common/common.interfaces'
import CustomMenuComponent from '../dropdowns/CustomMenuComponent'
import { SalsifyTableRow } from './salsify.interfaces'
import { useSpinner } from '../spinner/SpinnerContext'

interface SalsifySearchComponentProperties extends CommonProperties {
  salsifyTableService: any
  mpns: string[]
}

const SalsifySearchComponent = (props: SalsifySearchComponentProperties) => {
  const { showSpinner, hideSpinner } = useSpinner();
  const [searchByHours, setSearchByHours] = useState(1)
  const [searchBySku, setSearchBySku] = useState(undefined)
  const [searchRecords, setSearchRecords]: [SalsifyTableRow[], any] = useState(undefined)
  
  const handleSearchButtonClicked = useCallback((e) => {
    const search = async () => {
      setSearchBySku(e.target.id)
      showSpinner()

      const records = props.salsifyTableService.listEntities({queryOptions: {filter: `mpn eq '${e.target.id}'`}})
      const awaitedRecords = []
      for await(const record of records){
        awaitedRecords.push(record)
      }
      setSearchRecords(awaitedRecords)
      hideSpinner()
    }
    search()
  }, [])

  const handleSearchByHoursButtonClicked = useCallback((e) => {
    const search = async () => {
      showSpinner()

      const records = props.salsifyTableService.listEntities({queryOptions: {filter: `Timestamp gt datetime'${DateTime.utc().minus({hours: +searchByHours}).toISO()}'`}})
      const awaitedRecords = []
      for await(const record of records){
        awaitedRecords.push(record)
      }
      setSearchRecords(awaitedRecords)
      hideSpinner()
    }
    search()
  }, [searchByHours])

  const handleSearchByHoursChanged = useCallback((e) => {
    setSearchByHours(e.target.value)
  }, [])

  const buildSearch = useMemo(() => {
    const records = []
    if(typeof searchRecords !== 'undefined'){
      for(const record of searchRecords){
        const foundImageUrl = JSON.parse(record.payload).Image_URL_1__c
        const errors = !record.errors ? '' : JSON.parse(record.errors).message
        records.push(
            <Card>
              <Card.Header>
                <Card.Title>
                  {record.mpn}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                {record.salesforceId ? <a target="_blank" rel="noopener noreferrer" href={`https://nethealthshopsllc.lightning.force.com/${record.salesforceId}`}>Salesforce</a> : ''}
                <Form>
                  <Form.Text className='text-muted'>Record Type</Form.Text>
                  <Form.Control readOnly={true} defaultValue={record.partitionKey.toUpperCase()}></Form.Control>

                  <Form.Text className='text-muted'>Product's MPN</Form.Text>
                  <Form.Control readOnly={true} defaultValue={record.mpn}></Form.Control>

                  <Form.Text className='text-muted'>Status</Form.Text>
                  <Form.Control readOnly={true} defaultValue={record.status}></Form.Control>

                  {
                    errors ? <>
                      <Form.Text className='text-muted'>Errors</Form.Text>
                      <Form.Control readOnly={true} defaultValue={errors}></Form.Control>
                    </> : ''
                  }

                  <Form.Text className='text-muted'>Hash</Form.Text>
                  <Form.Control readOnly={true} defaultValue={record.hash}></Form.Control>

                  <Form.Text className='text-muted'>Last Updated</Form.Text>
                  <Form.Control readOnly={true} defaultValue={DateTime.fromISO(record.timestamp).setZone('local').toFormat('MM/dd/yyyy t')}></Form.Control>

                  <Accordion>
                    <Accordion.Header>Payload</Accordion.Header>
                    <Accordion.Body>
                      <JSONPretty data={record.payload} theme={CUSTOM_JSON_THEME} style={{fontSize: '1.5em'}} ></JSONPretty>
                    </Accordion.Body>
                  </Accordion>
                  <Accordion>
                    <Accordion.Header>Image</Accordion.Header>
                    <Accordion.Body>
                      {foundImageUrl ? <img style={{width: '50%'}} src={foundImageUrl}></img> : ''}
                    </Accordion.Body>
                  </Accordion>
                </Form>

              </Card.Body>
            </Card>
          )
      }
    }

    return (
      <>
        <Container>
          <Row>
            <Col>
              <Form.Control onChange={handleSearchByHoursChanged} style={{width: '50px'}} defaultValue={1}></Form.Control>
              <Form.Label className='text-muted'>Enter Hours to Look Back</Form.Label>
              <br/>
              <Button onClick={handleSearchByHoursButtonClicked}>Search By Hours</Button>
            </Col>
            <Col>
              <div style={{fontSize: '1.3em'}}>
              <CustomMenuComponent title={'MPN'} selected={searchBySku} items={props.mpns.map(r => {return <Dropdown.Item key={`${r}`} id={`${r}`} onClick={handleSearchButtonClicked}>{`${r}`}</Dropdown.Item> })}></CustomMenuComponent>          
              </div>
              <Form.Label className='text-muted'>Search By MPN</Form.Label>
              
            </Col>
          </Row>
        </Container>
        <hr/>
        {records.length} record(s) found
        <hr/>
        {records}
      </>
    )
  }, [searchBySku, searchRecords, props.mpns, handleSearchByHoursChanged, handleSearchByHoursButtonClicked])

  return buildSearch
}

export default SalsifySearchComponent
