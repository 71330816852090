import { useMemo } from 'react'
import { Modal, Button, Table, Collapse, Accordion } from 'react-bootstrap'
import JSONPretty from 'react-json-pretty'
import { CUSTOM_JSON_THEME } from '../common/common.constants'
import { CommonProperties } from '../common/common.interfaces'
import AccordionItem from 'react-bootstrap/esm/AccordionItem'
import AccordionBody from 'react-bootstrap/esm/AccordionBody'
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader'

interface OrderRoutingDebugModalComponentProperties extends CommonProperties {
  modalVisible: boolean
  closeModal: any
  blobName: string
  blobData: any
}

const OrderRoutingDebugModalComponent = (props: OrderRoutingDebugModalComponentProperties) => {
  if(!props.blobData) return  

  const view = useMemo(() => {
    const data = JSON.parse(props.blobData)
    let rows = []
    for(const record of data){
      rows.push(<tr>
        <th>{record.variable}</th>
        <td>{record.formula}</td>
        <td>
          {record.output.length > 255 ? 
          <Accordion>
          <AccordionHeader>{record.variable}</AccordionHeader>
            <AccordionBody>
              <AccordionItem eventKey={record.variable}>
                <JSONPretty data={record.output} theme={CUSTOM_JSON_THEME} style={{fontSize: '1.5em'}} ></JSONPretty>

              </AccordionItem>
            </AccordionBody>
          </Accordion>
          : record.output
          }
          
        </td>
      </tr>)
    }
    return (
      <Modal show={props.modalVisible} animation={false} onHide={props.closeModal} className='modal-xl'>
        <Modal.Header closeButton>
          <Modal.Title>
            {props.blobName}
          </Modal.Title>
        </Modal.Header>
        <br/>

        <Modal.Body>
          <Table striped bordered hover>
            <tbody>
              {rows}
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='danger' onClick={props.closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  }, [])

  return view
}

export default OrderRoutingDebugModalComponent
