import { Table as BTable } from "react-bootstrap";
import { flexRender, Row, Table as TableType } from "@tanstack/react-table";

interface CustomTableProps<TData> {
  table: TableType<TData>; // The table instance from react-table
}

const TsTable = <TData,>({ table }: CustomTableProps<TData>) => {
  return (
    <BTable striped bordered hover responsive size="sm">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                className={`text-center ${header.column.getCanSort() ? "cursor-pointer select-none" : ""}`}
                key={header.id}
                colSpan={header.colSpan}
                onClick={header.column.getToggleSortingHandler()}
              >
                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                {{
                  asc: " 🔼",
                  desc: " 🔽",
                }[header.column.getIsSorted() as string] ?? null}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row: Row<TData>) => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </BTable>
  );
};

export default TsTable;
