import { Button, Form, Row, Col } from "react-bootstrap"

const UploadAmazonViews = ({ handleFileChange, uploadFile }) => { 
  return(
    <Form>
      <Row className="align-items-center">
        <Form.Group controlId="formFile" className="mb-3">
          <Form.Label>Upload More Data</Form.Label>
          <Form.Control type="file" onChange={handleFileChange}/>
        </Form.Group>
      </Row>
      <Row className="align-items-center">
        <Button variant="outline-primary" onClick={uploadFile}>Upload TSV File</Button>
      </Row>
    </Form>
  )
}

export default UploadAmazonViews;