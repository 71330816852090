import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, ResponsiveContainer, ReferenceLine } from 'recharts'
import { useProjectedGraphDataContext } from '../contexts/amazonPageSpeedViewsProjectedDataContext';

const AmazonProjectedViewGraph = ({}) => { 
  
  const ONE_DAY_PERCENTAGE_REQUIREMENT = 10;
  const TWO_DAY_PERCENTAGE_REQUIREMENT = 45;
  const {projectedGraphData} = useProjectedGraphDataContext()

  return(
    <div className="p-3 shadow-sm bg-light rounded">
      <p className="fs-2">Page Views</p>
      <ResponsiveContainer width="100%" height={455}>
        <LineChart data={projectedGraphData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis domain={[0, 80]} tickFormatter={(tick) => `${tick}%`} />
          <Legend />
          <ReferenceLine y={TWO_DAY_PERCENTAGE_REQUIREMENT} label="<= 2 days Requirement (45%)" stroke="red" strokeDasharray="3 3" />
          <ReferenceLine y={ONE_DAY_PERCENTAGE_REQUIREMENT} label="<= 1 day Requirement (10%)" stroke="green" strokeDasharray="3 3" />
          <Line type="monotone" dataKey="lessThan2DaysPercent" stroke="red" name="<= 2 days" />
          <Line type="monotone" dataKey="lessThan1DayPercent" stroke="green" name="<= 1 day" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default AmazonProjectedViewGraph;