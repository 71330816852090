import { rankItem } from "@tanstack/match-sorter-utils";
import { FilterFn } from "@tanstack/react-table";
import { ProcessingPercentageMap } from "../common/common.interfaces";

// Define a custom fuzzy filter function that will apply ranking info to rows (using match-sorter utils)
export const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

// Custom sorting function for boolean values
export const booleanSort = (rowA, rowB, columnId) => {
  const a = rowA.getValue(columnId);
  const b = rowB.getValue(columnId);
  if (a === b) return 0;
  return a ? -1 : 1;
};

export function getProcessingPercentage(stage: string, processingPercentageMap: ProcessingPercentageMap) {
  if (!stage) return 0;
  for (const [key, percentage] of Object.entries(processingPercentageMap)) {
    if (stage.includes(key)) {
      return percentage;
    }
  }
  // Return 0 or some default value if no match is found
  return 0;
}