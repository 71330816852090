const Header = () => {
  return (
    <div className="d-flex justify-content-center align-items-center p-1">
      <a className="w-25" href='/'>
        <img className='w-100 h-auto' src='Sunnydaze-NHS.png'/>
      </a>
    </div>
  )
}

export default Header