import { Modal, Container, Row, Col, Button, Form } from 'react-bootstrap'
import { AmazonSFPConfig } from './AmazonSFPConfigComponent'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DateTime } from 'luxon'
import { notify } from '../notify/notify'

interface AmazonSFPConfigEditProps {
  config: {name: string, content: AmazonSFPConfig}
  visible: boolean
  closeModal: any
  saveConfig: any
  existingConfigNames: string[]
}

const AmazonSFPConfigEdit = (props: AmazonSFPConfigEditProps) => {
  const [title, setTitle] = useState(undefined)

  const [fileName, setFileName] = useState(undefined)

  const [days, setDays] = useState(undefined)
  const [enabled, setEnabled] = useState(undefined)
  const [time, setTime] = useState(undefined)
  const [emailTo, setEmailTo] = useState(undefined)
  const [listingEnabled, setListingEnabled] = useState(undefined)

  /** Initial loading. */
  useEffect(() => {
    let newTitle = 'New Configuration'

    if(props?.config?.name){
      newTitle = `Edit ${props?.config?.name?.split('/').pop()}`
      setFileName(props.config.name)
    } else {
      setFileName(`inventory-service/amazon/amazon.toggle.sfp.DESCRIBE.ENABLED-DISABLED.json`)
    }
    setTitle(newTitle)
    setDays(props.config?.content?.days ?? [])
    setEnabled(props.config?.content?.enabled ?? true)
    setTime(props.config?.content?.setTimeCentral)
    setEmailTo(props.config?.content?.emailTo ?? ['techteam@nethealthshops.com','owners@nethealthshops.com'])
    setListingEnabled(props.config?.content?.setListingTo ?? false)
  }, [props.config])

  const handleSaveClicked = useCallback((e) => {
    const isNew = title === 'New Configuration'
    if(props.existingConfigNames.includes(fileName) && isNew){
      notify('error', `${fileName} already exists. Please rename.`)
      return
    }
    if(!time || !days){
      notify('error', `Set Time Central and Days are required. Please adjust.`)
      return
    }

    const newConfig: AmazonSFPConfig = {
      days: days ?? [],
      emailTo: emailTo ?? ['techteam@nethealthshops.com','owners@nethealthshops.com'],
      enabled: enabled ?? true,
      lastSet: props.config?.content?.lastSet ?? DateTime.utc().setZone('America/Chicago').minus({days: 1}).toISO(),
      listings: props.config?.content?.listings ?? [],
      setListingTo: listingEnabled ?? false,
      setTimeCentral: time
    }
    if(confirm(`Please confirm...\n${fileName}\n${JSON.stringify({...newConfig, listings: [`${newConfig.listings.length} listings`]}, null, 2)}`)){
      props.saveConfig(newConfig, fileName)
    }
  }, [props.config, props.existingConfigNames, days, enabled, time, emailTo, listingEnabled, fileName, title])

  const handleDayChanged = useCallback((e) => {
    if(!days) return
    let newDays = []
    if(e.target.checked === true){
      //add the day to the array
      newDays = [...days, e.target.value]
    } else {
      // Remove the day from the array
      for(const day of days){
        if(day === e.target.value){
          continue
        }
        newDays.push(day)
      }
    }
    setDays(newDays)
  }, [days])

  const handleEnabledChanged = useCallback((e) => {
    setEnabled(e.target.checked)
  }, [])

  const handleSetTimeChanged = useCallback((e) => {
    setTime(e.target.value)
  }, [])

  const handleEmailToChanged = useCallback((e) => {
    setEmailTo(e.target.value?.split(';'))
  }, [])
  
  const handleSetListingToChanged = useCallback((e) => {
    setListingEnabled(e.target.checked)
  }, [])

  const handleFileNameChanged = useCallback((e) => {
    setFileName(`inventory-service/amazon/${e.target.value}`)
  }, [])

  const view = useMemo(() => {
    const allDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    const daysCheckBoxes = []
    for(const day of allDays){
      daysCheckBoxes.push(<Form.Check key={`${day}`} onChange={handleDayChanged} type='switch' id={day} label={day} defaultChecked={props?.config?.content?.days?.includes(day) ?? false} value={day}></Form.Check>)
    }
    return (
      <Modal show={props.visible} onHide={props.closeModal} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Container>
                <Row>
                  <Col>
                    {title === 'New Configuration' ? (<><Form.Control onChange={handleFileNameChanged} defaultValue={'amazon.toggle.sfp.DESCRIBE.ENABLED-DISABLED.json'} placeholder='Enter a file name. amazon.toggle.sfp.describe.enabled/disabled.json'></Form.Control> <em>{fileName}</em> <hr/></>) : ''}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label>Run Days</Form.Label>
                    {daysCheckBoxes}
                  </Col>
                  <Col>
                    <Row>
                      <Col>
                        <Form.Check type='switch' onChange={handleEnabledChanged} defaultChecked={props?.config?.content?.enabled ?? true} label='Config Enabled'></Form.Check>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Label>Set Run Time (Central)</Form.Label>
                        <Form.Control placeholder='23:00' onChange={handleSetTimeChanged} defaultValue={props?.config?.content?.setTimeCentral ?? ''}></Form.Control>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Label>Email To (separated by semi-colon)</Form.Label>
                        <Form.Control placeholder='techteam@nethealthshops.com;owners@nethealthshops.com' onChange={handleEmailToChanged} defaultValue={props?.config?.content?.emailTo?.join(';') ?? 'techteam@nethealthshops.com;owners@nethealthshops.com'}></Form.Control>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Check type='switch' label='Set Listing To' onChange={handleSetListingToChanged} defaultChecked={props?.config?.content?.setListingTo ?? false}></Form.Check>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={props.closeModal}>
              Cancel
            </Button>
            <Button variant='success' onClick={handleSaveClicked}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
    )
  }, [props.config, days, title, fileName, handleDayChanged, handleEmailToChanged, handleEnabledChanged, handleFileNameChanged, handleSaveClicked, handleSetListingToChanged, handleSetTimeChanged])

  return view
}
export default AmazonSFPConfigEdit
