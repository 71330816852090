import { useCallback, useEffect, useMemo, useState } from 'react'
import { InteractiveBrowserCredential } from '@azure/identity'
import { TENANT_ID, CLIENT_ID } from '../authConfig'
import { TableClient } from '@azure/data-tables'
import {DateTime} from 'luxon'
import { BlobServiceClient } from '@azure/storage-blob'
import { Button, ButtonGroup, Table, ToggleButton } from 'react-bootstrap'
import { blobToString } from '../blobs/blobs.service'
import JSONPretty from 'react-json-pretty'
import { CircleFill } from 'react-bootstrap-icons'
import CustomMenuComponent from '../dropdowns/CustomMenuComponent'
import Dropdown from 'react-bootstrap/Dropdown'

import './beacon.css'
import { DATA_SERVICES_TABLES_DEV, DATA_SERVICES_TABLES, DATA_SERVICES_BLOBS_DEV, DATA_SERVICES_BLOBS, CUSTOM_JSON_THEME } from '../common/common.constants'
import BlobModalComponent from '../blobs/BlobModalComponent'
import { useSpinner } from '../spinner/SpinnerContext'
import { notify } from '../notify/notify'

const radios = [
  { name: 'Development', value: 'development' },
  { name: 'Production', value: 'production' }
];

const functionTypes = [
  'timer',
  'blob',
  'http'
]

const BeaconComponent = () => {
  const { showSpinner, hideSpinner } = useSpinner();
  const [initialLoad, setInitialLoad] = useState(true)
  const [beaconRows, setBeaconRows] = useState([])
  const [logsRows, setLogsRows] = useState([])
  const [blob, setBlob] = useState(undefined)
  const [blobData, setBlobData] = useState(undefined)
  const [environment, setEnvironment] = useState('production')
  const [lastRefresh, setLastRefresh] = useState(DateTime.now().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS))
  const [rowsVisibile, setRowsVisible] = useState(new Map())
  const [modalVisible, setModalVisible] = useState(false)

  const credential = new InteractiveBrowserCredential({
    tenantId: TENANT_ID,
    clientId: CLIENT_ID,
    authorityHost: 'https://login.microsoft.com'
  })

  useEffect(() => {

    const intervalSetting = setInterval(
      () => {
        setLastRefresh(DateTime.now().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS))
      }, 30000
    )
    return () => {
      clearInterval(intervalSetting)
    }
  }, [])


  useEffect(() => {
    const refreshRows = async () => {
      if(initialLoad) showSpinner()
      try {
        const beaconTableClient = new TableClient(environment === 'development' ? DATA_SERVICES_TABLES_DEV : DATA_SERVICES_TABLES, 'beacon', credential)
        const tempBeaconRows = beaconTableClient.listEntities()
        const awaitedBeaconRows = []
        for await(const row of tempBeaconRows){
          awaitedBeaconRows.push(row)
        }

        const logsTableClient = new TableClient(environment === 'development' ? DATA_SERVICES_TABLES_DEV : DATA_SERVICES_TABLES, 'logs', credential)
        const tempLogsRows = logsTableClient.listEntities()
        const awaitedLogsRows = []
        for await(const row of tempLogsRows){
          awaitedLogsRows.push(row)
        }
  
        setBeaconRows(awaitedBeaconRows)
        setLogsRows(awaitedLogsRows)

        if(initialLoad) {
          setInitialLoad(false)
          hideSpinner()
        }
      }catch(error){
        console.log(error)
      }

    }
    refreshRows()
  }, [environment, lastRefresh])
  
  const blobClicked = useCallback((e) => {
    if(blob === e.target.id){
      setModalVisible(true)
    } else {
      setBlob(e.target.id)
    }
  }, [blob])

  useMemo(() => {
    const readData = async () => {
      if(typeof blob === 'undefined') return
      showSpinner()
      const blobServiceClient = new BlobServiceClient(environment === 'development' ? DATA_SERVICES_BLOBS_DEV : DATA_SERVICES_BLOBS, credential)
      const containerService = blobServiceClient.getContainerClient('storage')
      const blobClient = containerService.getBlobClient(blob)
      const data = await blobClient.download()
      const downloaded = await blobToString(await data.blobBody)
      const content = (<JSONPretty data={downloaded} theme={CUSTOM_JSON_THEME} style={{fontSize: '1.5em'}} ></JSONPretty>)
      setBlobData(content)
      hideSpinner()
      setModalVisible(true)
    }
    try {
      readData()
    }catch(error){
      notify('error', error)
    }
  }, [blob])

  const closeModal = useCallback(() => {
    setModalVisible(false)
  }, [])

  const blobView = useMemo(() => {
    return BlobModalComponent({blobData, blobName: blob, closeModal, modalVisible})
  }, [blobData, modalVisible, closeModal, blob])

  const toggleRows = useCallback((e) => {
    const key = e.target.id
    const visibleRows = new Map(rowsVisibile)

    visibleRows.set(key, !visibleRows.get(key))
    setRowsVisible(visibleRows)

  }, [rowsVisibile])

  const handleTypeClicked = useCallback((e) => {
    const updateRow = async () => {
      const split = e.target.id.split('___')
      const value = split[0]
      const pKey = split[1]
      const rKey = split[2]

      const newRow = {
        partitionKey: pKey,
        rowKey: rKey,
        functionType: value
      }

      const beaconTableClient = new TableClient(environment === 'development' ? DATA_SERVICES_TABLES_DEV : DATA_SERVICES_TABLES, 'beacon', credential)
      await beaconTableClient.submitTransaction([['upsert', newRow as any]])
  
      setLastRefresh(DateTime.now().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS))
    }
    updateRow()
  }, [beaconRows])

  const handleTimerSet = useCallback((e) => {
    const updateRow = async () => {
      const split = e.target.id.split('___')
      const value = +e.target.value
      const pKey = split[0]
      const rKey = split[1]
      
      const newRow = {
        partitionKey: pKey,
        rowKey: rKey,
        functionTiming: +value
      }

      const beaconTableClient = new TableClient(environment === 'development' ? DATA_SERVICES_TABLES_DEV : DATA_SERVICES_TABLES, 'beacon', credential)
      await beaconTableClient.submitTransaction([['upsert', newRow as any]])
  
      setLastRefresh(DateTime.now().toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS))
    }
    updateRow()
  }, [beaconRows])

  const buildView = useMemo(() => {
    const servicesMap = new Map()
    const channelsMap = new Map()

    const logsMap = new Map()

    for(const row of beaconRows){
      if(row.partitionKey.includes('service')){
        if(!servicesMap.has(row.partitionKey)){
          servicesMap.set(row.partitionKey, [])
        }
        servicesMap.get(row.partitionKey).push(row)
      } else {
        if(!channelsMap.has(row.partitionKey)){
          channelsMap.set(row.partitionKey, [])
        }
        channelsMap.get(row.partitionKey).push(row)
      }

    }

    for(const row of logsRows){
      if(!logsMap.has(row.partitionKey)){
        logsMap.set(row.partitionKey, [])
      }
      logsMap.get(row.partitionKey).push(row)
    }

    const servicesTables = []
    const channelsTables = []
    const reds = []
    const yellows = []
    const errors = []

    /** Services */
    for(const key of servicesMap.keys()){
      const relatedLogs = logsMap.get(key) ?? []
      let anyRed = false
      let anyYellow = false
      let rows = []
      for(const row of servicesMap.get(key)){
        const relatedLog = relatedLogs.find(l => l.rowKey === row.rowKey)
        const lastLogDateTime = DateTime.fromISO(relatedLog?.timestamp)
        let lastLogDateString = lastLogDateTime?.toLocaleString(DateTime.DATETIME_SHORT)
        let lastLogTimeAgo = undefined
        if(lastLogDateString === 'Invalid DateTime'){
          lastLogDateString = ''
        } else {
          lastLogTimeAgo = Math.abs(lastLogDateTime.diffNow('minutes').minutes)
        }
        const lastRunDateTime = DateTime.fromISO(row.lastRun)
        const lastCompleteDateTime = DateTime.fromISO(row.lastComplete)
        const diffInMinutes = Math.abs(lastRunDateTime.diff(lastCompleteDateTime, 'minutes').minutes)
        const diffInSeconds = Math.abs(lastRunDateTime.diff(lastCompleteDateTime, 'seconds').seconds)
        const lastRunDate = lastRunDateTime.toLocaleString(DateTime.DATETIME_SHORT)
        const lastRunDiffInMinutes = Math.abs(lastRunDateTime.diffNow('minutes').minutes)
        const lastCompleteDate = lastCompleteDateTime.toLocaleString(DateTime.DATETIME_SHORT)

        let functionType = ''
        if(typeof row.functionType !== 'undefined'){
          functionType = row.functionType
        }

        const customDropDown = (
          <CustomMenuComponent selected={functionType} title={'Type'} items={functionTypes.map(b => { 
            return <Dropdown.Item key={`${b}`} id={`${b}___${row.partitionKey}___${row.rowKey}___functiontype`} onClick={handleTypeClicked}>{b}</Dropdown.Item>
          })}></CustomMenuComponent>
        )

        let timerTimeInMinutes = '0'
        if(functionType === 'timer'){
          if(typeof row.functionTiming !== 'undefined'){
            timerTimeInMinutes = row.functionTiming
          }
        }

        let timeToCompareRed = +timerTimeInMinutes * 1.2 //TODO: If higher than a day then do other things
        const timeToCompareYellow = +timerTimeInMinutes + 10

        let statusColor = 'green'
        if(functionType === '' || functionType === 'timer'){
          if(lastRunDiffInMinutes > timeToCompareRed || (typeof lastLogTimeAgo !== 'undefined' && lastLogTimeAgo < lastRunDiffInMinutes)){
            statusColor = 'red'
            anyRed = true
          } else if(lastRunDiffInMinutes > timeToCompareYellow || (typeof lastLogTimeAgo !== 'undefined' && lastLogTimeAgo < lastRunDiffInMinutes)){
            statusColor = 'yellow'
            anyYellow = true
          }  
        }

        const tableRow = (
          <>
            <tr key={row.rowKey}>
              <td key={`${row.rowKey}-status`} style={{textAlign: 'center', width: '1%'}}><CircleFill color={statusColor} style={{border: 'solid 1px black', borderRadius: '5px'}}></CircleFill></td>
              <td key={`${row.rowKey}-type`}>
                {customDropDown}
                {functionType === 'timer' ?
                  <input
                  id={`${row.partitionKey}___${row.rowKey}___functiontiming`}
                  type="number"
                  defaultValue={timerTimeInMinutes}
                  onBlur={handleTimerSet}
                  />  : ''
                }
              </td>

              <td key={`${row.rowKey}-function`}><strong>{row.rowKey}</strong></td>
              <td key={`${row.rowKey}-lastRun`} style={{color: statusColor === 'yellow' ? 'grey' : statusColor}}>{lastRunDate}<br/>[{lastRunDiffInMinutes.toFixed(1)}] minute(s) ago.</td>
              <td key={`${row.rowKey}-time`}><strong>{diffInMinutes?.toFixed(0)}</strong> minutes <strong>{diffInSeconds?.toFixed(2)}</strong> seconds.</td>
              <td key={`${row.rowKey}-error`}>
              {lastLogDateString !== '' && lastLogTimeAgo < 5760 ? 
                  <div>Last Error: {lastLogDateString} <strong>[{lastLogTimeAgo.toFixed(1)}] minute(s) ago.</strong> 
                  <Button key={relatedLog.blobName} id={relatedLog.blobName} onClick={blobClicked} variant='danger'>View</Button><br/>
                  <em>{`${relatedLog.details.replace(relatedLog.blobName, '').substring(0, 255)}... View blob for more information. ${relatedLog.details.length} characters long.`}</em>
                  </div> : 
                  ''
              }
              
              </td>
            </tr>
          </>
        )
        if(statusColor === 'red'){
          reds.push(tableRow)
        } else if(statusColor === 'yellow'){
          yellows.push(tableRow)
        }
        
        if(lastLogDateString !== '' && lastLogTimeAgo < 1440){
          errors.push(tableRow)
        }
        rows.push(tableRow)
      }

      const headerColor = anyRed ? 'red' : anyYellow ? 'yellow' : 'green'
      servicesTables.push(<Table key={key} striped bordered hover>
        <thead key={`${key}-thead`}>
          <tr key={`${key}-row`}>
            <th id={key} onClick={toggleRows} key={`${key}-title`}  style={{textAlign: 'center', width: '1%',color: headerColor, minWidth: '100px'}}>{key.toUpperCase()}<br/><CircleFill color={headerColor} style={{border: 'solid 1px black', borderRadius: '2em'}}></CircleFill></th>
            <th>Type</th>
            <th>Function</th>
            <th>Last Run</th>
            <th>Run Time</th>
            <th>Error</th>
          </tr>
        </thead>
        {rowsVisibile.get(key) === true ?
        <tbody>
          {rows}
        </tbody> : ''}
      </Table>)

    }

    /** Channels */
    for(const key of channelsMap.keys()){
      const relatedLogs = logsMap.get(key) ?? []
      let anyRed = false
      let anyYellow = false
      let rows = []
      for(const row of channelsMap.get(key)){
        const relatedLog = relatedLogs.find(l => l.rowKey === row.rowKey)
        const lastLogDateTime = DateTime.fromISO(relatedLog?.timestamp)
        let lastLogDateString = lastLogDateTime?.toLocaleString(DateTime.DATETIME_SHORT)
        let lastLogTimeAgo = undefined
        if(lastLogDateString === 'Invalid DateTime'){
          lastLogDateString = ''
        } else {
          lastLogTimeAgo = Math.abs(lastLogDateTime.diffNow('minutes').minutes)
        }
        const lastRunDateTime = DateTime.fromISO(row.lastRun)
        const lastCompleteDateTime = DateTime.fromISO(row.lastComplete)
        const diffInMinutes = Math.abs(lastRunDateTime.diff(lastCompleteDateTime, 'minutes').minutes)
        const diffInSeconds = Math.abs(lastRunDateTime.diff(lastCompleteDateTime, 'seconds').seconds)
        const lastRunDate = lastRunDateTime.toLocaleString(DateTime.DATETIME_SHORT)
        const lastRunDiffInMinutes = Math.abs(lastRunDateTime.diffNow('minutes').minutes)
        const lastCompleteDate = lastCompleteDateTime.toLocaleString(DateTime.DATETIME_SHORT)

        let functionType = ''
        if(typeof row.functionType !== 'undefined'){
          functionType = row.functionType
        }

        const customDropDown = (
          <CustomMenuComponent selected={functionType} title={'Type'} items={functionTypes.map(b => { 
            return <Dropdown.Item key={`${b}`} id={`${b}___${row.partitionKey}___${row.rowKey}___functiontype`} onClick={handleTypeClicked}>{b}</Dropdown.Item>
          })}></CustomMenuComponent>
        )

        let timerTimeInMinutes = '0'
        if(functionType === 'timer'){
          if(typeof row.functionTiming !== 'undefined'){
            timerTimeInMinutes = row.functionTiming
          }
        }

        const timeToCompareRed = +timerTimeInMinutes * 1.5 //TODO: If higher than a day then do other things
        const timeToCompareYellow = +timerTimeInMinutes + 10

        let statusColor = 'green'
        if(functionType === '' || functionType === 'timer'){
          if(lastRunDiffInMinutes > timeToCompareRed || (typeof lastLogTimeAgo !== 'undefined' && lastLogTimeAgo < lastRunDiffInMinutes)){
            statusColor = 'red'
            anyRed = true
          } else if(lastRunDiffInMinutes > timeToCompareYellow || (typeof lastLogTimeAgo !== 'undefined' && lastLogTimeAgo < lastRunDiffInMinutes)){
            statusColor = 'yellow'
            anyYellow = true
          }  
        }

        const tableRow = (
          <>
            <tr key={row.rowKey}>
              <td key={`${row.rowKey}-status`} style={{textAlign: 'center', width: '1%'}}><CircleFill color={statusColor} style={{border: 'solid 1px black', borderRadius: '5px'}}></CircleFill></td>
              <td key={`${row.rowKey}-type`}>
                {customDropDown}
                {functionType === 'timer' ?
                  <input
                  id={`${row.partitionKey}___${row.rowKey}___functiontiming`}
                  type="number"
                  defaultValue={timerTimeInMinutes}
                  onBlur={handleTimerSet}
                  />  : ''
                }
              </td>

              <td key={`${row.rowKey}-function`}><strong>{row.rowKey}</strong></td>
              <td key={`${row.rowKey}-lastRun`} style={{color: statusColor === 'yellow' ? 'grey' : statusColor}}>{lastRunDate}<br/>[{lastRunDiffInMinutes.toFixed(1)}] minute(s) ago.</td>
              <td key={`${row.rowKey}-time`}><strong>{diffInMinutes?.toFixed(0)}</strong> minutes <strong>{diffInSeconds?.toFixed(2)}</strong> seconds.</td>
              <td key={`${row.rowKey}-error`}>
              {lastLogDateString !== '' && lastLogTimeAgo < 1440 ? 
                  <div>Last Error: {lastLogDateString} <strong>[{lastLogTimeAgo.toFixed(1)}] minute(s) ago.</strong> 
                  <Button key={relatedLog.blobName} id={relatedLog.blobName} onClick={blobClicked} variant='danger'>View</Button><br/>
                  <em>{`${relatedLog.details.replace(relatedLog.blobName, '').substring(0, 255)}... View blob for more information. ${relatedLog.details.length} characters long.`}</em>
                  </div> : 
                  ''
              }
              </td>
            </tr>
          </>
        )
        if(statusColor === 'red'){
          reds.push(tableRow)
        } else if(statusColor === 'yellow'){
          yellows.push(tableRow)
        }
                
        if(lastLogDateString !== '' && lastLogTimeAgo < 1440){
          errors.push(tableRow)
        }
        rows.push(tableRow)
      }
      
      const headerColor = anyRed ? 'red' : anyYellow ? 'yellow' : 'green'
      channelsTables.push(<Table key={key} striped bordered hover>
        <thead key={`${key}-thead`}>
          <tr key={`${key}-row`}>
            <th id={key} onClick={toggleRows} key={`${key}-title`}  style={{textAlign: 'center', width: '1%',color: headerColor, minWidth: '100px'}}>{key.toUpperCase()}<br/><CircleFill color={headerColor} style={{border: 'solid 1px black', borderRadius: '2em'}}></CircleFill></th>
            <th>Type</th>
            <th>Function</th>
            <th>Last Run</th>
            <th>Run Time</th>
            <th>Error</th>
          </tr>
        </thead>
        {rowsVisibile.get(key) === true ?
        <tbody>
          {rows}
        </tbody> : ''}
      </Table>)

    }

    const errorTable = (
      <Table key='error-table' striped bordered hover>
          <thead key={`error-table-thead`}>
          <tr key={`error-table-tr-row`}>
            <th id='error' onClick={toggleRows} key={`error-title`}  style={{textAlign: 'center', width: '1%',color: 'red', minWidth: '100px'}}>ERRORS [{errors.length}]<br/><CircleFill color={'red'} style={{border: 'solid 1px black', borderRadius: '2em'}}></CircleFill></th>
            <th>Type</th>
            <th>Function</th>
            <th>Last Run</th>
            <th>Run Time</th>
            <th>Error</th>
          </tr>
        </thead>
        {rowsVisibile.get('error') === true ?
        <tbody>
          {errors}
        </tbody> : ''}
      </Table>
    )

    const redTable = (
      <Table key='red-table' striped bordered hover>
          <thead key={`red-table-thead`}>
          <tr key={`$red-table-tr-row`}>
            <th id='red' onClick={toggleRows} key={`red-title`}  style={{textAlign: 'center', width: '1%',color: 'red', minWidth: '100px'}}>ISSUES [{reds.length}]<br/><CircleFill color={'red'} style={{border: 'solid 1px black', borderRadius: '2em'}}></CircleFill></th>
            <th>Type</th>
            <th>Function</th>
            <th>Last Run</th>
            <th>Run Time</th>
            <th>Error</th>
          </tr>
        </thead>
        {rowsVisibile.get('red') === true ?
        <tbody>
          {reds}
        </tbody> : ''}
      </Table>
    )

    const yellowTable = (
      <Table key='yellow-table' striped bordered hover>
          <thead key={`yellow-table-thead`}>
          <tr key={`$yellow-table-tr-row`}>
            <th id='yellow' onClick={toggleRows} key={`yellow-title`}  style={{textAlign: 'center', width: '1%',color: 'grey', minWidth: '100px'}}>WARNING [{yellows.length}]<br/><CircleFill color={'yellow'} style={{border: 'solid 1px black', borderRadius: '2em'}}></CircleFill></th>
            <th>Type</th>
            <th>Function</th>
            <th>Last Run</th>
            <th>Run Time</th>
            <th>Error</th>
          </tr>
        </thead>
        {rowsVisibile.get('yellow') === true ?
        <tbody>
          {yellows}
        </tbody> : ''}
      </Table>
    )

    return (
      <>
        {errors.length > 0 ? errorTable : ''}
        {reds.length > 0 ? redTable : ''}
        {yellows.length > 0 ? yellowTable: ''}
        {servicesTables}
        {channelsTables}
      </>
    )
  }, [beaconRows, logsRows, blobClicked, rowsVisibile, toggleRows])

  return (
    <>
      {blobView}
      <div className='beacon'>
        <h1>Beacon</h1>
        <hr/>
        <ButtonGroup>
          {radios.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant={idx % 2 ? 'outline-success' : 'outline-danger'}
              name="radio"
              value={radio.value}
              checked={environment === radio.value}
              onChange={(e) => setEnvironment(e.currentTarget.value)}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
        <br></br>
        <em>Last Refreshed: {lastRefresh} - Log Blobs are deleted automatically after 4 days.</em>
        <div style={{overflowY: 'scroll', height: '100vh'}}>
          {buildView}
        </div>
      </div>
    </>
  )
}

export default BeaconComponent
