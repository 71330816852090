import { Col } from "react-bootstrap"

const AmazonViewSummary = ({ summaries }) => {
  return (
    <div className="p-3 shadow-sm bg-light rounded">
      <p className="fs-2">Summary</p>
      <hr />
      <p>Unique ASINs: {summaries.uniqueASINs}</p>
      <p>Unique Oversize ASINs: {summaries.uniqueOversizeASINs}</p>
      <p>Total 2 days or less (Oversize Only): {summaries.totalTwoDaysOrLessOversize.toLocaleString()}</p>
      <p>Total greater than 2 days (Oversize Only): {summaries.totalGreaterThanTwoDaysOversize.toLocaleString()}</p>
      <p>Total Revenue: ${summaries.totalRevenue.toLocaleString()}</p>
      <p>Total Units: {summaries.totalUnits.toLocaleString()}</p>
      <hr />
      <p className="fw-bold">45% + 2 days or less (Oversize Only)</p>
      <p>Percentage: {summaries.percentageTwoDaysOrLessOversize.toFixed(2)}%</p>
      <p>Revenue: ${summaries.revenueTwoDaysOrLess.toLocaleString()}</p>
      <p>Units: {summaries.unitsTwoDaysOrLess.toLocaleString()}</p>
      <hr />
      <p className="fw-bold">All (Oversize Only)</p>
      <p>Revenue: ${summaries.oversizeOnlyRevenue.toLocaleString()}</p>
      <p>Units: {summaries.oversizeOnlyUnits.toLocaleString()}</p>
    </div>
  )

}

export default AmazonViewSummary