import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { calculateProjectedGraphData, getProjectedDataForWeek, getDayViewTotals } from "../../common/common.helpers"
import { PrimeSpeedRow, GraphDataPoint, DayViewTotals } from "../../common/common.types"
import useAmazonPageSpeedViewsRollingData from "../hooks/useAmazonPageSpeedViewsRollingData";
import { useSpinner } from "../../spinner/SpinnerContext";

const ProjectedGraphDataContext = createContext(null);

export const ProjectedGraphDataProvider = ({ children }) => {

  const { toggleSpinner } = useSpinner()

  const {     
    isLoadingRolling,
    isPendingRolling,
    rollingError,
    rollingdata,
    isFetchingRolling,
    refetchRolling 
  } = useAmazonPageSpeedViewsRollingData()

  const [filteredRollingData, setFilteredRollingData] = useState<PrimeSpeedRow[]>([]);
  const [projectedGraphData, setProjectedGraphData] = useState<GraphDataPoint[]>([]);
  const [projectedDayByDayData, setProjectedDayByDayData] = useState<DayViewTotals[]>([]);
  const [projectedSummary, setProjectedSummary] = useState({
    oneDayPercent: 0,
    twoDayPercent: 0,
    greaterThanTwoDayPercent: 0,
    oneDayViews: 0,
    twoDayViews: 0,
    greaterThanTwoDayViews: 0,
    totalViews: 0,
  });

  const isDataLoading = isPendingRolling || isFetchingRolling || isLoadingRolling

  useEffect(() => {
    toggleSpinner(isDataLoading);
  }, [isDataLoading]);

  useEffect(() => {
    if (rollingdata) {
      const filteredData = getProjectedDataForWeek(rollingdata)
      setFilteredRollingData(filteredData);
    }
  }, [rollingdata]);

  // Memoize filtered data, graph data, and day-by-day data
  const memoizedFilteredRollingData = useMemo(() => {
    return filteredRollingData;
  }, [filteredRollingData]);

  const memoizedProjectedGraphData = useMemo(() => {
    return calculateProjectedGraphData(memoizedFilteredRollingData);
  }, [memoizedFilteredRollingData]);

  const memoizedProjectedDayByDayData = useMemo(() => {
    return getDayViewTotals(memoizedFilteredRollingData);
  }, [memoizedFilteredRollingData]);

  useEffect(() => {
    if (memoizedFilteredRollingData.length > 0) {
      setProjectedGraphData(memoizedProjectedGraphData);
      setProjectedDayByDayData(memoizedProjectedDayByDayData);
    }
  }, [memoizedFilteredRollingData, memoizedProjectedGraphData, memoizedProjectedDayByDayData]);

  return (
    <ProjectedGraphDataContext.Provider 
      value={{
        isLoadingRolling,
        isPendingRolling,
        rollingError,
        rollingdata,
        isFetchingRolling,
        projectedGraphData: memoizedProjectedGraphData,
        projectedSummary,
        filteredRollingData: memoizedFilteredRollingData,
        projectedDayByDayData: memoizedProjectedDayByDayData,
        setProjectedSummary,
        refetchRolling
      }}
    >
      {children}
    </ProjectedGraphDataContext.Provider>
  );
};

export const useProjectedGraphDataContext = () => {
  const context = useContext(ProjectedGraphDataContext);
  if (!context) {
    throw new Error("useProjectedGraphDataContext must be used within a ProjectedGraphDataProvider");
  }
  return context;
};