import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ShippingTracking from "../shippingTracking/ShippingTracking";
import IagTracking from "../iagTracking/IagTracking";
import OrderTracking from "../orderTracking/OrderTracking";

function NobodyShouldKnowThis() {
  const [key, setKey] = useState(() => {
    return localStorage.getItem("selectedTab") || "shippingTracking";
  });

  useEffect(() => {
    localStorage.setItem("selectedTab", key);
  }, [key]);

  return (
    <Container fluid className="p-3 fs-4">
      <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
        <Tab eventKey="shippingTracking" title="Shipping Tracking">
          {key === "shippingTracking" && <ShippingTracking />}
        </Tab>
        <Tab eventKey="iagTracking" title="IAG Tracking">
          {key === "iagTracking" && <IagTracking />}
        </Tab>
        <Tab eventKey="orderTracking" title="Order Tracking">
          {key === "orderTracking" && <OrderTracking />}
        </Tab>
      </Tabs>
    </Container>
  );
}

export default NobodyShouldKnowThis;
