import { useCallback, useEffect, useMemo, useState } from 'react'
import { CommonProperties } from '../common/common.interfaces'
import { Button, CloseButton, Col, Container, Dropdown, Form, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap'
import { DATA_SERVICES_TABLES_DEV, DATA_SERVICES_TABLES, DATA_SERVICES_BLOBS_DEV, DATA_SERVICES_BLOBS, CUSTOM_JSON_THEME } from '../common/common.constants'
import { AnonymousCredential, BlobServiceClient } from '@azure/storage-blob'
import { blobToString } from '../blobs/blobs.service'
import { WnaInventory } from './wna.interfaces'
import CustomMenuComponent from '../dropdowns/CustomMenuComponent'
import { DateTime } from 'luxon'
import * as XLSX from "xlsx"
import { Buffer } from "buffer"
import { useSpinner } from '../spinner/SpinnerContext'

interface WNAComponentProperties extends CommonProperties {}
interface SortOptions<T> {
  field: string
  order: string
}

const INVENTORY_BLOB = 'wna/wna-inventory.json'
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
const WNA_ENCRYPTED = 'ampoaUxYQ3BAJEpSelNCVUtBb2ZIM3UyKlZPY3YyM0s6UDckZkR1YzV4IU00T2NIMmY1TmRTQFZEa1JCZzFQIUs='

function extractDateValue(value: any): Date | any {
  if (typeof value === 'string' && isDateTimeString(value)) {
    const [year, month, day] = value.split('-');
    const formattedDate = `${year}-${month}-${day}`;
    return new Date(formattedDate);
  }
  
  return value;
}

function isDateTimeString(value: string): boolean {
  const dateRegex = /^\d{4}-\d{2}-\d{2}$/i;

  return dateRegex.test(value);
}

function onlyUnique(value, index, array) {
  return array.indexOf(value) === index;
}

function sortByField<T>(array: T[], options: SortOptions<T>): T[] {
  const { field, order } = options;

  const compare = (a: T, b: T): number => {
    const aValue = a[field]
    const bValue = b[field]

    if (aValue === 'None' && bValue !== 'None') {
      return 1
    }
    
    if (aValue !== 'None' && bValue === 'None') {
      return -1
    }

    if (aValue === 'None' && bValue === 'None') {
      return 0
    }

    const aDateValue = extractDateValue(aValue)
    const bDateValue = extractDateValue(bValue)

    if (aDateValue < bDateValue) {
      return order === 'asc' ? -1 : 1
    }
    
    if (aDateValue > bDateValue) {
      return order === 'asc' ? 1 : -1
    }
    
    return 0
  }

  return array.slice().sort(compare)
}


const WNAComponent = () => {
  const { showSpinner, hideSpinner } = useSpinner();
  const [loggedIn, setLoggedIn] = useState(false)
  const [clientId, setClientId] = useState(undefined)
  const [clientSecret, setClientSecret] = useState(undefined)
  const [connection, setConnection] = useState(undefined)
  const [inventory, setInventory]: [WnaInventory[], any] = useState(undefined)
  const [sortField, setSortField] = useState('MPN__c')
  const [sortOrder, setSortOrder] = useState('asc')
  const [filters, setFilters] = useState([])
  const [lastRefresh, setLastRefresh] = useState(undefined)
  const [download, setDownload] = useState(undefined)

  /** Connect if logged in */
  useEffect(() => {
    if(!loggedIn) return

    const loadInventory = async () => {
      showSpinner()
      const blobServiceClient = new BlobServiceClient(DATA_SERVICES_BLOBS, new AnonymousCredential())
      const containerService = blobServiceClient.getContainerClient('public')
      const blobClient = containerService.getBlobClient(INVENTORY_BLOB)
      const lastModified = (await blobClient.getProperties()).lastModified.toISOString()
      const data = await blobClient.download()
      const downloaded = await blobToString(await data.blobBody)
      const json = JSON.parse(downloaded)
      const ws = XLSX.utils.json_to_sheet(json)
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] }
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
      const blob = new Blob([excelBuffer], { type: fileType })
      setDownload(window.URL.createObjectURL(blob))
      setInventory(json)
      setLastRefresh(DateTime.fromISO(lastModified).toLocaleString({dateStyle: 'full', timeStyle: 'full'}))
      hideSpinner()
    }
    loadInventory()
  }, [loggedIn])

  const renderTooltip = (content) => (
    <Tooltip id='header-tooltip'>
      {content}
    </Tooltip>
  )

  const handleSortChanged = useCallback((e) => {
    setSortField(e.target.id)
    if(sortOrder === 'asc'){
      setSortOrder('desc')
    } else if(sortOrder === 'desc'){
      setSortOrder('asc')
    } else {
      setSortOrder('asc')
    }
  }, [sortOrder, sortField])

  const handleFilterChanged = useCallback((e) => {
    const currentFilters = [...filters]
    if(!currentFilters.includes(e.target.id)){
      currentFilters.push(e.target.id)
    }
    setFilters(currentFilters)
  }, [filters])

  const handleFilterRemoved = useCallback((e) => {
    const currentFilters = [...filters]
    const newFilters = []
    for(const filter of currentFilters){
      if(filter.includes(e.target.id)) continue
      newFilters.push(filter)
    }
    setFilters(newFilters)
  }, [filters])

  const filterItems = useMemo(() => {
    const columns = []

    for(const filter of filters){
      columns.push(
        <Col md="auto" style={{border: 'solid darkgray 1px', borderRadius: '4px', marginLeft: '5px', padding: '3px', fontSize: '10px', background: '#F0F0F0'}}>
          <div style={{float: 'right'}}><CloseButton id={filter} onClick={handleFilterRemoved}/></div>
          <strong>{filter.split('|')[1]}</strong>
        </Col>
      )
    }
    return (
      <Container>
        <Row style={{padding: '7px'}}>
          {columns}
        </Row>
      </Container>
    )
  }, [filters, handleFilterRemoved])

  const handleAccessIdChange = useCallback((e) => {
    setClientId(e.target.value)
  }, [])

  const handleAccessKeyChange = useCallback((e) => {
    setClientSecret(e.target.value)
  }, [])

  const handleLoginClicked = useCallback((e) => {
    const base64 = Buffer.from(`${clientId}:${clientSecret}`).toString('base64')
    
    setLoggedIn(base64 === WNA_ENCRYPTED)
  }, [clientId, clientSecret])

  const buildView = useMemo(() => {
    if(!loggedIn){
      return (
        <div style={{textAlign: 'center', paddingLeft: '15px', paddingRight: '15px', marginBottom: '20px'}}>
          <h1>Secure Access</h1>
          <div style={{fontSize: '1.5em'}}>Please enter the secret provided by Net Health Shops.</div>
          <hr/>
          <div style={{marginTop: '20px'}}>
            <Form>
              <div>
                <Form.Label>Client ID</Form.Label><br/>
                <Form.Control style={{maxWidth: '250px', display: 'unset'}} id='username' onChange={handleAccessIdChange} onBlur={handleAccessIdChange} type="password" placeholder="Enter Client ID..." />
              </div>
              <div style={{marginTop: '5px'}}>
                <Form.Label>Client Secret</Form.Label><br/>
                <Form.Control style={{maxWidth: '250px', display: 'unset'}} id='password' onChange={handleAccessKeyChange} onBlur={handleAccessKeyChange} type="password" placeholder="Enter Client Secret..." />
              </div>
            </Form>
            <div>
                <Button style={{marginTop: '5px', width: '75px'}} onClick={handleLoginClicked}>Login</Button>
            </div>
          </div>
        </div>
      )
    } else {
      if(!inventory) return (<></>)

      const filteredRows = inventory.filter((item) => {
        let allMatch = true
        const hasDate = item.Restock_Date_Used__c !== 'None'
        const dupedItem = {...item}
        
        for(const filter of filters){
          const splitFilter = filter.split('|')

          const key = splitFilter[0]
          let value = splitFilter[1]
          if(key === 'Restock_Date_Used__c'){
            if(hasDate){
              dupedItem[key] = 'Has_Date'
            }
          }

          if(`${dupedItem[key]}` !== `${value}`){
            allMatch = false
          } 
        }
        return allMatch
      })

      let sortedRows = filteredRows
      if(typeof sortField !== 'undefined' && typeof sortOrder !== 'undefined'){
        sortedRows = sortByField(filteredRows, {field: sortField, order: sortOrder})
      }

      const sortText = typeof sortField === 'undefined' ? '' : typeof sortOrder === 'undefined' ? '' : sortOrder === 'asc' ? 'ASC' : 'DESC'

      const uniqueSkus = inventory.map(r => r.MPN__c).filter(onlyUnique)
      const uniqueTitles = inventory.map(r => r.Title__c).filter(onlyUnique)
      const uniqueUpcs = inventory.map(r => r.UPC__c).filter(onlyUnique)
      const uniqueDiscontinued = inventory.map(r => `${r.Discontinued__c}`).filter(onlyUnique)
      const uniqueRestockDates = ['None', 'Has Date']
      const uniqueReportCategories = inventory.map(r => r.Report_Category__c).filter(onlyUnique)
      const uniqueShippingCost = inventory.map(r => r.Calc_Shipping_Cost__c).filter(onlyUnique)

      const th: any[] = []
      const th2: any[] = []
      th.push(<th>Image</th>)
      th2.push(<th></th>)

      th.push(<th key={`header_mpn`}><div onClick={handleSortChanged} id='MPN__c' style={{cursor: 'pointer'}}>SKU {sortField === 'MPN__c' ? `(${sortText})` : ''}</div></th>)
      th2.push(<th><CustomMenuComponent key={`mpnFilter`} items={uniqueSkus.map(r => { return <Dropdown.Item key={`${r}`} id={`MPN__c|${r}`} onClick={handleFilterChanged}>{`${r}`}</Dropdown.Item>})} title='SKU'></CustomMenuComponent></th>)

      th.push(<th key={`header_upc`}><div onClick={handleSortChanged} id='UPC__c' style={{cursor: 'pointer'}}>UPC {sortField === 'UPC__c' ? `(${sortText})` : ''}</div></th>)
      th2.push(<th><CustomMenuComponent key={`upcFilter`} items={uniqueUpcs.map(r => { return <Dropdown.Item key={`${r}`} id={`UPC__c|${r}`} onClick={handleFilterChanged}>{`${r}`}</Dropdown.Item>})} title='UPC'></CustomMenuComponent></th>)
      
      th.push(<th key={`header_category`}><div onClick={handleSortChanged} id='Report_Category__c' style={{cursor: 'pointer'}}>Category {sortField === 'Report_Category__c' ? `(${sortText})` : ''}</div></th>)
      th2.push(<th><CustomMenuComponent key={`categoryFilter`} items={uniqueReportCategories.map(r => { return <Dropdown.Item key={`${r}`} id={`Report_Category__c|${r}`} onClick={handleFilterChanged}>{`${r}`}</Dropdown.Item>})} title='Category'></CustomMenuComponent></th>)

      th.push(<th key={`header_title`}><div onClick={handleSortChanged} id='Title__c' style={{cursor: 'pointer'}}>Title {sortField === 'Title__c' ? `(${sortText})` : ''}</div></th>)
      th2.push(<th><CustomMenuComponent key={`titleFilter`} items={uniqueTitles.map(r => { return <Dropdown.Item key={`${r}`} id={`Title__c|${r}`} onClick={handleFilterChanged}>{`${r}`}</Dropdown.Item>})} title='Title'></CustomMenuComponent></th>)

      th.push(<th key={`header_discontinued`}><div onClick={handleSortChanged} id='Discontinued__c' style={{cursor: 'pointer'}}>Discontinued {sortField === 'Discontinued__c' ? `(${sortText})` : ''}</div></th>)
      th2.push(<th><CustomMenuComponent key={`discontinuedFilter`} items={uniqueDiscontinued.map(r => { return <Dropdown.Item key={`${r}`} id={`Discontinued__c|${r}`} onClick={handleFilterChanged}>{`${r}`}</Dropdown.Item>})} title='Discontinued'></CustomMenuComponent></th>)

      th.push(<th key={`header_restock`}><div onClick={handleSortChanged} id='Restock_Date_Used__c' style={{cursor: 'pointer'}}>Restock Date {sortField === 'Restock_Date_Used__c' ? `(${sortText})` : ''}</div></th>)
      th2.push(<th><CustomMenuComponent key={`restockFilter`} items={uniqueRestockDates.map(r => { return <Dropdown.Item key={`${r}`} id={`Restock_Date_Used__c|${r.replace(' ', '_')}`} onClick={handleFilterChanged}>{`${r}`}</Dropdown.Item>})} title='Restock Date'></CustomMenuComponent></th>)

      th.push(<th key={`header_map`}><div onClick={handleSortChanged} id='Marketplace_Price__c' style={{cursor: 'pointer'}}>MAP {sortField === 'Marketplace_Price__c' ? `(${sortText})` : ''}</div></th>)
      th2.push(<th></th>)

      th.push(<th key={`header_wscost`}><div onClick={handleSortChanged} id='Wholesale_Price__c' style={{cursor: 'pointer'}}>Wholesale {sortField === 'Wholesale_Price__c' ? `(${sortText})` : ''}</div></th>)
      th2.push(<th></th>)

      th.push(<th key={`header_shipping`}><div onClick={handleSortChanged} id='Calc_Shipping_Cost__c' style={{cursor: 'pointer'}}>Shipping {sortField === 'Calc_Shipping_Cost__c' ? `(${sortText})` : ''}</div></th>)
      th2.push(<th><CustomMenuComponent key={`shippingFilter`} items={uniqueShippingCost.map(r => { return <Dropdown.Item key={`${r}`} id={`Calc_Shipping_Cost__c|${r}`} onClick={handleFilterChanged}>{`${r}`}</Dropdown.Item>})} title='Shipping'></CustomMenuComponent></th>)

      const tr: any[] = []
      for(const item of sortedRows){
        let trueDiscontinued = false
        if(item.Fulfillable_Inventory__c < 10 && item.Discontinued__c){
          trueDiscontinued = true
        }

        let restockColor = 'darkred'
        const oneEightyDaysFromNow = DateTime.now().plus({days: 180}).toFormat('yyyy-MM-dd')
        if(item.Fulfillable_Inventory__c <= 0 && !item.Discontinued__c && item.Restock_Date_Used__c === oneEightyDaysFromNow){
          restockColor = 'darkorange'
        }

        let daysOut = undefined
        if(item.Restock_Date_Used__c !== 'None'){
          daysOut = DateTime.fromFormat(item.Restock_Date_Used__c, 'yyyy-MM-dd').diffNow('days').days.toFixed(0)
        }

        const td: any[] = []
        td.push(<td style={{backgroundColor:'white', maxWidth: '100px'}}><a href={item.Image_URL_1__c}><img style={{width: '100px'}} src={item.Image_URL_1__c}></img></a></td>)
        td.push(<td style={{verticalAlign: 'middle'}}>{item.MPN__c}</td>)
        td.push(<td style={{verticalAlign: 'middle'}}>{item.UPC__c}</td>)
        td.push(<td style={{verticalAlign: 'middle'}}>{item.Report_Category__c}</td>)
        td.push(<td style={{maxWidth: '200px', verticalAlign: 'middle'}}>{item.Title__c}</td>)
        td.push(<td style={{verticalAlign: 'middle'}}>{`${trueDiscontinued}`}</td>)
        td.push(<td style={{verticalAlign: 'middle', fontWeight: item.Restock_Date_Used__c === 'None' ? 'inherit' : 'bold', color: item.Restock_Date_Used__c === 'None' ? 'inherit' : restockColor}}>{item.Restock_Date_Used__c}<div style={{fontSize: '.75em', fontWeight: 'normal'}}>{daysOut ? `${daysOut} day(s) out...`:''}</div></td>)
        td.push(<td style={{verticalAlign: 'middle'}}>${item.Marketplace_Price__c}</td>)
        td.push(<td style={{verticalAlign: 'middle'}}>${item.Wholesale_Price__c}</td>)
        td.push(<td style={{verticalAlign: 'middle'}}>{item.Calc_Shipping_Cost__c === 'FREE' ? 'Free' : `$${item.Calc_Shipping_Cost__c}`}</td>)
        tr.push(<tr>{td}</tr>)
      }

      return (
        <div style={{textAlign: 'center', marginLeft: '25px', marginRight: '25px'}}>
          <h1>Wholesale North America - Products</h1>
          <Button style={{width: '85px'}} variant='success'>
            <a download={`NHS-WNA-Products-${DateTime.utc().toMillis()}.xlsx`} href={download} style={{color: '#ffffff'}}>Download XLSX</a>
          </Button>
          <hr/>
          {filters.length > 0 ? (
            <>
            {filterItems}
            <hr/>
            </>
          ): ''}
          <div>{lastRefresh ? `Last refreshed: ${lastRefresh}` : ''}</div>
          <Table bordered hover>
            <thead>
              <tr style={{fontSize: '1.5em', backgroundColor: 'white', position: 'sticky', top: 50, zIndex: 1000 }}>
                {th}
              </tr>
              <tr>
                {th2}
              </tr>
            </thead>
            <tbody style={{fontSize: '1.5em'}}>
              {tr}
            </tbody>
          </Table>
        </div>
      )
    }
  }, [loggedIn, inventory, filters, handleFilterChanged, sortField, sortOrder, lastRefresh, handleLoginClicked])

  return buildView
}

export default WNAComponent