import { Button, Form, Row, Col, Container } from "react-bootstrap"

const UploadAmazonDeliveryMetricsComponent = ({ handleFileChange, uploadFile }) => { 
  return(
    <Container>
      <Row className="justify-content-md-center">
        <Col md={10}>
          <Form>
            <Row className="mb-3">
              <Col>
                <Form.Group controlId="formFile">
                  <Form.Label>Upload More Data</Form.Label>
                  <Form.Control type="file" onChange={handleFileChange}/>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <Button className="w-100" variant="outline-primary" onClick={uploadFile}>Upload CSV File</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default UploadAmazonDeliveryMetricsComponent;