import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import AmazonSFPConfigComponent from './AmazonSFPConfigComponent'
import { ButtonGroup, ToggleButton } from 'react-bootstrap'
import { useMemo, useState } from 'react'
import { InteractiveBrowserCredential } from '@azure/identity'
import { TENANT_ID, CLIENT_ID } from '../authConfig'
import { BlobServiceClient } from '@azure/storage-blob'
import { DATA_SERVICES_BLOBS_DEV, DATA_SERVICES_BLOBS } from '../common/common.constants'

const RADIO = [
  { name: 'Development', value: 'development', enabled: true },
  { name: 'Production', value: 'production', enabled: true }
]

const ConfigComponent = () => {
  const [environment, setEnvironment]: [string, any] = useState('development')

  const credential = new InteractiveBrowserCredential({
    tenantId: TENANT_ID,
    clientId: CLIENT_ID,
    authorityHost: 'https://login.microsoft.com'
  })

  const view = useMemo(() => {
    const blobServiceClient = new BlobServiceClient(environment === 'development' ? DATA_SERVICES_BLOBS_DEV : DATA_SERVICES_BLOBS, credential)
    const containerClient = blobServiceClient.getContainerClient('storage')

    return (
      <>
        <h2>Configurations</h2>
        <div>
        <ButtonGroup>
          {RADIO.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant={idx % 2 ? 'outline-success' : 'outline-danger'}
              name="radio"
              value={radio.value}
              checked={environment === radio.value}
              onChange={(e) => setEnvironment(e.currentTarget.value)}
              disabled={radio.enabled !== true}
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
        </div>
        <Tabs>
          <TabList>
            <Tab key='amazonSfp' title={'Amazon SFP Configurations'}>Amazon SFP Configurations</Tab>
          </TabList>
          <TabPanel key='amazonSfp'>
            <AmazonSFPConfigComponent containerClient={containerClient}></AmazonSFPConfigComponent>
          </TabPanel>
        </Tabs>
      </>
    )
  }, [environment])

  return view
}

export default ConfigComponent