import { EventType, InteractionStatus } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect, useRef } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSpinner } from "../spinner/SpinnerContext";

const Login = () => {
  const { toggleSpinner } = useSpinner();
  const navigate = useNavigate();
  const { instance: msalInstance, inProgress } = useMsal();
  const loginAttemptedRef = useRef(false);
  const isAuthenticated = useIsAuthenticated();
  console.log("🚀 ~ Login ~ inProgress:", inProgress);
  console.log("🚀 ~ Login ~ isAuthenticated:", isAuthenticated);

  const verify = async () => {
    if (!msalInstance.getActiveAccount() && !loginAttemptedRef.current) {
      loginAttemptedRef.current = true;
      try {
        // await msalInstance.loginPopup();
        await msalInstance.loginRedirect();
      } catch (error) {
        console.error("Login failed:", error);
      }
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();

    // Retry parameters
    const maxRetries = 3;
    let retries = 0;
    const retryDelay = 2000; // 2 seconds delay for retry

    const addLoginCallback = () => {
      // Add event callback for login success
      msalInstance.addEventCallback((event) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
          const account = event.payload.account;
          msalInstance.setActiveAccount(account);
          console.log("Login successful, active account set:", account);
        }
      });

      retries += 1;
      console.log(`Callback attempt ${retries}`);

      // Retry if the callback doesn't work within a certain time frame
      if (retries < maxRetries) {
        setTimeout(() => {
          const activeAccount = msalInstance.getActiveAccount();
          if (!activeAccount) {
            console.warn("No active account found, retrying...");
            addLoginCallback(); // Retry callback registration
          } else {
            console.log("Active account found, no further retry needed.");
          }
        }, retryDelay);
      } else {
        console.error("Max retries reached. Login callback did not trigger.");
      }
    };

    addLoginCallback(); // First call to addEventCallback
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    toggleSpinner(inProgress !== "none");
  }, [inProgress]);

  return (
    <Container fluid={true} className="vh-100 d-flex">
      <Row className="flex-column flex-grow-1 my-auto">
        <Col className="d-flex mx-auto justify-content-center">
          <Button size="lg" onClick={verify}>
            Login
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
