import { TableClient } from '@azure/data-tables'
import { InteractiveBrowserCredential } from '@azure/identity'
import { TENANT_ID, CLIENT_ID } from '../authConfig'
import { CUSTOM_JSON_THEME, DATA_SERVICES_TABLES } from '../common/common.constants'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Col, Form, Row, Table } from 'react-bootstrap'
import JSONPretty from 'react-json-pretty'
import BlobModalComponent from '../blobs/BlobModalComponent'
import { DateTime } from 'luxon'
import { useSpinner } from '../spinner/SpinnerContext'


const InventoryComponent = () => {
  const { showSpinner, hideSpinner } = useSpinner();
  const credential = new InteractiveBrowserCredential({
    tenantId: TENANT_ID,
    clientId: CLIENT_ID,
    authorityHost: 'https://login.microsoft.com'
  })
  const statusTableClient = new TableClient(DATA_SERVICES_TABLES, 'inventoryStatus', credential)
  const configTableClient = new TableClient(DATA_SERVICES_TABLES, 'integrationConfiguration', credential)
  
  const [configs, setConfigs] = useState(undefined)
  const [results, setResults] = useState(undefined)
  const [searchSku, setSearchSku] = useState(undefined)
  const [jsonContent, setJsonContent] = useState(undefined)
  const [modalVisible, setModalVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState(undefined)

  useEffect(() => {
    const loadConfigs = async () => {
      const configTableRows = configTableClient.listEntities()
      const temp = []
      for await(const config of configTableRows){
        temp.push(config)
      }
      setConfigs(temp)
    }
    loadConfigs()
  }, [])

  const jsonButtonClicked = useCallback((e) => {
    if(typeof results === 'undefined') return

    const idSplit = e.target.id.split('___')
    const pKey = idSplit[0]
    const rKey = idSplit[1]
    const integration = idSplit[2]
    const result = results.find(r => r.partitionKey === pKey && r.rowKey === rKey)

    let content = result.payload
    const data = JSON.parse(result.payload)
    if(typeof data.authorization !== 'undefined'){
      delete data.authorization
      content = JSON.stringify(data)
    }

    setJsonContent(<JSONPretty data={content} theme={CUSTOM_JSON_THEME} style={{fontSize: '1.5em'}} ></JSONPretty>)
    setModalTitle(integration)
    setModalVisible(true)
  }, [results])

  const closeModal = useCallback(() => {
    setModalVisible(false)
  }, [])

  const blobView = useMemo(() => {
    return BlobModalComponent({blobData: jsonContent, blobName: `${searchSku} - ${modalTitle}`, closeModal, modalVisible})
  }, [jsonContent, modalVisible, closeModal, searchSku, modalTitle])

  const searchBySkuClicked = useCallback((e) => {
    const search = async () => {
      if(typeof searchSku === 'undefined') return
      showSpinner()
      try {
        const temp = []
        const rows = statusTableClient.listEntities({queryOptions: {filter: `sku eq '${searchSku}'`}})
        for await(const row of rows){
          temp.push(row)
        }
        setResults(temp)
      }catch(error){
        console.log(error)
      }
      hideSpinner()
    }
    search()
  }, [searchSku])

  const searchSkuChanged = useCallback((e) => {
    setSearchSku(e.target.value)
  }, [])

  const searchView = useMemo(() => {
    if(typeof configs === 'undefined') return

    return (
      <>
        <Row>
          <Col>
            <div>
              <Form.Control onChange={searchSkuChanged} type='text' placeholder='Enter SKU (Case Sensitive)...'></Form.Control>
              <br/>
              <Button onClick={searchBySkuClicked}>Search</Button>
            </div>
          </Col>
        </Row>
      </>
    )
  }, [configs, searchBySkuClicked])

  const findRelatedConfig = useCallback((record) => {
    for(const config of configs){
      if(config.inventoryPartitionKey === record.partitionKey){
        return config
      } else if(config.pricingPartitionKey === record.partitionKey){
        return config
      } else if(config.pricePartitionKey === record.partitionKey){
        return config
      } else if(config.productsPartitionKey === record.partitionKey){
        return config
      } else if(config.logisticsPartitionKey === record.partitionKey){
        return config
      } else if(config.metafieldsPartitionKey === record.partitionKey){
        return config
      } else if(config.variantsPartitionKey === record.partitionKey){
        return config
      }
    }
  }, [configs])

  const resultsView = useMemo(() => {
    if(typeof results === 'undefined') return

    const rows = []
    for(const result of results){
      const integration = result.partitionKey.replaceAll('|', ' ').toUpperCase()
      const rowKey = decodeURI(result.rowKey)
      const rowKeySplit = rowKey.split('|')
      const lastIndexRowKey = rowKeySplit.pop()
      let numberOfTextAreaRows = result.payload.length > 800 ? 5 : result.payload.length > 500 ? 4 : result.payload.lenght > 250 ? 3 : 2
      if(result.payload.includes('\n')){
        numberOfTextAreaRows = result.payload.split('\n').length
      }
      let isJson = false
      let content = result.payload
      try {
        const data = JSON.parse(result.payload)
        if(typeof data.authorization !== 'undefined'){
          delete data.authorization
          content = JSON.stringify(data)
        }
        isJson = true
      }catch(e){}

      let jsonButton = undefined
      if(isJson){
        jsonButton = (
          <>
          <Button onClick={jsonButtonClicked} variant='success' id={`${result.partitionKey}___${result.rowKey}___${integration}`}>View Pretty JSON</Button>
          </>
        ) 
      }

      let warehouse = undefined
      if(lastIndexRowKey !== searchSku){
        warehouse = (<p>Warehouse: <strong>{lastIndexRowKey}</strong></p>)
      }

      const relatedConfig = findRelatedConfig(result)

      rows.push(<tr>
        <td style={{width: '200px'}}>
          <strong>{integration}</strong><br/>
          {lastIndexRowKey === searchSku ? undefined : warehouse}
          <div>Last Map: <strong>{DateTime.fromISO(result.lastMap).setZone('America/Chicago').toFormat('MM/dd/yyyy t')}</strong></div>
          <div>Mapping Enabled: <strong>{relatedConfig?.inventoryEnabled === true ? 'YES' : 'NO'}</strong></div>
        </td>
        <td><Form.Control as="textarea" value={content} rows={numberOfTextAreaRows}></Form.Control>{jsonButton}</td>
      </tr>)
    }

    return (
      <>
        {results.length} records found.
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Integration</th>
              <th>Payload</th>
            </tr>
          </thead>
          <tbody>
            {rows}
          </tbody>
        </Table>
      </>
    )
  }, [results, findRelatedConfig])

  return (
    <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
      {blobView}
      <h1>Inventory Mapping</h1>
      <hr/>
      {searchView}
      <em>Please note that the date/times associated with each integration are based on last mapping. For some integrations, we only send data if it has changed since the last mapping.</em>
      <hr/>
      {resultsView}
    </div>
  )
}

export default InventoryComponent
