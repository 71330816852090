import { apiRequest } from "../../utility/axios"

const useAmazonFedExDeliveryMetricsPost = (file, toggleSpinner) => {
  const uploadFile = async () => {
    if (!file) {
      alert('Please select a file to upload.')
      return
    }

    const reader = new FileReader()

    reader.onload = async (event) => {

      // If the validation passes, proceed to upload
      const formData = new FormData()
      formData.append('csvContent', file)

      try {
        toggleSpinner(true)
        await apiRequest("POST", `/api/amazonFedExDeliveryMetrics`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      } catch (error) {
        console.error('Error uploading file:', error)
      } finally {
        toggleSpinner(false)
      }
    }

    reader.onerror = (error) => {
      console.error('Error reading file:', error)
      alert('Error reading file. Please try again.')
    }

    reader.readAsText(file)
  }

  return{
    uploadFile
  }
}

export default useAmazonFedExDeliveryMetricsPost