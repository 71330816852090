import { BlobServiceClient } from '@azure/storage-blob'
import { useCallback, useMemo, useState } from 'react'
import { Modal, Button, Form, Collapse, Accordion } from 'react-bootstrap'
import JSONPretty from 'react-json-pretty'
import { CUSTOM_JSON_THEME } from '../common/common.constants'
import AccordionItem from 'react-bootstrap/esm/AccordionItem'
import AccordionBody from 'react-bootstrap/esm/AccordionBody'
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader'
import { CommonProperties } from '../common/common.interfaces'
import { notify } from '../notify/notify'

interface OrderEditModalComponentProperties extends CommonProperties {
  modalVisible: boolean
  closeModal: any
  blobName: string
  blobData: any
  devBlobServiceClient: BlobServiceClient
}

const MAPPING_PATH = 'order-service/mapping'

const OrderEditModalComponent = (props: OrderEditModalComponentProperties) => {
  if(!props.blobData) return
  const [newData, setNewData] = useState(undefined)
  
  const handleCopyClick = useCallback((e) => {
    const copy = async () => {
      const copyData = newData ? newData : props.blobData
      const copyPath = `${MAPPING_PATH}${props.blobName.split('archive')[1]}`
      try {
        const containerClient = props.devBlobServiceClient.getContainerClient('storage')
        const blockBlobClient = containerClient.getBlockBlobClient(copyPath)
        await blockBlobClient.upload(copyData, copyData.length)
        notify('success', `Copied to Sandbox! ${copyPath}`)
      }catch(error){
        notify('error', `Failed to copy to Sandbox! ${copyPath}`)
        console.log(error)
      }
      props.closeModal()
    }
    copy()
  }, [newData])

  const handleDataChanged = useCallback((e) => {
    setNewData(e.target.value)
  }, [])

  const view = useMemo(() => {
    return (
      <Modal show={props.modalVisible} animation={false} onHide={props.closeModal} className='modal-xl'>
        <Modal.Header closeButton>
          <Modal.Title>
            Copy Production: {props.blobName} <br/> to Sandbox: {`${MAPPING_PATH}${props.blobName.split('archive')[1]}`}
          </Modal.Title>
        </Modal.Header>
        <br/>

        <Modal.Body>
          <Accordion defaultActiveKey={'data'}>
            <AccordionItem eventKey='data'>
              <AccordionHeader>Data</AccordionHeader>
              <AccordionBody>
                <Form.Control onChange={handleDataChanged} spellCheck={false} style={{backgroundColor: '#1e3250', color: 'white', fontSize: '14px'}} as="textarea" rows={20} defaultValue={JSON.stringify(newData ? newData : JSON.parse(props.blobData), null, 2)} />
              </AccordionBody>
            </AccordionItem>
            <AccordionItem eventKey='preview'>
              <AccordionHeader>Preview</AccordionHeader>
              <AccordionBody>
                <JSONPretty data={newData ? newData : props.blobData} theme={CUSTOM_JSON_THEME} style={{fontSize: '1.5em'}} ></JSONPretty>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='success' onClick={handleCopyClick}>Copy to Sandbox</Button>
          <Button variant='danger' onClick={props.closeModal}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    )
  }, [newData])

  return view
}

export default OrderEditModalComponent
