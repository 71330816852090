import { createContext, useContext, useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useSpinner } from "../../spinner/SpinnerContext";
import useAmazonDeliveryMetricsXlsxExport from "../hooks/useAmazonDeliveryMetricsXlsxExport";
import useAmazonFedExDeliveryMetricsGet from "../hooks/useAmazonFedExDeliveryMetricsGet";
import useAmazonFedExDeliveryMetricsPost from "../hooks/useAmazonFedExDeliveryMetricsPost";
import { fedexDelayReason } from "../utilities/fedexDelayReasons";
import { DeliveryMetrics } from "../../common/common.types";

// Create the context
const AmazonDeliveryMetricsContext = createContext(null);

// Context Provider
export const AmazonDeliveryMetricsProvider = ({ children }) => {

  const getPreviousWeekRange = () => {
    const now = DateTime.local().toUTC();
    const previousSunday = now.minus({ days: now.weekday % 7 + 7 }).toFormat('yyyy-MM-dd');
    const previousSaturday = now.minus({ days: now.weekday % 7 + 1 }).toFormat('yyyy-MM-dd');
    return { previousSunday, previousSaturday };
  };

  const { toggleSpinner } = useSpinner();
  const [selectedStartDate, setSelectedStartDate] = useState(getPreviousWeekRange().previousSunday);
  const [selectedEndDate, setSelectedEndDate] = useState(getPreviousWeekRange().previousSaturday);
  const [selectedReason, setSelectedReason] = useState("")
  const [file, setFile] = useState<File | null>(null)
  const [startDate, setStartDate] = useState<string | null>(selectedStartDate)
  const [endDate, setEndDate] = useState<string | null>(selectedEndDate)
  const [filteredData, setFilteredData] = useState<DeliveryMetrics[] | null>();

  const {
    isLoadingCurrent,
    isPendingCurrent,
    currentError,
    currentData,
    isFetchingCurrent,
    refetchCurrent,
  } = useAmazonFedExDeliveryMetricsGet(startDate, endDate)

  const {
    uploadFile
  } = useAmazonFedExDeliveryMetricsPost(file, toggleSpinner)

  const {
    exportTableXlsx,
    exportDetailedTableXlsx 
  } = useAmazonDeliveryMetricsXlsxExport(currentData)

  const handleFilter = (selectedReasonId) => {
    // No data available to filter; clear the table
    if (!currentData || currentData.length === 0) {
      setFilteredData([]); // Set to empty array if there's no data
      return;
    }
  
    // No reason selected, reset to full dataset
    if (!selectedReasonId) {
      setFilteredData(currentData);
      return;
    }
  
    // Filter by the selected reason
    const reason = fedexDelayReason.find((r) => r.id.toString() === selectedReasonId)?.reason;
  
    if (reason) {
      const filtered = currentData.filter((item) =>
        item.scanEvents.some((event) => event.exceptionDescription === reason)
      );
      setFilteredData(filtered);
    } else {
      // No matching reason found; reset to full dataset
      setFilteredData(currentData);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0])
    }
  }

  const handleAmazonDeliveryMetricsDetailedExport = (e) => {
    e.preventDefault();
    exportDetailedTableXlsx()
  };

  const handleAmazonDeliveryMetricsTableExport = (e) => {
    e.preventDefault();
    exportTableXlsx()
  };

  const isDataLoading = isPendingCurrent || isFetchingCurrent || isLoadingCurrent

  useEffect(() => {
    toggleSpinner(isDataLoading);
  }, [isDataLoading]);

  useEffect(() => {
    refetchCurrent();
  }, [startDate, endDate]);

  // Provide all the state and handlers as context value
  return (
    <AmazonDeliveryMetricsContext.Provider
      value={{
        selectedStartDate,
        setSelectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        setStartDate,
        setEndDate,
        isLoadingCurrent,
        currentError,
        currentData,
        handleFileChange,
        uploadFile,
        handleAmazonDeliveryMetricsDetailedExport,
        handleAmazonDeliveryMetricsTableExport,
        selectedReason,
        setSelectedReason,
        filteredData,
        handleFilter,
        refetchCurrent
      }}
    >
      {children}
    </AmazonDeliveryMetricsContext.Provider>
  );
};

// Custom hook to use the context
export const useAmazonDeliveryMetricsContext = () => {
  const context = useContext(AmazonDeliveryMetricsContext);
  if (!context) {
    throw new Error('useAmazonDeliveryMetricsContext must be used within a AmazonDeliveryMetricsProvider');
  }
  return context;
};