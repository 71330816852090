import { useMemo } from 'react'
import { Col, Card, Button } from 'react-bootstrap'
import { CommonProperties } from '../common/common.interfaces'
import { SalsifyTableRow } from './salsify.interfaces'

interface SalsifyErrorsComponentProperties extends CommonProperties {
  toSalsify: SalsifyTableRow[]
  fromSalsify: SalsifyTableRow[]
}

const SalsifyErrorsComponent = (props: SalsifyErrorsComponentProperties) => {
  const buildErrors = useMemo(() => {
    const toSalsifyErrors = []
    const fromSalsifyErrors = []

    for(const record of props.toSalsify){
      toSalsifyErrors.push(
        <Col key={`tosalsify_error_col_${record.mpn}`}>
          <Card key={`tosalsify_error_card_${record.mpn}`}>
            <Card.Header key={`tosalsify_error_header_${record.mpn}`}>
              <Card.Title key={`tosalsify_error_header_title_${record.mpn}`}>{record.mpn}</Card.Title>
            </Card.Header>
            <Card.Body key={`tosalsify_error_body_${record.mpn}`}>
              {record.errors}
            </Card.Body>
          </Card>
        </Col>
      )
    }
    for(const record of props.fromSalsify){
      const errors = JSON.parse(record.errors)
      
      fromSalsifyErrors.push(
        <div style={{paddingTop: '5px'}}>
          <Card key={`tosalesforce_error_card_${record.mpn}`}>
            <Card.Header key={`tosalesforce_error_header_${record.mpn}`}>
              <Card.Title key={`tosalesforce_error_header_title_${record.mpn}`}>{record.mpn}</Card.Title>
            </Card.Header>
            <Card.Body key={`tosalesforce_error_body_${record.mpn}`}>
              <strong>{errors.message}</strong>
            </Card.Body>
          </Card>
        </div>
      )
    }

    return (
      <>
          {toSalsifyErrors && toSalsifyErrors.length > 0 ? 
            <>
              <h1>Salsify Errors [{toSalsifyErrors.length}]</h1>
              {toSalsifyErrors}
            </> : ''
          }
          {fromSalsifyErrors && fromSalsifyErrors.length > 0 ?
            <>
              <h1>Salesforce Errors [{fromSalsifyErrors.length}]</h1>
              {fromSalsifyErrors}
            </> : ''
          }
    
      </>
    )
  }, [props.toSalsify, props.fromSalsify])

  return buildErrors
}

export default SalsifyErrorsComponent
