import { ProcessingPercentageMap } from "../common/common.interfaces";

export type OrderPipelineStatusRow = {
  orderPipelineId: string;
  timestamp: string;
  statusQueryGetUri: string;
  terminatePostUri: string;
  purgeHistoryDeleteUri: string;
};

export type PipelineStatusRes = {
  status: string;
  processingStatus: string;
  finalOutput: string;
};

export type JobStatus = {
  processingStatus: string;
  processingPrecentage: number;
  finalOutput: any;
  finalStatus: string;
  isRunning: boolean;
};

export const orderProcessingPercentageMap: ProcessingPercentageMap = {
  "Get Chad Orders": 10,
  "Map Orders": 20,
  "Routing": 30,
  "Order Service save orders to MC": 40,
  "FS shippingStatusUpdate": 50,
  "FS pendingQuery": 60,
  "FMS shipSageMapperOutgoing": 70,
  "ShipSage orderCreate": 100,
};
