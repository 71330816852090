import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSpinner } from '../spinner/SpinnerContext'
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, ButtonGroup, Col, Container, Row, Table, ToggleButton } from 'react-bootstrap';
import { FileUploader } from 'react-drag-drop-files';
import { read, utils, writeFile } from 'xlsx';
import { DATA_SERVICES_BLOBS, DATA_SERVICES_BLOBS_DEV, DATA_SERVICES_TABLES, DATA_SERVICES_TABLES_DEV } from '../common/common.constants';
import { InteractiveBrowserCredential } from '@azure/identity';
import { BlobServiceClient } from '@azure/storage-blob';
import { TENANT_ID, CLIENT_ID } from '../authConfig';
import { blobToString } from '../blobs/blobs.service';
import { BazaarvoiceImportOrder, BazaarvoiceOrder, BazaarvoiceOrderItem, BazaarvoiceOrdersTableRow, MissionControlOrderData } from './BazaarvoiceOrders.interfaces';
import { DateTime } from 'luxon';
import { Inventory__c, Product2 } from '../common/common.interfaces';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { TableClient } from '@azure/data-tables';

const RADIO = [
  { name: 'Development', value: 'development', enabled: true },
  { name: 'Production', value: 'production', enabled: true }
]

const DS_ORDERS_INBOUND = 'order-service/inbound/bazaarvoice/promo'
const DS_BAZAARVOICE_ORDERS_TABLE = 'bazaarvoiceOrders'

const BazaarvoiceOrdersComponent = () => {
  const { showSpinner, hideSpinner } = useSpinner()

  const [importContent, setImportContent]: [BazaarvoiceImportOrder[], any] = useState(undefined)
  const [importFile, setImportFile]: [File, any] = useState(undefined)
  const [mappedContent, setMappedContent]: [MissionControlOrderData[], any] = useState(undefined)
  const [products, setProducts]: [Product2[], any] = useState(undefined)
  const [inventory, setInventory]: [Inventory__c[], any] = useState(undefined)
  const [bvOrdersRows, setBvOrdersRows]: [BazaarvoiceOrdersTableRow[], any] = useState(undefined)
  const [reloadBvOrders, setReloadBvOrders]: [string, any] = useState('initial')
  const [reloadProducts, setReloadProducts]: [string, any] = useState(undefined)
  const [loadStep, setLoadStep]: [string, any] = useState('')

  const [environment, setEnvironment]: [string, any] = useState('development')

  const credential = new InteractiveBrowserCredential({
    tenantId: TENANT_ID,
    clientId: CLIENT_ID,
    authorityHost: 'https://login.microsoft.com'
  })

  /** Load Product Data */
  useEffect(() => {
    const load = async () => {
      if(!reloadProducts) return

      await loadProducts()
      await loadEauClaireInventory()
      setLoadStep('')
    }
    const loadProducts = async () => {
      showSpinner()
      try {
        setLoadStep('Loading products...')
        const dsBlobServiceClient = new BlobServiceClient(environment === 'development' ? DATA_SERVICES_BLOBS_DEV : DATA_SERVICES_BLOBS, credential)
        const dsStorageContainerService = dsBlobServiceClient.getContainerClient('storage')
      
        const blobClient = dsStorageContainerService.getBlobClient(`product-service/products/all-products.json`)
        const data = await blobClient.download()
        const downloaded = await blobToString(await data.blobBody)
        const json = JSON.parse(downloaded)
        setProducts(json)
      }catch(error){
        console.error(error)
      }
      hideSpinner()
    }
    const loadEauClaireInventory = async () => {
      showSpinner()
      try {
        setLoadStep('Loading Eau Claire inventory...')
        const dsBlobServiceClient = new BlobServiceClient(environment === 'development' ? DATA_SERVICES_BLOBS_DEV : DATA_SERVICES_BLOBS, credential)
        const dsStorageContainerService = dsBlobServiceClient.getContainerClient('storage')
      
        const blobClient = dsStorageContainerService.getBlobClient(`inventory-service/Eau_Claire.json`)
        const data = await blobClient.download()
        const downloaded = await blobToString(await data.blobBody)
        const json = JSON.parse(downloaded)
        setInventory(json)
      }catch(error){
        console.error(error)
      }
      hideSpinner()
    }
    load()
  }, [reloadProducts, environment])

  /** Load existing orders */
  useEffect(() => {
    const loadOrders = async () => {
      showSpinner()
      try {
        const dsBazaarvoiceOrdersTableClient = new TableClient(environment === 'development' ? DATA_SERVICES_TABLES_DEV : DATA_SERVICES_TABLES, DS_BAZAARVOICE_ORDERS_TABLE, credential)
      
        const rows = dsBazaarvoiceOrdersTableClient.listEntities()
        const loadedRows = []
        for await(const row of rows){
          loadedRows.push(row)
        }
        setBvOrdersRows(loadedRows)
      }catch(error){
        console.log(error)
      }
      hideSpinner()
    }
    loadOrders()
  }, [reloadBvOrders, environment])
  
  const handleFileChanged = useCallback((file: File) => {
    if (!file) return;
    showSpinner();
  
    const reader = new FileReader();
    
    reader.onload = (event) => {
      try {
        const arrayBuffer = event.target?.result as ArrayBuffer;
        
        // Read the array buffer and convert it to a workbook
        const workbook = read(arrayBuffer, { type: 'array' });
        
        // Assume we want the first sheet
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
  
        // Convert the sheet to JSON
        const jsonData = utils.sheet_to_json(worksheet);
        setImportContent(jsonData)
      } catch (error) {
        console.error(error);
      }
      hideSpinner();
    };
  
    // Read file as an array buffer
    reader.readAsArrayBuffer(file);
    setImportFile(file)
  }, []);

  // Content Changed
  useEffect(() => {
    if(!importFile || !products || !importContent || !inventory) return
   
    const mapped: MissionControlOrderData[] = []
    const now = DateTime.now().setZone('America/Chicago').toISO()
    for(const order of importContent){
      order.errors = []
      /** Validate fields */
      const fullName = order['Column1']
      const phoneNumber = `${order['Phone Number']}`
      const orderId = order['Order ID']
      const city = order['City']
      const state = order['State']
      const street = order['Address Line 1']
      const country = order['Country']
      const postalCode = order['Postal Code']
      const productUpc = `${order['Product ID']}`

      if(typeof fullName === 'undefined' || fullName === null){
        order.errors.push('Missing Full Name field [Column1]')
      } else if(fullName.trim().length === 0){
        order.errors.push('Full Name field is empty. [Column1]')
      }

      if(typeof phoneNumber === 'undefined' || phoneNumber === null){
        order.errors.push('Missing Phone field [Phone Number]')
      } else if(phoneNumber.trim().length === 0){
        order.errors.push('Phone field is empty. [Phone Number]')
      }

      if(typeof orderId === 'undefined' || orderId === null){
        order.errors.push('Missing Order ID field [OrderID]')
      } else if(orderId.trim().length === 0){
        order.errors.push('Order ID field is empty. [Order ID]')
      }

      if(typeof city === 'undefined' || city === null){
        order.errors.push('Missing City field [City]')
      } else if(city.trim().length === 0){
        order.errors.push('City field is empty. [City]')
      }

      if(typeof state === 'undefined' || state === null){
        order.errors.push('Missing State field [State]')
      } else if(state.trim().length === 0){
        order.errors.push('State field is empty. [State]')
      }

      if(typeof postalCode === 'undefined' || postalCode === null){
        order.errors.push('Missing Postal Code field [Postal Code]')
      } else if(state.trim().length === 0){
        order.errors.push('Postal Code field is empty. [Postal Code]')
      }

      if(typeof country === 'undefined' || country === null){
        order.errors.push('Missing Country field [Country]')
      } else if(country.trim().length === 0){
        order.errors.push('Country field is empty. [Country]')
      }

      if(typeof street === 'undefined' || street === null){
        order.errors.push('Missing Street field [Address Line 1]')
      } else if(street.trim().length === 0){
        order.errors.push('Street field is empty. [Address Line 1]')
      }

      if(typeof productUpc === 'undefined' || productUpc === null){
        order.errors.push('Missing UPC field [Product ID]')
      } else if(productUpc.trim().length === 0){
        order.errors.push('UPC field is empty. [Product ID]')
      }

      const product = products.find(p => `${p.UPC__c}` === productUpc)
      if(!product){
        order.errors.push(`Product not found by UPC. [${productUpc}]`)
      }
      
      if(order.errors.length > 0){
        continue //onto the next order
      }

      const mappedOrder: BazaarvoiceOrder = {
        AccountId: '0016O00003TL7vYQAT',
        Customer_Name__c: fullName,
        Customer_Phone_Number__c: phoneNumber,
        Name: orderId,
        Order_Integration__c: 'a033l000011v2rCAAQ',
        Order_Time__c: now,
        EffectiveDate: now,
        Shipping_Address_Name__c: fullName,
        ShippingCity: city,
        ShippingCountry: country,
        ShippingPostalCode: `${postalCode}`,
        ShippingState: state,
        ShippingStreet: street,
        Order_Discount_Amount__c: 0,
        Order_Tags__c: 'Bazaar Voice',
        Shipping_Carrier_Requested__c: 'Fedex',
        Shipping_Class_Requested__c: 'Standard',
        Shipping_Collected__c: 0,
        Status: 'Draft',
        Tax_Collected__c: 0,
        SYS_CustomOrderData__c: importFile.name,
        Pricebook2Id: '01s1N0000066ylwQAA',
        Unique_External_ID__c: `bv_${orderId}`, //disallow duplicate orders
        Distribution_ID__c: '0',
        Distribution_Center_Name__c: 'Eau Claire'
      }

      const relatedInventory = inventory.find(i => i.Product__c === product.Id)
      const mappedOrderItem: BazaarvoiceOrderItem = {
        PricebookEntryId: product.PricebookEntries.records[0].Id,
        Product2Id: product.Id,
        Quantity: 1,
        UnitPrice: 0,
        ListPrice: 0.05,
        Fulfillment_Warehouse__c: relatedInventory.Warehouse__c,
        Inventory__c: relatedInventory.Id
      }
      mapped.push({
        order: mappedOrder,
        orderItems: [mappedOrderItem]
      })
    }
    setMappedContent(mapped)
  }, [importContent, products, importFile, inventory])

  const dragDropAreaView = useMemo(() => {
    return (
      <>
        <FileUploader handleChange={handleFileChanged} name="file" label="Import a Bazaarvoice Orders XLSX file." types={["XLSX"]} />
      </>
    )
  }, [])

  const mappedTable = useMemo(() => {
    if(!mappedContent) return
    if(!products) return
    if(!importContent) return

    const mappedRows = []
    for(const content of mappedContent){
      const relatedImportOrder = importContent.find(ic => ic['Order ID'] === content.order.Name)
      const product = products.find(p => `${p.Id}` === `${content.orderItems[0].Product2Id}`)
      const relatedInventory = inventory.find(i => i.Product__c === product.Id)

      const row = <tr>
        <td>{content.order.Name}</td>
        <td>{content.order.Customer_Name__c}</td>
        <td>{relatedInventory.Name.replace('_', ' ')} [{relatedInventory.Fulfillable_Inventory__c}]</td>
        <td>{content.order.ShippingStreet}</td>
        <td>{content.order.ShippingCity}</td>
        <td>{content.order.ShippingState}</td>
        <td>{content.order.ShippingPostalCode}</td>
        <td>{content.order.ShippingCountry}</td>
        <td>{relatedImportOrder?.errors?.length ?? 0 > 0 ? `${relatedImportOrder.errors.join('. ')}` : 'Ready to Load'}</td>
      </tr>
      mappedRows.push(row)
    }

    const tables = <div>
      {importContent.some(ic => ic.errors.length > 0) ? 
        <>
        <h2>Validation Errors</h2>
        <Table>
          <thead>
            <tr>
              <td>Order Number</td>
              <td>Errors</td>
            </tr>
          </thead>
          <tbody>
            {importContent.filter(ic => ic.errors.length > 0).map((ic) => {
              return (
                <tr>
                  <td style={{color: 'red'}}>{ic['Order ID']}</td>
                  <td style={{color: 'red', fontWeight: 'bolder'}}>{ic.errors.join(' | ')}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        </>
        : ''
      }
      <h2>Ready for Import</h2>
      <Table striped hover>
        <thead>
          <tr>
            <td>Order Number</td>
            <td>Customer</td>
            <td>Product</td>
            <td>Ship Street</td>
            <td>Ship City</td>
            <td>Ship State</td>
            <td>Ship Postal</td>
            <td>Ship Country</td>
            <td>Import Status</td>
          </tr>
        </thead>
        <tbody>
          {mappedRows}
        </tbody>
      </Table>
    </div>

    return (
      tables
    )

  }, [mappedContent, products, importContent])

  const reloadProductsClicked = useCallback((e) => {
    setReloadProducts(DateTime.now().toISO())
  }, [])

  const sendToMissionControlClicked = useCallback((e) => {
    const save = async function(){
      if(!mappedContent || mappedContent.length === 0) return
      if(!importContent) return

      showSpinner()
      try {
        const dsBazaarvoiceOrdersTableClient = new TableClient(environment === 'development' ? DATA_SERVICES_TABLES_DEV : DATA_SERVICES_TABLES, DS_BAZAARVOICE_ORDERS_TABLE, credential)

        for(const content of mappedContent){
          const rawContent = importContent.find(ic => ic['Order ID'] === content.order.Name)
          const bvOrderRow: BazaarvoiceOrdersTableRow = {
            partitionKey: content.order.SYS_CustomOrderData__c,
            rowKey: content.order.Name,
            orderNumber: content.order.Name,
            bazaarvoiceData: JSON.stringify(rawContent),
            importData: JSON.stringify(content),
            status: 'pending-import',
            product2Id: content.orderItems[0].Product2Id
          }        
          await dsBazaarvoiceOrdersTableClient.upsertEntity(bvOrderRow, 'Replace')
        }
        setImportContent(undefined)
        setMappedContent(undefined)
        setImportFile(undefined)
        setReloadBvOrders(DateTime.now().toISO())
      }catch(error){
        console.error(error)
      }
      hideSpinner()
    }
    save()
  }, [mappedContent, importContent, environment])

  const importOrdersView = useMemo(() => {
    return (
      <>
        <h2>{loadStep}</h2>
        <hr/>
        <Accordion defaultActiveKey={'import-step-1'} alwaysOpen>
          <AccordionItem eventKey={'import-step-1'}>
            <AccordionHeader><h2>Step 1: Load Products & Inventory</h2></AccordionHeader>
            <AccordionBody>
              {!products && !inventory ? <Button style={{width: '100%', height: '50px'}} onClick={reloadProductsClicked}>Load Products & Inventory</Button> : `Products & Inventory loaded successfully on ${reloadProducts}.`}
            </AccordionBody>
          </AccordionItem>
          <AccordionItem eventKey={'import-step-2'}>
            <AccordionHeader><h2>Step 2: Select Bazaarvoice Orders File</h2></AccordionHeader>
            <AccordionBody>
              {products ? <> <p>Upload a changed file to overwrite previous content and apply changes below.</p> {dragDropAreaView}</> : 'Load Products & Inventory first.'}
            </AccordionBody>
          </AccordionItem>
          <AccordionItem eventKey={'import-step-3'}>
            <AccordionHeader><h2>Step 3: Validate & Import</h2></AccordionHeader>
            <AccordionBody>
              {importContent ? <>
                <Button variant='success' style={{width: '100%', height: '50px'}} onClick={sendToMissionControlClicked} hidden={(importContent?.length ?? -1) !== (mappedContent?.length ?? 0)}>Start Process</Button>
                <hr/>
                {mappedTable}
              </> : 'Load Products & Inventory then and Select Orders File.'}
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      </>
    )
  }, [environment, dragDropAreaView, mappedTable, importContent, mappedContent, products, reloadProducts, loadStep, inventory])

  const refreshExistingOrdersClicked = useCallback((e) => {
    setReloadBvOrders(DateTime.now().toISO())
  }, [])

  const exportClicked = useCallback((e) => {
    const exportData = async function () {
      try {
        /** Get related rows by file name */
        const rows = bvOrdersRows.filter(row => row.partitionKey === e.target.id.split('___')[0])
        const excelRows = []
        for(const row of rows){
          const bvData = JSON.parse(row.bazaarvoiceData)
          /** Set to Central time */
          bvData.shipDate = DateTime.fromISO(row.shipDate).setZone('America/Chicago').toISO()
          bvData.trackingNumber = row.trackingNumber
          bvData.carrier = row.carrier
          /** Convert numbers to string to avoid scientific notation */
          bvData['Product ID'] = `${bvData['Product ID']}`
          bvData['Logistics ID'] = `${bvData['Logistics ID']}`
          bvData['Postal Code'] = `${bvData['Postal Code']}`
          delete bvData.errors
          delete bvData.Product
          excelRows.push(bvData)
        }

        const fileName = rows[0].partitionKey
        const worksheet = utils.json_to_sheet(excelRows)
        const workbook = utils.book_new()
        utils.book_append_sheet(workbook, worksheet, 'Orders')
        writeFile(workbook, fileName)

        /** Mark them as pending-iag */
        showSpinner()
        const dsBazaarvoiceOrdersTableClient = new TableClient(environment === 'development' ? DATA_SERVICES_TABLES_DEV : DATA_SERVICES_TABLES, DS_BAZAARVOICE_ORDERS_TABLE, credential)

        for(const row of rows){
          /** Allow future exports but not setting their statuses */
          if(row.status !== 'pending-export') continue
          row.status = 'pending-iag'
          await dsBazaarvoiceOrdersTableClient.upsertEntity(row, 'Replace')
        }
      }catch(error){
        console.error(error)
      }    
      hideSpinner()
    }
    exportData()
  }, [bvOrdersRows])

  const existingOrdersView = useMemo(() => {
    if(!bvOrdersRows){
      return 'No orders...'
    }

    const fileMap: Map<string, BazaarvoiceOrdersTableRow[]> = new Map()
    for(const row of bvOrdersRows){
      if(!fileMap.has(row.partitionKey)){
        fileMap.set(row.partitionKey, [])
      }
      fileMap.get(row.partitionKey).push(row)
    }

    const accordionTables = []
    
    for(const key of fileMap.keys()){
      const rows = []
      const records = fileMap.get(key)

      for(const record of records){
        rows.push(<tr>
          <td>{record.orderNumber}</td>
          <td>{record.shipDate ?? ''}</td>
          <td>{record.carrier ?? ''}</td>
          <td>{record.trackingNumber ?? ''}</td>
          <td>{record.status}</td>
        </tr>)

      }
      const pendingExport = records.filter(r => r.status === 'pending-export')
      const pendingImport = records.filter(r => r.status === 'pending-import')
      const pendingFulfillment = records.filter(r => r.status === 'pending-fulfillment')
      const pendingIag = records.filter(r => r.status === 'pending-iag')

      const left = records.length - (pendingImport.length + pendingFulfillment.length)
      const pendingImportText = `@Import[${pendingImport.length}/${records.length}]`
      const pendingExportText = `@Export[${pendingExport.length}/${records.length}]`
      const pendingFulfillmentText = `@Fulfillment[${pendingFulfillment.length}/${records.length}]`
      const pendingIagText = `@IAG[${pendingIag.length}/${records.length}]`
      
      const allReady = pendingExport.length === records.length || pendingIag.length === records.length
      accordionTables.push(<>
        <AccordionItem eventKey={key}>
          <AccordionHeader><h2>{key}{allReady === true ? ' - Ready for Export' : ` - ${pendingImportText} - ${pendingFulfillmentText} - ${pendingExportText}`}</h2></AccordionHeader>
          <AccordionBody>
            <div>
              {pendingExport.length === records.length ? 'All orders are ready for export.' : 'All orders are not ready for export.'}
            </div>
            <Button onClick={exportClicked} style={{width: '100%', height: '50px'}} key={`${key}___export`} id={`${key}___export`} variant='success' disabled={!allReady}>Export to XLSX</Button>
            <hr/>
            <Table striped hover>
              <thead>
                <tr>
                  <td>Order Number</td>
                  <td>Ship Date</td>
                  <td>Carrier</td>
                  <td>Tracking Number</td>
                  <td>Status</td>
                </tr>
              </thead>
              <tbody>
                {rows}
              </tbody>
            </Table>
          </AccordionBody>
        </AccordionItem>
      </>)
    }

    return <>
    <Accordion>
      {accordionTables}
    </Accordion>
    </>
  }, [bvOrdersRows])

  return (
    <>
    <h1>Bazaarvoice Promotional Orders</h1>
    <hr/>
    <div>
      <ButtonGroup>
        {RADIO.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant={idx % 2 ? 'outline-success' : 'outline-danger'}
            name="radio"
            value={radio.value}
            checked={environment === radio.value}
            onChange={(e) => setEnvironment(e.currentTarget.value)}
            disabled={radio.enabled !== true}
          >
            {radio.name}
          </ToggleButton>
        ))}
      </ButtonGroup>
    </div>
    <hr/>
    <Tabs>
      <TabList>
        <Tab key='orderTab'>Import Orders</Tab>
        <Tab key='existingTab'>Existing/Export Orders</Tab>
      </TabList>
      <TabPanel key='orderTabPanel'>
        {importOrdersView}
      </TabPanel>
      <TabPanel key='existingTab'>
        <Button style={{width: '100%', height: '50px'}} onClick={refreshExistingOrdersClicked}>Refresh Existing Orders</Button>
        <hr/>
        {existingOrdersView}
      </TabPanel>
    </Tabs>
    </>
  )
}

export default BazaarvoiceOrdersComponent
