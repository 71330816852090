import { Button, Col, Container, Form, Offcanvas, Row } from 'react-bootstrap'
import { useCallback, useMemo, useState } from 'react'
import { InteractiveBrowserCredential } from '@azure/identity'
import { TENANT_ID, CLIENT_ID } from '../../authConfig'
import { BlobServiceClient } from '@azure/storage-blob'
import { DATA_SERVICES_BLOBS_DEV, DATA_SERVICES_BLOBS } from '../../common/common.constants'
import { blobToString } from '../../blobs/blobs.service'
import ProductHSCodesRequirementsComponent from './ProductHSCodesRequirementsComponent'
import { useSpinner } from '../../spinner/SpinnerContext'


const HSCODES_FOLDER = 'mission-control/hs-codes'

const ProductHSCodesDebugComponent = () => {
  const { showSpinner, hideSpinner } = useSpinner();
  const dev = false
  const credential = new InteractiveBrowserCredential({
    tenantId: TENANT_ID,
    clientId: CLIENT_ID,
    authorityHost: 'https://login.microsoft.com'
  })
  const blobServiceClient = new BlobServiceClient(dev ? DATA_SERVICES_BLOBS_DEV : DATA_SERVICES_BLOBS, credential)
  const containerService = blobServiceClient.getContainerClient('storage')
  
  const [searchMpn, setSearchMpn] = useState(undefined)
  const [foundBlobs, setFoundBlobs] = useState(undefined)
  const [selectedBlob, setSelectedBlob] = useState(undefined)
  const [content, setContent]: [string[], any] = useState(undefined)
  const [showRequirements, setShowRequirements] = useState(false)

  const handleSearchButtonClicked = useCallback((e) => {
    const search = async () => {
      if(typeof searchMpn === 'undefined') return
      try {
        showSpinner()
        const blobsFlat = containerService.listBlobsFlat({prefix: `${HSCODES_FOLDER}/debug/${searchMpn}`})
        const blobs = []
        for await(const blob of blobsFlat){
          blobs.push(blob)
        }
        setFoundBlobs(blobs)
      }catch(error){
        console.log(error)
      }
      hideSpinner()
    }
    search()
  }, [searchMpn])

  const handleSearchMpnChanged = useCallback((e) => {
    setSearchMpn(encodeURIComponent(e.target.value))
  }, [])

  const handleSelectionChanged = useCallback((e) => {
    console.log(e.target.value)
    setSelectedBlob(e.target.value)
    setContent(undefined)
  }, [])

  const handleLoadBlobClicked = useCallback((e) => {
    const load = async () => {
      try {
        if(typeof selectedBlob === 'undefined') return
        showSpinner()
        const blobClient = containerService.getBlobClient(selectedBlob)
        const data = await blobClient.download()
        const downloaded = await blobToString(await data.blobBody)
        const json = JSON.parse(downloaded)
        setContent(json)
      }catch(error){
        console.log(error)
      }
      hideSpinner()
    }
    load()
  }, [selectedBlob])

  const blobsSelectionOptions = useMemo(() => {
    if(typeof foundBlobs === 'undefined' || foundBlobs?.length === 0) return

    const options = []
    for(const blob of foundBlobs){
      const blobName = blob.name.split('/').pop()
      const justMpn = blobName.split('___')[0]
      options.push(<option key={blob.name} value={blob.name}>{justMpn}</option>)
    }
    const form = (
      <>
        <Form.Select onChange={handleSelectionChanged}>
          <option>Select an MPN...</option>
          {options}
        </Form.Select>
        <br/>
        <Button variant='success' onClick={handleLoadBlobClicked}>Load</Button>
      </>
    )
    return form
  }, [foundBlobs, handleSelectionChanged, handleLoadBlobClicked])

  const contentView = useMemo(() => {
    if(typeof content === 'undefined') return

    const lines = []
    let lastStep = undefined
    for(const line of content){
      const lineSplit = line.split(':')
      const step = lineSplit[0]
      const data = lineSplit[1]
      if(typeof lastStep === 'undefined'){
        lastStep = step
      }
      if(lastStep !== step){
        lines.push(<br/>)
        lastStep = step
      }
      lines.push(<div><strong>{step}:</strong> {data}</div>)
    }
    const selectedBlobSplit = selectedBlob.split('/').pop()
    const splitName = selectedBlobSplit.split('___')
    const mpn = decodeURIComponent(splitName[0])
    const id = splitName[1].replace('.json', '')
    const foundBlob = foundBlobs.find(fb => fb.name.includes(selectedBlob))

    return (
      <>
        <hr/>
        <h3>{mpn}</h3>
        <div><a href={`https://nethealthshopsllc.lightning.force.com/${id}`}>Salesforce</a> - {foundBlob?.properties?.lastModified?.toLocaleString()}</div>
        <hr/>
        {lines}
      </>
    )
  }, [content, selectedBlob])

  const handleShowRequirementsButtonClicked = useCallback((e) => {
    setShowRequirements(true)
  }, [])

  const handleCloseRequirements = useCallback((e) => {
    setShowRequirements(false)
  }, [])

  const buildView = useMemo(() => {
    return (
      <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
        <h2>Product HS Codes Debug</h2>
        <hr/>
        <div>
          <Button onClick={handleShowRequirementsButtonClicked}>Requirements</Button>
          <Offcanvas placement='end' show={showRequirements} onHide={handleCloseRequirements}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Product HS Code Requirements</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div style={{fontSize: 'larger'}}>
                <ProductHSCodesRequirementsComponent />

              </div>
            </Offcanvas.Body>
          </Offcanvas>
        </div>
        <hr/>
        <Container>
          <Row>
            <Col>
              <div>
                <Form.Control style={{width: '250px'}} type='text' placeholder='Enter an MPN...' onChange={handleSearchMpnChanged}></Form.Control>
                <br/>
                <Button variant='success' onClick={handleSearchButtonClicked}>Search</Button>
              </div>
            </Col>
            <Col>
              {blobsSelectionOptions}
            </Col>
          </Row>
        </Container>
        <div>
        </div>
        <div style={{fontSize: 'larger'}}>
          {contentView}
        </div>
      </div>
    )
  }, [handleSearchButtonClicked, handleSearchMpnChanged, blobsSelectionOptions, contentView, showRequirements, handleShowRequirementsButtonClicked])

  return buildView
}

export default ProductHSCodesDebugComponent