import { DateTime } from 'luxon'
import { useState, useCallback, useMemo } from 'react'
import { Button, Container, Row, Col, Table, Form } from 'react-bootstrap'
import { CommonProperties } from '../common/common.interfaces'
import { SalsifySuffixesTableRow } from './salsify.interfaces'
import { notify } from '../notify/notify'

interface SalsifySuffixesComponentProperties extends CommonProperties {
  suffixes: SalsifySuffixesTableRow[]
  salsifySuffixesTableService: any
  setLastRefresh: any
}

const SalsifySuffixesComponent = (props: SalsifySuffixesComponentProperties) => {
  const [newSuffixPartitionKey, setNewSuffixPartitionKey] = useState(undefined)
  const [newSuffixRowKey, setNewSuffixRowKey] = useState(undefined)
  const [newSuffixPartitionKeyError, setNewSuffixPartitionKeyError] = useState(undefined)
  const [newSuffixRowKeyError, setNewSuffixRowKeyError] = useState(undefined)
  
  const handleNewSuffixPartitionKeyChange = useCallback((e) => {
    const foundSuffix = props.suffixes.find(s => s.partitionKey.toLowerCase() === e.target.value.toLowerCase())
    if(typeof foundSuffix !== 'undefined'){
      setNewSuffixPartitionKeyError('This listing suffix already exists!')
    } else {
      setNewSuffixPartitionKeyError(undefined)
    }
    setNewSuffixPartitionKey(e.target.value)
  }, [props.suffixes])

  const handleNewSuffixRowKeyChange = useCallback((e) => {
    const foundSuffix = props.suffixes.find(s => s.rowKey.toLowerCase() === e.target.value.toLowerCase())
    if(typeof foundSuffix !== 'undefined'){
      setNewSuffixRowKeyError('This Salsify Field already exists!')
    } else {
      setNewSuffixRowKeyError(undefined)
    }
    setNewSuffixRowKey(e.target.value)
  }, [props.suffixes])

  const handleSaveNewSuffix = useCallback((e) => {
    const saveSuffix = async () => {
      try {
        await props.salsifySuffixesTableService.upsertEntity({partitionKey: newSuffixPartitionKey, rowKey: newSuffixRowKey}, 'Replace')
        notify('success', `${newSuffixPartitionKey} ${newSuffixRowKey} saved successfully!`)
        props.setLastRefresh(DateTime.utc().toMillis())
      }catch(error){
        notify('error', `Failed to save ${newSuffixPartitionKey} ${newSuffixRowKey}! ${error}`)
        console.log(error)
      }
    }
    saveSuffix()
  }, [newSuffixPartitionKey, newSuffixRowKey])

  const handleDeleteSuffix = useCallback((e) => {
    const deleteSuffix = async () => {
      if(confirm('Are you sure you wish to permanently delete this mapping?')){
        const splitId = e.target.id.split('|')
        const pKey = splitId[0]
        const rKey = splitId[1]
        try {
          await props.salsifySuffixesTableService.deleteEntity(pKey, rKey)
          notify('success', `${pKey} ${rKey} deleted successfully!`)
          props.setLastRefresh(DateTime.utc().toMillis())
        }catch(error){
          notify('error', `Failed to delete ${pKey} ${rKey}! ${error}`)
          console.log(error)
        }
      }
    }
    deleteSuffix()
  }, [])

  const buildSuffixes = useMemo(() => {
    const tr = []
    for(const record of props.suffixes){
      tr.push(
        <tr>
          <td>{record.partitionKey}</td>
          <td>{record.rowKey}</td>
          <td><Button onClick={handleDeleteSuffix} variant='danger' id={`${record.partitionKey}|${record.rowKey}`}>Delete</Button></td>
        </tr>
      )
    }
    const saveEnabled = 
      typeof newSuffixPartitionKey !== 'undefined' && newSuffixPartitionKey.trim().length > 0 &&
      typeof newSuffixRowKey !== 'undefined' && newSuffixRowKey.trim().length > 0 &&
      typeof newSuffixPartitionKeyError === 'undefined' && 
      typeof newSuffixRowKeyError === 'undefined'

    return (
      <>
      <div>
        <Container>
          <Row>
            <Col><Form.Control onChange={handleNewSuffixPartitionKeyChange} placeholder='Enter Listing Suffix...'></Form.Control></Col>
            <Col><Form.Control onChange={handleNewSuffixRowKeyChange} placeholder='Enter Salsify Field...'></Form.Control></Col>
            <Col><Button onClick={handleSaveNewSuffix} disabled={!saveEnabled} variant='success'>Save New Suffix</Button></Col>
          </Row>
          <Row>
            <Col><div style={{color: 'darkred'}}>{newSuffixPartitionKeyError}</div></Col>
            <Col><div style={{color: 'darkred'}}>{newSuffixRowKeyError}</div></Col>
            <Col><div></div></Col>
          </Row>
        </Container>

      </div>
      <hr/>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Listing Suffix</th>
            <th>Salsify Field</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {tr}
        </tbody>
      </Table>
      </>
    )
  }, [props.suffixes, newSuffixPartitionKeyError, handleNewSuffixPartitionKeyChange, newSuffixRowKeyError, handleNewSuffixRowKeyChange, newSuffixPartitionKey, newSuffixRowKey, handleSaveNewSuffix, handleDeleteSuffix])

  return buildSuffixes
}

export default SalsifySuffixesComponent
