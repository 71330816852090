import { Modal, Button } from 'react-bootstrap'
import { blob } from 'stream/consumers'

interface BlobModalComponentProperties {
  modalVisible: boolean
  closeModal: any
  blobName: string
  blobData: any
}

const BlobModalComponent = (props: BlobModalComponentProperties) => {
  return (
    <Modal show={props.modalVisible} animation={false} onHide={props.closeModal} className='modal-xl'>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.blobName}
        </Modal.Title>
      </Modal.Header>
        
      <Modal.Body>
        {props.blobData}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='danger' onClick={props.closeModal}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default BlobModalComponent
