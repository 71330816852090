export const SUBSCRIPTION_ID = '79a4e9ac-6bb3-4bed-9615-98820e09723b'
export const TENANT_ID = '187406b1-30f5-4248-afc8-cd3eb8c4e567'
export const CLIENT_ID = '93150465-118d-4d0c-8813-6cfbbd186857'

export function getMsalConfig(url: string) {
  return {
    auth: {
      clientId: CLIENT_ID,
      authority: `https://login.microsoftonline.com/${TENANT_ID}`,
      redirectUri: url
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false
    }
  }
}

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const LOGIN_REQUEST_SCOPES = {
  scopes: ["User.Read"]
}

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const GRAPH_CONFIG = {
    graphMeEndpoint: "https://login.microsoftonline.com"
}