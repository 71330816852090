import { Button, Form, Row, Col, Container } from "react-bootstrap"
import { useAmazonDeliveryMetricsContext } from "../../contexts/amazonDeliveryMetricsContext";
import { getPreviousWeekRange } from "../../utilities/getPreviousWeekRage";

const AmazonDeliveryApplyClearFilterComponent = ({}) => {

  const{
    setSelectedStartDate,
    setSelectedEndDate,
    selectedStartDate,
    selectedEndDate,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    selectedReason,
    setSelectedReason,
    refetchCurrent,
    handleFilter
  } = useAmazonDeliveryMetricsContext()

  const handleApplyFilters = (e) => {
    e.preventDefault();
    
    // Check if any of the date filters have changed
    const filtersChanged =
      startDate !== selectedStartDate || 
      endDate !== selectedEndDate;
    
    // If date filters have changed, we need to refetch data
    if (filtersChanged) {
      setStartDate(selectedStartDate);
      setEndDate(selectedEndDate);
    }
    
    // Apply selectedReason filter immediately if it has changed
    if (selectedReason !== "") {
      handleFilter(selectedReason); // Apply filter if a reason is selected
    } else {
      // If no reason is selected (or it defaults to "none"), reset to currentData
      handleFilter(""); // Reset to show all data
    }
  };

  const handleClearFilters = (e) => {
    e.preventDefault();
  
    const defaultStartDate = getPreviousWeekRange().previousSunday;
    const defaultEndDate = getPreviousWeekRange().previousSaturday;
  
    setSelectedReason(""); 
    handleFilter("");
  
    setSelectedStartDate(defaultStartDate);
    setSelectedEndDate(defaultEndDate);
  
    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
  };


  return(
  <Container>
    <Row className="justify-content-evenly">
      <Col>
        <Form>
          <Row className="mb-3">
            <Col>
              <Button className="w-100" variant="outline-primary" onClick={handleApplyFilters}>Apply Filters</Button>
            </Col>
            <Col>
              <Button className="w-100" variant="outline-primary" onClick={handleClearFilters}>Clear Filters</Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  </Container>
  )
}

export default AmazonDeliveryApplyClearFilterComponent;