const ProductHSCodesRequirementsComponent = () => {
  const content = (
    <>
      <h4>HS Code - US - Base Tariff %</h4>
      <div>
        If the product's country manufactured is included in the HS Code US's Excluded Countries list, this value will be set to 0%, otherwise it will be set to the HS Code US's Standard Tariff Percent.
      </div>
      <br/>
      <h4>HS Code - US - 99 Tariff %</h4>
      <div>
        If the product's HS Code US 99 Exclusion field exists, this value will be set to 0%.<br/><br/>
        If the product's country manufactured is NOT included in the HS Code US Chapter 99 Affected Countries list, this value will be set to 0%.<br/><br/>
        If neither of those two conditions are met, then this value will be set to the HS Code US's Chapter 99 Tariff Percent.
      </div>
      <br/>
      <h4>HS Code - CA - Effective Tariff % </h4>
      <div>
        If the product's country manufactured exists in the HS Code CA's MFN Revoked Countries list, this value will be set to 35%.<br/><br/>
        If the product's country manufactured exists in the HS Code CA's Excluded Countries list, this value will be set to 0%.<br/><br/>
        If the product's country manufactured exists in the HS Code CA's Reduced Tariff Countries list, this value will be set to the HS Code CA's Reduced Tariff Percent.<br/><br/>
        If none of the previous conditions are met, then this value will be set to the HS Code CA's Standard Tariff Percent.
      </div>
    </>
  )

  return content
}

export default ProductHSCodesRequirementsComponent
