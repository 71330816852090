import { useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Col, Container, Form, Modal, Row, Table } from 'react-bootstrap'
import { notify } from '../notify/notify'

interface AmazonSFPConfigEditProps {
  configName: string
  listings: {listingName: string, Id: string, reason?: string}[]
  visible: boolean
  closeModal: any
  saveConfig: any
}

const AmazonSFPConfigListingsEdit = (props: AmazonSFPConfigEditProps) => {
  const [listings, setListings] = useState(undefined)
  const [listingRows, setListingRows] = useState(undefined)
  const [modifiedListingRows, setModifiedListingRows] = useState(undefined)
  const [addListingName, setAddListingName] = useState(undefined)
  const [addListingId, setAddListingId] = useState(undefined)

  /** Initial Load */
  useEffect(() => {
    const cloned = [...props?.listings ?? []]
    for(const listing of cloned){
      delete listing.reason
    }
    setListings(cloned)
  }, [props.listings])

  /** Adds a removal listing to the changed list. */
  const handleRemoveClicked = useCallback((e) => {
    if(!listings) return
    const newListings = listings?.map(l => {
      if(l.Id === e.target.id){
        l.reason = 'Removal' 
      }
      return l
    })

    setListings(newListings)
  }, [listings])

  /** Removes a changed listing by not including it, or deleting the reason property which controls the UI. */
  const handleCancelClicked = useCallback((e)=> {
    if(!listings) return
    const newListings = []
    for(const listing of listings){
      if(listing.Id === e.target.id){
        if(listing.reason === 'Removal'){
          delete listing.reason //removes the reason
        } else if(listing.reason === 'Addition'){
          continue //removed from the list
        }
      }

      newListings.push({...listing})
    }
    setListings(newListings)
  }, [listings])

  /** Builds the tables in the Modal based on listings. */
  useEffect(() => {
    if(!listings) return
    setListingRows([])
    setModifiedListingRows([])
    const rows = []
    for(const listing of listings){
      rows.push(<tr key={`${listing.Id}___exist`}>
        <td>{listing.listingName}</td>
        <td>{listing.Id}</td>
        <td><Button id={listing.Id} onClick={handleRemoveClicked} variant='danger' disabled={typeof listing.reason !== 'undefined'}>{listing.reason ? listing.reason : 'Remove'}</Button></td>
      </tr>)
    }
    setListingRows(rows)

    if(listings?.some(l => typeof l.reason !== 'undefined')){
      const modifiedRows = []
      for(const listing of listings.filter(l => typeof l.reason !== 'undefined')){
        modifiedRows.push(<tr key={`${listing.Id}___mod`}>
          <td>{listing.listingName}</td>
          <td>{listing.Id}</td>
          <td>{listing.reason}</td>
          <td><Button id={listing.Id} onClick={handleCancelClicked} variant='danger'>Cancel</Button></td>
        </tr>)
      }
      
      setModifiedListingRows(modifiedRows)
    }
  }, [listings])

  /** Saves changes made. Notifies when there are no changes. */
  const handleApplyNewListingsClicked = useCallback((e) => {
    if(!listings) return
    const changed = listings.filter(l => typeof l.reason !== 'undefined')
    if(changed.length === 0) {
      notify('warning', 'No changes to be made.')
      return
    }
    const final = []
    for(const listing of listings){
      const changeReason = changed?.find(l => l.Id === listing.Id)?.reason
      if(changeReason === 'Removal'){
        continue
      }
      delete listing.reason
      final.push(listing)
    }
    if(confirm(`Apply [${changed.length}] listing changes to [${props?.configName}]?`)){
      props.saveConfig(final, props.configName)
      setListingRows(undefined)
      setModifiedListingRows(undefined)
      setListings(undefined)
      setAddListingId(undefined)
      setAddListingName(undefined)      
    }
  }, [listings])

  /** Allows JSON from SF Data Export. Must contain an array of Id and Name properties. */
  const handleListingsImportChanged = useCallback((e) => {
    const parsed = JSON.parse(e.target.value)
    const newListings = []
    for(const listing of parsed){
      const existing = listings.find(l => l.Id === listing.Id)
      if(existing) continue //dedupe
      newListings.push({
        listingName: listing.Name,
        Id: listing.Id,
        reason: 'Addition'
      })
    }
    setListings([...newListings, ...listings])
  }, [listings])

  /** Sets everything to defaults before closing the modal. */
  const handleCancel = useCallback(()=> {
    setListingRows(undefined)
    setModifiedListingRows(undefined)
    setListings(undefined)
    setAddListingId(undefined)
    setAddListingName(undefined)
    props.closeModal()
  }, [])

  /** Adds a listing from the text boxes for manual listing addition. */
  const handleAddListingClicked = useCallback((e) => {
    if(!listings || !addListingId || !addListingName) return
    if(addListingId.trim() === '' || addListingName.trim() === '') return

    const newListings = []
    const existing = listings.find(l => l.Id === addListingId)
    if(existing) return //dedupe
    newListings.push({
      listingName: addListingName,
      Id: addListingId,
      reason: 'Addition'
    })
    for(const listing of listings){
      newListings.push({...listing})
    }

    setListings(newListings)
    setAddListingId(undefined)
    setAddListingName(undefined)
  }, [listings, addListingId, addListingName])

  /** Updates from the Listing Name text box. */
  const handleAddListingNameChanged = useCallback((e) => {
    setAddListingName(e.target.value)
  }, [])

  /** Updates from the Listing Id text box. */
  const handleAddListingIdChanged = useCallback((e) => {
    setAddListingId(e.target.value)
  }, [])

  /** Modal view based on listings and form controls. */
  const view = useMemo(() => {
    return (
      <Modal show={props.visible} onHide={handleCancel} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>{props?.configName?.split('/')?.pop()} Listings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Button onClick={handleCancel}>
          Cancel
        </Button>
      <Button variant='success' onClick={handleApplyNewListingsClicked}>
          Save
        </Button>
        <hr/>
        Import
      <Form>
        <Form.Control onChange={handleListingsImportChanged} as="textarea" placeholder='Paste JSON. Requires [Id] and [Name] Salesforce fields...' rows={3} />
      </Form>

      <hr/>
      <Container>
        <Row>
          <Col>
              <Form.Label >Listing Name</Form.Label>
              <Form.Control onChange={handleAddListingNameChanged} value={addListingName ?? ''} type='text' placeholder='Enter listing name...'></Form.Control>
          </Col>
          <Col>
              <Form.Label >Salesforce ID</Form.Label>
              <Form.Control onChange={handleAddListingIdChanged} value={addListingId ?? ''} type='text' placeholder='Enter listing Salesforce ID...'></Form.Control>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button onClick={handleAddListingClicked}>Add</Button>
          </Col>
        </Row>
      </Container>
      <h3>Changes [{modifiedListingRows?.length ?? 0}]</h3>
      <Table striped hover>
          <thead>
            <tr>
              <th>Listing Name</th>
              <th>Salesforce ID</th>
            </tr>
          </thead>
          <tbody>
            {modifiedListingRows}
          </tbody>
        </Table>
      <hr/>
      <h3>Preview [{listingRows?.length ?? 0}]</h3>
      <Table striped hover>
        <thead>
          <tr>
            <th>Listing Name</th>
            <th>Salesforce ID</th>
          </tr>
        </thead>
        <tbody>
          {listingRows}
        </tbody>
      </Table>
      
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant='success' onClick={handleApplyNewListingsClicked}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
    )
  }, [modifiedListingRows, listingRows, props, listings, handleAddListingIdChanged, handleAddListingNameChanged, handleAddListingClicked, addListingId, addListingName])

  return (view)
}

export default AmazonSFPConfigListingsEdit
