import { Table } from "react-bootstrap";

const AmazonViewGraphSummary = ({ graphSummary}) => { 
  return(
    <div className="p-3 shadow-sm bg-light rounded">
      <p className="fs-2">Graph Summary</p>
      <Table>
        <thead>
          <tr>
            <th>
              Type
            </th>
            <th>
              Percentage
            </th>
            <th>
              Views
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              1 Day
            </td>
            <td>
              {graphSummary.oneDayPercent.toFixed(2)}%
            </td>
            <td>
              {graphSummary.oneDayViews}
            </td>
          </tr>
          <tr>
            <td>
              2 Day
            </td>
            <td>
              {graphSummary.twoDayPercent.toFixed(2)}%
            </td>
            <td>
              {graphSummary.twoDayViews}
            </td>
          </tr>
          <tr>
            <td>
              More Than 2 Days
            </td>
            <td>
              {graphSummary.greaterThanTwoDayPercent.toFixed(2)}%
            </td>
            <td>
              {graphSummary.greaterThanTwoDayViews}
            </td>
          </tr>
          <tr>
            <td>
              Total
            </td>
            <td></td>
            <td>
              {graphSummary.totalViews}
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  )
}

export default AmazonViewGraphSummary;