import { useQuery } from "@tanstack/react-query"
import { apiRequest } from "../../utility/axios"
import { useSpinner } from "../../spinner/SpinnerContext"
import { useState } from "react"
import { DateTime } from "luxon"
import { PrimeSpeedRow } from "../../common/common.types"

const useAmazonPageSpeedViewsData = (initialStartDate, initialEndDate) => {
  const { toggleSpinner } = useSpinner()
  const [file, setFile] = useState<File | null>(null)
  const [startDate, setStartDate] = useState<string | null>(initialStartDate)
  const [endDate, setEndDate] = useState<string | null>(initialEndDate)

  const { isLoading: isLoadingCurrent, isPending: isPendingCurrent, error: currentError, data: currentData, isFetching: isFetchingCurrent, refetch: refetchCurrent } = useQuery<PrimeSpeedRow[]>({
    queryKey: ["CurrentRows", startDate, endDate],
    queryFn: async (): Promise<PrimeSpeedRow[]> => {      
      const response = await apiRequest("GET", `/api/amazonPageSpeedViews`, null, {params: {startDate, endDate}})
      return response as PrimeSpeedRow[]
    },
  })

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0])
    }
  }

  const uploadFile = async () => {
    if (!file) {
      alert('Please select a file to upload.')
      return
    }
  
    const reader = new FileReader()
  
    reader.onload = async (event) => {
      const content = event.target?.result as string
  
      // Parse TSV content (you could use a more robust parsing method)
      const rows = content.split('\n').map(row => row.split('\t'))
  
      // Assuming you have a header row, check the headers
      const expectedHeaders = ['ASIN', 'Date', 'Size Tier', 'Same-day detail page views', '<= 1 day detail page views', '<= 2 days detail page views', '> 2 days detail page views']
      const fileHeaders = rows[0]
  
      // Validate headers match the expected structure
      const headersMatch = expectedHeaders.every((header, index) => header === fileHeaders[index])
  
      if (!headersMatch) {
        alert('The file headers do not match the expected format.')
        return
      }
  
      // If the validation passes, proceed to upload
      const formData = new FormData()
      formData.append('tsvContent', file)
  
      try {
        toggleSpinner(true)
        await apiRequest("POST", `/api/amazonPageSpeedViews`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      } catch (error) {
        console.error('Error uploading file:', error)
      } finally {
        toggleSpinner(false)
      }
    }
  
    reader.onerror = (error) => {
      console.error('Error reading file:', error)
      alert('Error reading file. Please try again.')
    }
  
    reader.readAsText(file)
  }

  return {
    isLoadingCurrent,
    isPendingCurrent,
    currentError,
    currentData,
    isFetchingCurrent,
    handleFileChange,
    uploadFile,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    refetchCurrent
  }
}

export default useAmazonPageSpeedViewsData