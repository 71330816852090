import { Outlet } from "react-router-dom"
import Footer from "../footer/Footer"
import Header from "../header/Header"
import SideMenu from "../sideMenu/SideMenu"

const Layout = ({ navList }) => {
  return (
    <>
      <Header />
      <SideMenu navList={navList} />

      <main>
        <div className="d-flex flex-column px-5">
          <Outlet />
        </div>
      </main>
      
      <Footer />
    </>
  )
}

export default Layout