import { useMutation, useQuery } from "@tanstack/react-query";
import { createColumnHelper, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { Alert, Button, Container, OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";
import { apiRequest } from "../utility/axios";
import { useEffect, useMemo, useState } from "react";
import { useSpinner } from "../spinner/SpinnerContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notify } from "../notify/notify";
import { faCircleCheck, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { AxiosError } from "axios";
import TsTable from "../components/TsTable";
import { booleanSort, fuzzyFilter, getProcessingPercentage } from "../utility/common";
import { DebouncedInput } from "../components/DebouncedInput";
import { IagPipeLineStatusRes, IagTrackingRow, JobStatus, iagProcessingPercentageMap } from "./interfaces";
import ConfirmModal from "../components/ConfirmModal";

const IagTracking = () => {
  const { toggleSpinner } = useSpinner();
  const columnHelper = createColumnHelper<IagTrackingRow>();

  //#region useState
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([
    { id: "expediteTracking", desc: false },
    { id: "status", desc: false },
    { id: "iagName", desc: true },
  ]);
  const [jobStatus, setJobStatus] = useState<JobStatus>({
    isRunning: false,
    processingStatus: "Pending",
    finalOutput: "",
    finalStatus: null,
    processingPrecentage: 0,
  });
  //#endregion useState

  //#region confirm modal
  const [showModal, setShowModal] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [modalCallback, setModalCallback] = useState(null);

  const handleExpediteIags = () => {
    setModalMsg("Are you sure you want to expedite IAGs tracking?");
    setModalCallback(() => handleConfirmExpedite);
    setShowModal(true);
  };

  const handleTerminateIags = () => {
    setModalMsg("Are you sure you want to terminate IAGs tracking?");
    setModalCallback(() => handleConfirmTerminate);
    setShowModal(true);
  };

  const handleConfirmExpedite = async () => {
    setJobStatus((prev) => ({ ...prev, finalStatus: "started" }));
    const body = {
      expediteTracking: true,
    };
    expediteIagMutation.mutate(body);
  };

  const handleConfirmTerminate = async () => {
    const body = {
      terminateIagWorkflow: true,
    };
    terminateIagWorkflow.mutate(body);
  };

  const handleCancel = () => {
    setShowModal(false);
  };
  //#endregion confirm modal

  const expediteIagMutation = useMutation({
    mutationFn: async (body: { expediteTracking: boolean }) => {
      const response = await apiRequest<any>("POST", `/api/iagTracking`, body);
      return response;
    },
    onMutate: () => {
      toggleSpinner(true);
    },
    onSuccess: async () => {
      setShowModal(false);
      await iagTrackingQuery.refetch();
      await iagPipeLineStatusQuery.refetch();
      notify("success", "Processing");
    },
    onError: (error) => {
      notify("error", error.message);
      toggleSpinner(false);
    },
    onSettled: () => {
      toggleSpinner(false);
    },
  });

  const terminateIagWorkflow = useMutation({
    mutationFn: async (body: { terminateIagWorkflow: boolean }) => {
      const response = await apiRequest<any>("POST", `/api/iagTracking`, body);
      return response;
    },
    onMutate: () => {
      toggleSpinner(true);
    },
    onSuccess: async (response) => {
      setShowModal(false);
      await iagPipeLineStatusQuery.refetch();
      notify("success", response.message);
    },
    onError: (error: AxiosError) => {
      if (error.response) {
        notify("error", JSON.stringify(error.response.data));
        console.error("Error response:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        notify("error", "No response from the server.");
        console.error("Error request:", error.request);
      }
    },
    onSettled: () => {
      toggleSpinner(false);
    },
  });

  const iagTrackingQuery = useQuery<IagTrackingRow[]>({
    queryKey: ["iagTrackingTable"],
    queryFn: async () => {
      const response = await apiRequest<IagTrackingRow[]>("GET", `/api/iagTracking?value=table`);
      return response;
    },
    // refetchInterval: 60000 * 0.5, // Auto refetch every 30 sec
  });

  const iagPipeLineStatusQuery = useQuery<IagPipeLineStatusRes>({
    queryKey: ["iagPipeLineStatus"],
    queryFn: async () => {
      const response = await apiRequest<IagPipeLineStatusRes>("GET", `/api/iagTracking?value=status`);
      return response;
    },
    refetchInterval: 6000, // Auto refetch every 10 sec
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.fulfillmentName, {
        id: "fulfillmentName",
        cell: (info) => {
          return <div className="text-center">{info.getValue()}</div>;
        },
        header: () => <span>Fullfillment</span>,
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.iagId, {
        id: "iagId",
        cell: (info) => {
          return <div className="text-center">{info.getValue()}</div>;
        },
        header: () => <span>IAG ID</span>,
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.iagName, {
        id: "iagName",
        cell: (info) => {
          return <div className="text-center">{info.getValue()}</div>;
        },
        header: () => <span>IAG Name</span>,
        enableSorting: true,
      }),
      columnHelper.accessor((row) => row.status, {
        id: "status",
        cell: (info) => {
          return <div className="text-center">{info.getValue()}</div>;
        },
        header: () => <span>Status</span>,
        enableSorting: true,
      }),
      columnHelper.accessor((row) => row.expediteTracking, {
        id: "expediteTracking",
        cell: (info) => {
          let isExpedited = info.getValue();
          return (
            <>
              {isExpedited && (
                <div className="text-center">
                  <OverlayTrigger overlay={<Tooltip>Expedited</Tooltip>}>
                    <FontAwesomeIcon icon={faCircleCheck} />
                  </OverlayTrigger>
                </div>
              )}
            </>
          );
        },
        header: () => <span>Expedite Tracking</span>,
        enableSorting: true,
      }),
      // columnHelper.accessor((row) => row.expediteTrackingStatus, {
      //   id: "expediteTrackingStatus",
      //   cell: (info) => {
      //     const expediteId = info.getValue();
      //     return (
      //       <>
      //         {expediteId && (
      //           <div className="text-center">
      //             <OverlayTrigger overlay={<Tooltip>Furture Project</Tooltip>}>
      //               <Button variant="secondary">Expedite Status</Button>
      //             </OverlayTrigger>
      //           </div>
      //         )}
      //       </>
      //     );
      //   },
      //   header: () => <span>Expedite Tracking Status</span>,
      //   enableSorting: false,
      // }),
    ],
    []
  );

  const table = useReactTable({
    data: iagTrackingQuery.data ?? [],
    columns: columns.map((column) => {
      if (column.id === "expediteTracking") {
        return {
          ...column,
          sortingFn: booleanSort,
        };
      }
      return column;
    }),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    enableMultiSort: true,
    enableSorting: true,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: "fuzzy", //apply fuzzy filter to the global filter (most common use case for fuzzy filter)
    filterFns: {
      fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
    },
    state: {
      globalFilter,
      sorting,
    },
    onSortingChange: setSorting,
  });

  //#region useEffect
  useEffect(() => {
    toggleSpinner(iagTrackingQuery.isPending || iagTrackingQuery.isFetching || iagTrackingQuery.isLoading);
  }, [iagTrackingQuery]);

  useEffect(() => {
    const status = iagPipeLineStatusQuery?.data?.status;
    const processingStatus = iagPipeLineStatusQuery?.data?.processingStatus;
    const finalOutput = iagPipeLineStatusQuery?.data?.finalOutput;

    if (status) {
      const isRunning = status === "Running" || status === "Pending";
      const processingPrecentage = getProcessingPercentage(processingStatus ?? "Pending", iagProcessingPercentageMap);
      const finalStatus = finalOutput && jobStatus.finalStatus === "started" ? status : jobStatus.finalStatus;

      // Check if we need to update the state to avoid unnecessary re-renders
      setJobStatus((prev) => {
        if (
          prev.isRunning === isRunning &&
          prev.processingStatus === processingStatus &&
          prev.processingPrecentage === processingPrecentage &&
          prev.finalOutput === finalOutput &&
          prev.finalStatus === finalStatus
        ) {
          return prev; // No need to update, prevent re-render
        }

        return {
          ...prev,
          isRunning,
          processingStatus,
          processingPrecentage,
          finalOutput,
          finalStatus,
        };
      });
    }
  }, [iagPipeLineStatusQuery?.data?.status, iagPipeLineStatusQuery?.data?.processingStatus, iagPipeLineStatusQuery?.data?.finalOutput]);

  useEffect(() => {
    const handleJobStatus = async () => {
      if (jobStatus.finalStatus === "Completed") {
        if (jobStatus.finalOutput.message) {
          try {
            await iagTrackingQuery.refetch();
            notify("success", jobStatus.finalOutput?.message);
          } catch (error) {
            notify("error", `Failed to refetch: ${error.message}`);
          }
        }
        if (jobStatus.finalOutput.error) {
          notify("error", jobStatus.finalOutput.error);
        }
      }

      if (jobStatus.finalStatus === "Failed") {
        notify("error", jobStatus.finalOutput);
      }
    };

    handleJobStatus(); // Call the async function
  }, [jobStatus.finalStatus]);
  //#endregion useEffect

  if (!iagTrackingQuery) return <div>Loading...</div>;

  return (
    <>
      <Container fluid className="p-3 fs-4">
        <Alert variant="warning" className="fs-5">
          <strong>In order to process IAGs please make sure meeting the listed requirements below: </strong>
          <br />
          <strong>*</strong> IAG STATUS: In Transit <br />
          <strong>*</strong> IAG DATE EXPECTED is set <br />
          <strong>*</strong> IAG DEPARTURE DATE is set <br />
          <strong>*</strong> IAG BOL NUMBER is set <br />
          <strong>*</strong> IAG X3PL IAG STATUS: 'Ready to Send'
          <br />
        </Alert>
        <DebouncedInput
          value={globalFilter ?? ""}
          onChange={(value) => setGlobalFilter(String(value))}
          className="fs-4 mb-3 shadow-sm p-3"
          placeholder="Search"
          type="text"
          aria-describedby="search"
        />
        <div className="d-flex mb-3 w-100">
          <div className="d-flex gap-1 align-items-center">
            {jobStatus.isRunning && (
              <Button size="lg" variant="danger" onClick={handleTerminateIags}>
                Terminate IAGs
              </Button>
            )}
            {!jobStatus.isRunning && (
              <Button size="lg" onClick={handleExpediteIags}>
                Expedite IAGs
              </Button>
            )}
            {jobStatus.isRunning && (
              <OverlayTrigger overlay={<Tooltip className="fs-6">Active Expedite Job is running.</Tooltip>}>
                <FontAwesomeIcon icon={faCircleInfo} />
              </OverlayTrigger>
            )}
          </div>
          {jobStatus.isRunning && (
            <OverlayTrigger overlay={<Tooltip className="fs-6">{jobStatus.processingStatus}</Tooltip>}>
              <ProgressBar className="w-100 ms-2 my-auto" animated now={jobStatus.processingPrecentage} />
            </OverlayTrigger>
          )}
        </div>
        <TsTable table={table} />
      </Container>

      <ConfirmModal showModal={showModal} modalMsg={modalMsg} modalCallback={modalCallback} handleCancel={handleCancel} />
    </>
  );
};

export default IagTracking;
