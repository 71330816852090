import { Button, Form, Row, Col } from "react-bootstrap"
import AmazonExportViews from "./amazonPageSpeedViewsXlsxExportComponent";

const AmazonFilterForm = ({
  selectedStartDate, setSelectedStartDate,
  selectedEndDate, setSelectedEndDate,
  showPrime, setShowPrime,
  showBolingbrook, setShowBolingbrook,
  showOversize, setOversize,
  handleApplyFilters, handleClearFilters,
  handleAmazonViewsExport
}) => (
  <Form>
    <Row className="align-items-center">
      <Col sm>
        <Form.Group controlId="startDate" className="mb-3">
          <Form.Label>Start Date</Form.Label>
          <Form.Control
            type="date"
            value={selectedStartDate || ''}
            onChange={(e) => setSelectedStartDate(e.target.value)}
          />
        </Form.Group>
      </Col>
      <Col sm>
        <Form.Group controlId="endDate" className="mb-3">
          <Form.Label>End Date</Form.Label>
          <Form.Control
            type="date"
            value={selectedEndDate || ''}
            onChange={(e) => setSelectedEndDate(e.target.value)}
          />
        </Form.Group>
      </Col>
    </Row>
    <Row className="align-items-center">
      <Col sm>
        <Form.Check
          type="checkbox"
          label="Show Prime Eligible ASINs"
          checked={showPrime}
          onChange={(e) => setShowPrime(e.target.checked)}
        />
      </Col>
      <Col sm>
        <Form.Check
          type="checkbox"
          label="Show Bolingbrook ASINs"
          checked={showBolingbrook}
          onChange={(e) => setShowBolingbrook(e.target.checked)}
        />
      </Col>
      <Col sm>
        <Form.Check
          type="checkbox"
          label="Show Oversize ASINs"
          checked={showOversize}
          onChange={(e) => setOversize(e.target.checked)}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <Button variant="outline-primary" onClick={handleApplyFilters}>Apply Filters</Button>
      </Col>
      <Col>
        <Button variant="outline-primary" onClick={handleClearFilters}>Clear Filters</Button>
      </Col>
      <Col>
        <AmazonExportViews exportFile={handleAmazonViewsExport} />
      </Col>
    </Row>
  </Form>
)

export default AmazonFilterForm;