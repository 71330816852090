import { useMemo } from 'react';
import { Button, Table } from 'react-bootstrap';

interface Props {
  ids: string[]
  salesforceUrl: string
  handleWriteShipDetails: (e) => void
}

export default function ShipDetailResetToPendingInvoice(props: Props){

  function buildShipDetail(id: string){
    return {
      Id: id,
      Invoice_Uploaded_Time__c: null,
      Azure_Status__c: null,
      Shipping_Status_Calc__c: 'Pending Invoice',
    }
  }

  const render = useMemo(() => {
    let tableHeader = undefined
    let rows = undefined
    const newDetails: any[] = []
    if(props.ids && Array.isArray(props.ids) && props.ids.length > 0){
      tableHeader = <thead>
        <tr>
          <th>Id</th>
          <th>Azure_Status__c</th>
          <th>Invoice_Uploaded_Time__c</th>
          <th>Shipping_Status_Calc__c</th>
        </tr>
      </thead>
      rows = props.ids.map((id) => {
        const detail = buildShipDetail(id)
        newDetails.push(detail)
        return <tbody>
          <tr>
            <td>{detail.Id} - <a href={`${props.salesforceUrl}/${detail.Id}`} target="_blank" rel="noopener noreferrer">Salesforce</a></td>
            <td>{detail.Azure_Status__c ?? 'null'}</td>
            <td>{detail.Invoice_Uploaded_Time__c ?? 'null'}</td>
            <td>{detail.Shipping_Status_Calc__c ?? 'null'}</td>
          </tr>
        </tbody>
      })
    }

    return <div>
      <h2>Reset to Pending Invoice</h2>
      <div>This resets the ship detail to be at Pending Invoice so that the invoice can be redelivered to the marketplace.</div>
      {props.ids ? <Button onClick={() => {props.handleWriteShipDetails(newDetails)}}>Confirm Reset</Button> : ''}
      <hr/>
      {props.ids ? <Table striped bordered hover>{tableHeader}{rows}</Table> : ''}
    </div>
  }, [props])
  
  return render
}
