import { useCallback, useEffect, useMemo, useState } from 'react'
import { InteractiveBrowserCredential } from '@azure/identity'
import { TENANT_ID, CLIENT_ID } from '../authConfig'
import { BlobServiceClient, } from '@azure/storage-blob'
import { DATA_SERVICES_BLOBS, DATA_SERVICES_BLOBS_DEV, DATA_SERVICES_TABLES } from '../common/common.constants'
import { Badge, Button, ButtonGroup, Form, Table } from 'react-bootstrap'
import { TableClient } from '@azure/data-tables'
import { blobToString } from '../blobs/blobs.service'
import OrderEditModalComponent from './OrderEditComponent'
import OrderRoutingDebugModalComponent from './OrderRoutingDebugComponent'
import { useSpinner } from '../spinner/SpinnerContext'
import { notify } from '../notify/notify'
import { useUserContext } from '../user/UserContext'

const OrdersComponent = () => {
  const {userData} = useUserContext();
  const isAdmin = userData.roles.includes('admin')
  const { showSpinner, hideSpinner } = useSpinner();
  const credential = new InteractiveBrowserCredential({
    tenantId: TENANT_ID,
    clientId: CLIENT_ID,
    authorityHost: 'https://login.microsoft.com'
  })
  const BASE_MAPPING_ARCHIVE_PATH = 'order-service/mapping/archive/new/'
  const BASE_INBOUND_ARCHIVE_PATH = 'order-service/inbound/archive/new/'

  const [configs, setConfigs] = useState([])
  const [ordersByAccountFiles, setOrdersByAccountFiles] = useState([])
  const [ordersByAccount, setOrdersByAccount] = useState(undefined)
  const [selectedAccountOrders, setSelectedAccountOrders] = useState(undefined)
  const [findValues, setFindValues] = useState(undefined)
  const [devOrdersByAccountFile, setDevOrdersByAccountFiles] = useState([])
  const [devOrdersByAccount, setDevOrdersByAccount] = useState(undefined)
  const [history, setHistory] = useState(undefined)

  const [copyBlobOrderNumber, setCopyBlobOrderNumber] = useState(undefined)
  const [copyBlob, setCopyBlob] = useState(undefined)
  const [routingDebugBlob, setRoutingDebugBlob] = useState(undefined)
  const [routingDebugBlobName, setRoutingDebugBlobName] = useState(undefined)

  const SALESFORCE_PRODUCTION_URL = 'https://nethealthshopsllc.lightning.force.com'
  const SALESFORCE_TESTING_URL = 'https://nethealthshopsllc--testing.sandbox.lightning.force.com'
  const HISTORY_BLOB = 'order-service/crystal-ball-order-move-history.json'
  const WAREHOUSE_ROUTING_PATH = 'order-service/warehouse-routing/debug/'

  useEffect(() => {
    const load = async () => {
      try {
        showSpinner()
        await getOrdersByAccountFiles()
        await getDevOrdersByAccountFiles()
      }catch(error){
        console.log(error)
      }
      hideSpinner()
    }
    const getDevOrdersByAccountFiles = async () => {
      const blobServiceClient = new BlobServiceClient(DATA_SERVICES_BLOBS_DEV, credential)
      const containerClient = blobServiceClient.getContainerClient('storage')
      const listedBlobs = containerClient.listBlobsFlat({prefix: 'order-service/mc-orders/'})
      const accountBlobs = []
      for await(const blob of listedBlobs){
        accountBlobs.push(blob)
      }
      setDevOrdersByAccountFiles(accountBlobs)
    }
    const getOrdersByAccountFiles = async () => {
      const blobServiceClient = new BlobServiceClient(DATA_SERVICES_BLOBS, credential)
      const containerClient = blobServiceClient.getContainerClient('storage')
      const listedBlobs = containerClient.listBlobsFlat({prefix: 'order-service/mc-orders/'})
      const accountBlobs = []
      for await(const blob of listedBlobs){
        accountBlobs.push(blob)
      }
      const tableClient = new TableClient(DATA_SERVICES_TABLES, 'integrationConfiguration', credential)
      const records = tableClient.listEntities()
      const tableRows = []
      for await(const record of records){
        tableRows.push(record)
      }
      setConfigs(tableRows)
      setOrdersByAccountFiles(accountBlobs)
    }
    load()
  }, [])

  useEffect(() => {
    const load = async () => {
      if(!selectedAccountOrders) return
      try {
        showSpinner()
        await getOrdersFromSelectedAccount()
        await getDevOrdersFromSelectedAccount()
      }catch(error){
        console.log(error)
      }
      hideSpinner()
    }
    const getOrdersFromSelectedAccount = async () => {
      setOrdersByAccount(undefined)
      const blobServiceClient = new BlobServiceClient(DATA_SERVICES_BLOBS, credential)
      const containerClient = blobServiceClient.getContainerClient('storage')
      const blockBlobClient = containerClient.getBlockBlobClient(selectedAccountOrders)
      const data = await blockBlobClient.download()
      const downloaded = await blobToString(await data.blobBody);
      setOrdersByAccount(JSON.parse(downloaded))
    }
    const getDevOrdersFromSelectedAccount = async () => {
      setDevOrdersByAccount(undefined)
      const blobServiceClient = new BlobServiceClient(DATA_SERVICES_BLOBS_DEV, credential)
      const containerClient = blobServiceClient.getContainerClient('storage')
      const blockBlobClient = containerClient.getBlockBlobClient(selectedAccountOrders)
      const data = await blockBlobClient.download()
      const downloaded = await blobToString(await data.blobBody);
      setDevOrdersByAccount(JSON.parse(downloaded))
    }
    load()
  }, [selectedAccountOrders])

  const handleOrderAccountClick = useCallback((e) => {
    setSelectedAccountOrders(e.target.id)
  }, [])

  const orderAccounts = useMemo(() => {
    if(!configs) return

    const accountsWithBadges = []
    const accountsWithoutBadges = []
    for(const order of ordersByAccountFiles){
      const justBlobName = order.name.split('/').pop()
      const splitBlobName = justBlobName.split('___')
      const accountId = splitBlobName[1].replace('.json', '')
      const config = configs.find(c => c.accountId === accountId);

      if(config?.forceEauClaire === true){
        accountsWithBadges.push(<div key={order.name} style={{ margin: '3px' }}>
          <Button variant='dark' key={order.name} onClick={handleOrderAccountClick} id={order.name}>
            {decodeURIComponent(splitBlobName[0]).toUpperCase()}
            <br/><Badge bg='warning' style={{color: 'black'}} onClick={handleOrderAccountClick} id={order.name}>Force EC</Badge>
          </Button>
        </div>)
      } else {
        accountsWithoutBadges.push(<div key={order.name} style={{ margin: '3px' }}>
          <Button variant='dark' key={order.name} onClick={handleOrderAccountClick} id={order.name}>
            {decodeURIComponent(splitBlobName[0]).toUpperCase()}
          </Button>
        </div>)
      }
    }  
    return (
      <>
        <h3>Order Accounts</h3>
          <ButtonGroup style={{ display: 'flex', flexWrap: 'wrap' }}>
            {accountsWithBadges}
          </ButtonGroup>
          <ButtonGroup style={{ display: 'flex', flexWrap: 'wrap' }}>
            {accountsWithoutBadges}
          </ButtonGroup>
        </>
    );
  }, [ordersByAccountFiles, configs]);
  
  const handleSandboxMappingClick = useCallback((e) => {
    if(!configs) return
    const copyOrder = async () => {
      try {
        const idSplit = e.target.id.split('___')
        const orderNumber = idSplit[1]
        const accountId = idSplit[2]
        const config = configs.find(c => c.accountId === accountId)
        const paths = []
        if(config?.partitionKey === 'Wayfair'){
          paths.push(`${BASE_MAPPING_ARCHIVE_PATH.replace('new/', `new/wayfair/WAYU/${orderNumber}`)}`)
          paths.push(`${BASE_MAPPING_ARCHIVE_PATH.replace('new/', `new/castlegate/WAYU/${orderNumber}`)}`)
          paths.push(`${BASE_MAPPING_ARCHIVE_PATH.replace('new/', `new/wayfair/WAYUCA/${orderNumber}`)}`)
          paths.push(`${BASE_MAPPING_ARCHIVE_PATH.replace('new/', `new/castlegate/WAYU/${orderNumber}`)}`)
        } else if(!config) {
          paths.push(`${BASE_MAPPING_ARCHIVE_PATH.replace('new/', `new/chad/${orderNumber}`)}`)
        } else {
          paths.push(`${BASE_MAPPING_ARCHIVE_PATH.replace('new/', `${config.newOrdersChannelFolder}/${orderNumber}`)}`)
        }

        const prodBlobServiceClient = new BlobServiceClient(DATA_SERVICES_BLOBS, credential)
        const prodContainerClient = prodBlobServiceClient.getContainerClient('storage')
        const foundBlobs = []
        for(const path of paths){
          showSpinner()
          const blobs = prodContainerClient.listBlobsFlat({prefix: path})
          for await (const blob of blobs){
            foundBlobs.push(blob)
            break
          }
        }
        if(foundBlobs.length === 0 || foundBlobs.length > 1){
          hideSpinner()
          notify('error', 'Failed to find mapping blob!')
          return
        }
        showSpinner()
  
        const blockBlobClient = prodContainerClient.getBlockBlobClient(foundBlobs[0].name)
        const data = await blockBlobClient.download()
        const downloaded = await blobToString(await data.blobBody);
        setCopyBlobOrderNumber(foundBlobs[0].name)
        setCopyBlob(JSON.stringify(JSON.parse(downloaded), null, 2))
      }catch(error){
        console.log(error)
      }
      hideSpinner()
    }
    copyOrder()
    
  }, [configs])

  const handleBlobModalClose = useCallback((e) => {
    setCopyBlob(undefined)
  }, [])

  const handleSandboxRoutingDebugClick = useCallback((e) => {
    const load = async () => {
      try {
        const orderNumber = e.target.id.split('___')[1]

        showSpinner()
        const blobServiceClient = new BlobServiceClient(DATA_SERVICES_BLOBS_DEV, credential)
        const containerClient = blobServiceClient.getContainerClient('storage')
        const blobs = containerClient.listBlobsFlat({prefix: `${WAREHOUSE_ROUTING_PATH}${orderNumber}`})
        const foundBlobs = []
        for await (const blob of blobs){
          foundBlobs.push(blob)
        }
        if(foundBlobs.length === 0){
          notify('error', `Could not find routing debug for ${orderNumber}!`)
          hideSpinner()
          return
        } else {
          notify('success', `Found ${foundBlobs.map(b => b.name).join(',')}`)
          const blockBlobClient = containerClient.getBlockBlobClient(foundBlobs[0].name)
          const data = await blockBlobClient.download()
          const downloaded = await blobToString(await data.blobBody);
          setRoutingDebugBlob(downloaded)
          setRoutingDebugBlobName(foundBlobs[0].name)
        }
      }catch(error){
        console.log(error)
      }
      hideSpinner()
      
    }
    load()
  }, [])

  const handleSandboxDeleteOrderClick = useCallback((e) => {
    const deleteOrder = async () => {
      const orderId = e.target.id.split('___')[1]
      const orderNumber = e.target.id.split('___')[2]
      try {
  
        showSpinner()
        const blobServiceClient = new BlobServiceClient(DATA_SERVICES_BLOBS_DEV, credential)
        const containerClient = blobServiceClient.getContainerClient('storage')
        const path = `order-service/delete/pending/${orderNumber}___${orderId}.json`
        const content = JSON.stringify({Id: orderId})
        const blobClient = containerClient.getBlockBlobClient(path)
        await blobClient.upload(content, content.length)
        notify('success', `Created sandbox order delete request for ${path}`)
      }catch(error){
        notify('error', `Could create sandbox order delete request for ${orderNumber}!`)
        console.log(error)
      }
      hideSpinner()
    }
    deleteOrder()
  }, [])

  const handleProductionRoutingDebugClick = useCallback((e) => {
    const load = async () => {
      try {
        const orderNumber = e.target.id.split('___')[1]

        showSpinner()
        const blobServiceClient = new BlobServiceClient(DATA_SERVICES_BLOBS, credential)
        const containerClient = blobServiceClient.getContainerClient('storage')
        const blobs = containerClient.listBlobsFlat({prefix: `${WAREHOUSE_ROUTING_PATH}${orderNumber}`})
        const foundBlobs = []
        for await (const blob of blobs){
          foundBlobs.push(blob)
        }
        if(foundBlobs.length === 0){
          notify('error', `Could not find routing debug for ${orderNumber}!`)
          hideSpinner()
          return
        } else {
          notify('success', `Found ${foundBlobs.map(b => b.name).join(',')}`)
          const blockBlobClient = containerClient.getBlockBlobClient(foundBlobs[0].name)
          const data = await blockBlobClient.download()
          const downloaded = await blobToString(await data.blobBody);
          setRoutingDebugBlob(downloaded)
          setRoutingDebugBlobName(foundBlobs[0].name)
        }
      }catch(error){
        console.log(error)
      }
      hideSpinner()
      
    }
    load()
  }, [])

  function lookupItemIncludesItemFields(item, lookupItems) {
    return (
      lookupItems?.includes(item.Product2?.MPN__c?.toLowerCase()) || 
      lookupItems?.includes(item.Order_Number__c?.toLowerCase()) ||
      lookupItems?.includes(item.Shipping_Detail__r?.Fulfilled_by_Software__c?.toLowerCase()) ||
      lookupItems?.includes(item.Order?.Distribution_Center_Name__c?.toLowerCase()) ||
      lookupItems?.includes(item.Shipping_Detail__r?.Shipping_Status__c?.toLowerCase()) || // Added closing parenthesis here
      lookupItems?.includes(item.Order?.ShippingState?.toLowerCase())
    )
  }
  
  function generateTable(orderItems: any[], salesforceUrl: string){
    return (
      <>
      
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Salesforce Order Item</th>
                  <th>Product Report Category</th>
                  <th>Product MPN</th>
                  <th>Ships LTL</th>
                  <th>Is Drop Ship</th>
                  <th>Is Replacement</th>
                  <th>Is Bundle</th>
                  <th>Is PO Box</th>
                  <th>Fulfillment Warehouse Name</th>
                  <th>Master Order Product</th>
                  <th>Quantity</th>
                  <th>Shipping Country</th>
                  <th>Shipping State</th>
                  <th>Order Status</th>
                  <th title='Shipping Detail'>Shipping Status</th>
                  <th title='Shipping Detail'>Shipping Status Calc</th>
                  <th title='Shipping Detail'>Azure Status</th>
                  <th title='Shipping Detail'>Batch Name</th>
                  <th title='Shipping Detail'>Fulfilled by Software</th>
                  <th>Distribution ID</th>
                  <th>Distribution Center Name</th>
                  <th>Custom Order Data</th>
                  <th>Custom Order Item Data</th>
                </tr>
              </thead>
              <tbody>
                {orderItems?.map((orderItem) => (
                  <tr key={`${orderItem.Id}`}>
                    <td key={`id_${orderItem.Id}`}><a title={`${salesforceUrl}/${orderItem.Id}`} href={`${salesforceUrl}/${orderItem.Id}`}>Link</a></td>
                    <td key={`reportCategory_${orderItem.Id}`}>{orderItem.Product2?.Report_Category__r?.Name}</td>
                    <td key={`mpn_${orderItem.Id}`}>{orderItem.Product2?.MPN__c}</td>
                    <td key={`shipsLtl_${orderItem.Id}`}>{!orderItem.Product2?.Ships_LTL__c ? 'No' : 'Yes'}</td>
                    <td key={`shipDetailisDropShip_${orderItem.Id}`}>{!orderItem.is_Drop_Ship__c ? 'No' : 'Yes'}</td>
                    <td key={`shipDetailIsReplacement_${orderItem.Id}`}>{!orderItem.is_Replacement__c ? 'No' : 'Yes'}</td>
                    <td key={`shipDetailIsBundle_${orderItem.Id}`}>{!orderItem.isBundle__c ? 'No' : 'Yes'}</td>
                    <td key={`shipDetailIsPOBox_${orderItem.Id}`}>{!orderItem.Shipping_Detail__r?.Is_PO_Box__c ? 'No' : 'Yes'}</td>
                    <td key={`warehouseName_${orderItem.Id}`}>{orderItem.Fulfillment_Warehouse__r?.Name}</td>
                    <td key={`masterOrderProduct_${orderItem.Id}`}>{!orderItem.Master_Order_Product__c ? '' : <a title={`${salesforceUrl}/${orderItem.Master_Order_Product__c}`} href={`${salesforceUrl}/${orderItem.Master_Order_Product__c}`}>Link</a>}</td>
                    <td key={`quantity_${orderItem.Id}`}>{orderItem.Quantity}</td>
                    <td key={`shippingCountry_${orderItem.Id}`}>{orderItem.Order?.ShippingCountry}</td>
                    <td key={`shippingState_${orderItem.Id}`}>{orderItem.Order?.ShippingState}</td>
                    <td key={`orderStatus_${orderItem.Id}`}>{orderItem.Order?.Status__c}</td>
                    <td key={`shipDetailShippingStatus_${orderItem.Id}`}>{orderItem.Shipping_Detail__r?.Shipping_Status__c}</td>
                    <td key={`shipDetailShippingStatusCalc_${orderItem.Id}`}>{orderItem.Shipping_Detail__r?.Shipping_Status_Calc__c}</td>
                    <td key={`shipDetailAzureStatus_${orderItem.Id}`}>{orderItem.Shipping_Detail__r?.Azure_Status__c}</td>
                    <td key={`shipDetailBatchName_${orderItem.Id}`}>{orderItem.Shipping_Detail__r?.BatchName__c}</td>
                    <td key={`shipDetailFulfilledBySoftware_${orderItem.Id}`}>{orderItem.Shipping_Detail__r?.Fulfilled_by_Software__c}</td>
                    <td key={`distributionId_${orderItem.Id}`}>{orderItem.Order?.Distribution_ID__c}</td>
                    <td key={`distributionCenterName_${orderItem.Id}`}>{orderItem.Order?.Distribution_Center_Name__c}</td>
                    <td key={`shipDetailCustomOrderData_${orderItem.Id}`}>{orderItem.Order?.SYS_CustomOrderData__c}</td>
                    <td key={`shipDetailCustomOrderItemData_${orderItem.Id}`}>{orderItem.SYS_CustomOrderItemData__c}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
      </>
    )
  }

  const ordersList = useMemo(() => {
    if (!ordersByAccount) return (undefined);
    const lookupItems = findValues?.split(',').map(s => s.trim().toLowerCase())

    // Group orders by order number
    const orderHiddenMap: Map<string, boolean> = new Map()
    const orderMap: Map<string, any[]> = new Map()
    const orderDevMap: Map<string, any[]> = new Map()

    for(const orderItem of ordersByAccount){
      if(!orderMap.has(orderItem.Order_Number__c)){
        orderMap.set(orderItem.Order_Number__c, [])
      }
      orderMap.get(orderItem.Order_Number__c).push(orderItem)
    }
    for(const devOrderItem of devOrdersByAccount ?? []){
      if(!orderDevMap.has(devOrderItem.Order_Number__c)){
        orderDevMap.set(devOrderItem.Order_Number__c, [])
      }
      orderDevMap.get(devOrderItem.Order_Number__c).push(devOrderItem)
    }

  // Iterate through orderMap keys
  for (const [orderNumber, orderItems] of orderMap) {
    let orderHidden = false; // Assume hidden until proven otherwise
    if(!lookupItems || lookupItems?.length === 0) break

    const prodHasSome = orderItems.some(oi => lookupItemIncludesItemFields(oi, lookupItems))
    const devHasSome = orderDevMap?.get(orderItems[0].Order_Number__c)?.some(oi => lookupItemIncludesItemFields(oi, lookupItems))

    if(!prodHasSome && !devHasSome){
      orderHidden = true
    }

    // Populate orderHiddenMap with the order number and orderHidden value
    orderHiddenMap.set(orderNumber, orderHidden);
  }

  return (
    <>
    <hr />
    <h3>
      <a
        title={`${SALESFORCE_PRODUCTION_URL}/${ordersByAccount[0].Order?.Account?.Id}`}
        href={`${SALESFORCE_PRODUCTION_URL}/${ordersByAccount[0].Order?.Account?.Id}`}
      >
        {ordersByAccount[0].Order?.Account?.Name}
      </a>
    </h3>
    <p>
      <strong>{orderMap.size} order(s)</strong> {ordersByAccount.length} order item(s) in Production Mission Control
    </p>
    <p>
      <strong>{orderDevMap.size} order(s)</strong> {devOrdersByAccount?.length ?? 0} order item(s) in Sandbox Mission Control
    </p>

    {[...orderMap.values()].map((orderItems: any[], index) => (
      <div
        hidden={orderHiddenMap.get(orderItems[0].Order_Number__c)}
        key={index}
        style={{
          border: '1px solid #f2f2f2',
          marginBottom: '10px',
          borderRadius: '3px',
          padding: '5px',
        }}
      >
        <h4>
          Order # {orderItems[0].Order_Number__c}
        </h4>
        <div className="mb-3" hidden={!isAdmin}>
              <ButtonGroup>
                <Button onClick={handleSandboxMappingClick} id={`toSandbox___${orderItems[0].Order_Number__c}___${orderItems[0].Order?.Account?.Id}`} title='Modify, and or, send production blob to Sandbox mapping.'>Send to Sandbox Mapping</Button>
                <Button variant='success' onClick={handleProductionRoutingDebugClick} id={`productionRouting___${orderItems[0].Order_Number__c}___${orderItems[0].Order?.Account?.Id}`} title='View latest production routing results.'>Production Routing Debug</Button>
              </ButtonGroup>
            </div>
        <div>
        {new Date(orderItems[0].Order?.CreatedDate).toLocaleString()}
          <br/>
          <a
            title={`${SALESFORCE_PRODUCTION_URL}/${orderItems[0].Order?.Id}`}
            href={`${SALESFORCE_PRODUCTION_URL}/${orderItems[0].Order?.Id}`}
          >
            Production Order
          </a>
          {orderDevMap.get(orderItems[0]?.Order_Number__c)?.length > 0 ? 
          <div>
          <a
            title={`${SALESFORCE_TESTING_URL}/${orderDevMap.get(orderItems[0]?.Order_Number__c)[0].Order?.Id}`}
            href={`${SALESFORCE_TESTING_URL}/${orderDevMap.get(orderItems[0]?.Order_Number__c)[0].Order?.Id}`}
          >
          Sandbox Order
          </a>
          </div>
        : ''}
        </div>
        <h4>Production Order Items</h4>
        {generateTable(orderItems, SALESFORCE_PRODUCTION_URL)}
        
        {orderDevMap.get(orderItems[0]?.Order_Number__c)?.length > 0 ? 
          <div>
            <h4>Sandbox Order Items</h4>
          <ButtonGroup>
            <Button variant='warning' onClick={handleSandboxRoutingDebugClick} id={`sandboxRouting___${orderItems[0].Order_Number__c}___${orderItems[0].Order?.Account?.Id}`} title='View latest sandbox routing results.'>Sandbox Routing Debug</Button>
          <Button variant='danger' onClick={handleSandboxDeleteOrderClick} id={`sandboxDelete___${orderDevMap.get(orderItems[0]?.Order_Number__c)[0].Order.Id}___${orderDevMap.get(orderItems[0]?.Order_Number__c)[0].Order_Number__c}`} title='Deletes an order in sandbox within 2 minutes.'>Sandbox Order Delete</Button>

          </ButtonGroup>

          {generateTable(orderDevMap.get(orderItems[0]?.Order_Number__c), SALESFORCE_TESTING_URL)} 

          </div>
          : ''}
      </div>
    ))}
    </>
    )
  }, [ordersByAccount, findValues, devOrdersByAccount]);

  const handleFindValuesChanged = useCallback((e) => {
    setFindValues(e.target.value.trim() === '' ? undefined : e.target.value)
  }, [])
  
  const findSkusView = useMemo(() => {
    return (
      <>
        <Form.Control onChange={handleFindValuesChanged} id='findSkus' placeholder='HH-MHS-Black, HH-MHS-Blue, ShipSage Allentown, ShipSage, Shipstation, DesktopShipper'></Form.Control>
        <Form.Label htmlFor='findSkus'>Find SKU(s) / Order Number(s)</Form.Label>
      </>
    )
  }, [])

  const handleRoutingBlobModalClose = useCallback((e) => {
    setRoutingDebugBlob(undefined)
    setRoutingDebugBlobName(undefined)
  }, [])

  const playSound = () => {
    const audio = new Audio('385818__tec_studio__ominous_horn.wav');
    audio.play();
  };

  const view = useMemo(() => {
    return (
      <>
      <OrderRoutingDebugModalComponent 
        blobData={routingDebugBlob} 
        blobName={routingDebugBlobName} 
        closeModal={handleRoutingBlobModalClose} 
        modalVisible={typeof routingDebugBlob !== 'undefined'} 
        />
      <OrderEditModalComponent 
        devBlobServiceClient={new BlobServiceClient(DATA_SERVICES_BLOBS_DEV, credential)}
        blobData={copyBlob} blobName={copyBlobOrderNumber} closeModal={handleBlobModalClose}
        modalVisible={typeof copyBlob !== 'undefined'}
        />
      <div style={{padding: '5px', textAlign: 'center'}}>
      {findSkusView}
      {orderAccounts}
      {ordersList}
      <img onClick={playSound} style={{borderRadius: '5px'}} hidden={typeof ordersList !== 'undefined'} src='bifrost.png'></img>

      </div>
      </>
    )
  }, [orderAccounts, ordersList, findSkusView, copyBlob, copyBlobOrderNumber, routingDebugBlob, routingDebugBlobName])

  return view
}

export default OrdersComponent