import { useMemo } from 'react'
import { Modal, Button } from 'react-bootstrap'

interface DesktopShipperBatchRunsModalComponentProperties {
  modalVisible: boolean
  closeModal: any
  records: any[]
}

const DesktopShipperBatchRunsModalComponent = (props: DesktopShipperBatchRunsModalComponentProperties) => {
  const display = useMemo(() => {
    if(typeof props.records === 'undefined') return

    const runsMap = new Map()
    for(const record of props.records){
      if(!runsMap.has(record.partitionKey)){
        runsMap.set(record.partitionKey, [])
      }
      runsMap.get(record.partitionKey).push({runTime: decodeURIComponent(record.rowKey), lastRunTime: record.lastRunTime})
    }

    const divs = []
    for(const key of runsMap.keys()){
      const runTimes = runsMap.get(key)
      const theDiv = []
      theDiv.push(<div><strong>{key}</strong></div>)
      divs.push(theDiv)
      for(const time of runTimes){
        divs.push(
          <div key={`${key}_${time.runTime}`}><strong>{decodeURIComponent(time.runTime)}</strong> - Last Ran: {time.lastRunTime}</div>
        )
      }

    }
    return divs
  }, [props.records])
  return(
    <Modal show={props.modalVisible} animation={false} onHide={props.closeModal} className='modal-s'>        
      <Modal.Body>
        <div style={{textAlign: 'center'}}>{display}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='danger' onClick={props.closeModal}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DesktopShipperBatchRunsModalComponent
