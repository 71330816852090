import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Table } from 'react-bootstrap';

interface Props {
  ids: string[]
  salesforceUrl: string
  handleWriteShipDetails: (e) => void
}

export default function ShipDetailResetToPendingSoftware(props: Props){
  function buildShipDetail(id: string){
    return {
      Id: id,
      Shipment_Time__c: null,
      Shipment_Sent_Time__c: null,
      ShipStation_Order_Id__c: null,
      Shipment_Uploaded_Time__c: null,
      Invoice_Uploaded_Time__c: null,
      Azure_Status__c: null,
      BatchName__c: null,
      Shipping_Status_Calc__c: 'Pending',
      Fulfilled_By_Software__c: null
    }
  }

  const render = useMemo(() => {
    let tableHeader = undefined
    let rows = undefined
    const newDetails: any[] = []
    if(props.ids && Array.isArray(props.ids) && props.ids.length > 0){
      tableHeader = <thead>
        <tr>
          <th>Id</th>
          <th>Azure_Status__c</th>
          <th>BatchName__c</th>
          <th>ShipStation_Order_Id__c</th>
          <th>Fulfilled_By_Software__c</th>
          <th>Invoice_Uploaded_Time__c</th>
          <th>Shipment_Uploaded_Time__c</th>
          <th>Shipment_Sent_Time__c</th>
          <th>Shipment_Time__c</th>
          <th>Shipping_Status_Calc__c</th>
        </tr>
      </thead>
      rows = props.ids.map((id) => {
        const detail = buildShipDetail(id)
        newDetails.push(detail)
        return <tbody>
          <tr>
            <td>{detail.Id} - <a href={`${props.salesforceUrl}/${detail.Id}`} target="_blank" rel="noopener noreferrer">Salesforce</a></td>
            <td>{detail.Azure_Status__c ?? 'null'}</td>
            <td>{detail.BatchName__c ?? 'null'}</td>
            <td>{detail.ShipStation_Order_Id__c ?? 'null'}</td>
            <td>{detail.Fulfilled_By_Software__c ?? 'null'}</td>
            <td>{detail.Invoice_Uploaded_Time__c ?? 'null'}</td>
            <td>{detail.Shipment_Uploaded_Time__c ?? 'null'}</td>
            <td>{detail.Shipment_Sent_Time__c ?? 'null'}</td>
            <td>{detail.Shipment_Time__c ?? 'null'}</td>
            <td>{detail.Shipping_Status_Calc__c ?? 'null'}</td>
          </tr>
        </tbody>
      })
    }

    return <div>
      <h2>Reset to Pending Software</h2>
      <div>This resets the ship detail back to the beginning where it will have its fulfillment software redetermined.</div>
      {props.ids ? <Button onClick={() => {props.handleWriteShipDetails(newDetails)}}>Confirm Reset</Button> : ''}
      <hr/>
      {props.ids ? <Table striped bordered hover>{tableHeader}{rows}</Table> : ''}
    </div>
  }, [props])
  
  return render
}
