import { useQuery } from "@tanstack/react-query"
import { apiRequest } from "../../utility/axios"
import { DateTime } from "luxon"
import { PrimeSpeedRow } from "../../common/common.types"

const useAmazonPageSpeedViewsRollingData = () => {

  const now = DateTime.local().toUTC().toFormat('yyyy-MM-dd')
  const last30days = DateTime.fromISO(now).minus({days: 30}).toFormat('yyyy-MM-dd')

  const {isLoading: isLoadingRolling, isPending: isPendingRolling, error: rollingError, data: rollingdata, isFetching: isFetchingRolling, refetch: refetchRolling } = useQuery<PrimeSpeedRow[]>({
    queryKey: ["RollingRows", last30days, now],
    queryFn: async (): Promise<PrimeSpeedRow[]> => {      
      const response = await apiRequest("GET", `/api/amazonPageSpeedViews`, null, {params: {startDate: last30days, endDate: now}})
      return response  as PrimeSpeedRow[]
    },
  })

  return {
    isLoadingRolling,
    isPendingRolling,
    rollingError,
    rollingdata,
    isFetchingRolling,
    refetchRolling
  }
}

export default useAmazonPageSpeedViewsRollingData