import { useCallback, useMemo, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

interface Props {
  modalVisible: boolean
  closeModal: () => void
  setIds: (ids: string[]) => void
}

export default function ResetDataInputComponent(props: Props) {
  const [ids, setIds] = useState(undefined)
  
  const handleDataChanged = useCallback((e) => {
    if(e.target.value.trim() === '') return
    const contentSplit = e.target.value.split('\n')
    const newIds = []
    for(const content of contentSplit){
      if(content.trim() === '') continue
      newIds.push(content)
    }
    setIds(newIds)
  }, [])

  const handleSaveClicked = useCallback((e) => {
    if(!ids) return
    props.setIds(ids)
    props.closeModal()
    setIds(undefined)
  }, [ids])

  const handleCloseClicked = useCallback((e) => {
    props.closeModal()
  }, [])

  const render = useMemo(() => {
    return <Modal show={props.modalVisible} animation={false} onHide={props.closeModal} className='modal-s'>
      <Modal.Body>
        Add IDs separated by a new line.
        <Form.Control as='textarea' placeholder='Paste IDs...' rows={10} onChange={handleDataChanged}></Form.Control>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='success' onClick={handleSaveClicked}>Save [{ids?.length ?? 0}] IDs</Button>
        <Button onClick={handleCloseClicked}>Close</Button>
      </Modal.Footer>
    </Modal>
  }, [props, ids, handleDataChanged, handleSaveClicked, handleCloseClicked])

  return render
}