export interface CarrierInvoiceAuditSave {
  invoices: CarrierInvoice[]
  totals: WarehouseTotal[]
}

export interface CostAuditProduct {
  mpn: string
  fedexBudget: number
  upsBudget: number
}

export interface CarrierDispute {
  id: string
  reference: string
  invoiceDate: string
}

export interface CarrierInvoiceShipDetail {
  Id: string
  SalesforceUrl?: string
  Order_Number__c: string
  Tracking_Number__c: string
  Shipping_Carrier__c: string
  Shipping_Class__c: string
  Shipping_Cost__c: number
  Shipment_Time__c: string
  Shipment_Sent_Time__c: string
  shipmentSentTimeString?: string
  Shipment_Uploaded_Time__c: string
  Shipment_Delivered_Date__c: string
  Shipping_State__c: string
  Order__r: Order__r
  Order_Products__r: OrderProduct[]
  Warehouse__r: Warehouse__r
  budgetTotal: number
}

export interface Warehouse__r {
  Name: string
}

interface Order__r {
  Order_Time__c: string
  orderTimeString?: string
}

interface OrderProduct {
  Id: string
  Product_MPN__c: string
  Quantity: number
  costAuditProduct?: CostAuditProduct
  mcBudget?: string
}

export const CarrierInvoiceTableHeaders = [
  'Order Number',
  'Invoice Number',
  'Invoice Tracking Number',
  'Invoice Account',
  'Invoice Products',
  'Invoice Date',
  'Invoice Net Due',
  'MC Ship Cost',
  'MC Budget',
  'MC Budget Detail',
  'MC Warehouse',
  'MC Ship To',
  'MC Carrier',
  'MC Tracking Number',
  'MC Order Date',
  'MC Ship Detail'
]

export interface CarrierInvoice {
  Account: string
  InvoiceNumber: string
  InvoiceDate: string
  TrackingNumber: string
  OrderNumber: string
  Products: string
  NetDue: string
  NetDueNumber: number
  ShipDetail?: CarrierInvoiceShipDetail
  differenceResult?: string
  differenceValue?: string
  differenceType?: string
}

export interface GroupedCarrierInvoice {
  Account: string
  InvoiceNumber: string
  InvoiceDate: string
  TrackingNumbers: string[]
  InvoiceNumbers: string[]
  OrderNumber: string
  Products: string
  NetDue: string
  NetDueNumber: number
  ShipDetail?: CarrierInvoiceShipDetail
  differenceResult?: string
  numberOfInvoices?: number
}

export interface CarrierInvoiceExport {
  'Order Number': string
  'Invoice Number': string
  'Tracking Number': string
  'Net Due': number
  'Dispute Reference'?: string
  'Order Products': string
}

export interface WarehouseTotal {
  warehouseName: string
  productBudgetTotal: number
  invoiceTotal: number
  difference?: number
}

export interface InvoiceMap {
  invoices: CarrierInvoice[]
}