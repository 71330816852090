import { Card, Nav } from 'react-bootstrap'
import { useUserContext } from './user/UserContext'
import React from 'react';
import { Nav_Item } from './layout/interfaces';

interface HomeComponentProps {
  navList: Nav_Item[];
}

const HomeComponent: React.FC<HomeComponentProps> = ( {navList} ) => {
  const {userData} = useUserContext();
  const roles = userData?.roles
  const userName = userData?.friendlyUserName

  return (
    <>
      <div className='d-flex justify-content-center'>
        <h2>Welcome {userName}</h2>
      </div>
      {roles && navList.map((item, index) => {
        const hasAccess = roles.some(element => item.userLevel.includes(element))
        const show = item.userLevel.length === 0 || hasAccess
        if (show) {
          return (
            <React.Fragment key={index}>
              <Card>
                <Card.Header>
                  <Card.Title>
                    <Nav.Link href={item.link} style={{ fontWeight: 'bold', color: 'blue' }}>
                      {item.displayName}
                    </Nav.Link>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  {item.description}
                </Card.Body>
              </Card>
              <br />
            </React.Fragment>
          );
        }
        return null
      })}

      {roles && roles.length === 0 && 
        <div>An admin needs to update your roles to view this site. Please let them know you are waiting for access.</div>
      }

    </>
  )
}

export default HomeComponent
