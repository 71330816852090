import { useMemo } from 'react'
import { Modal, Button } from 'react-bootstrap'

interface DesktopShipperErrorModalComponentProperties {
  modalVisible: boolean
  closeModal: any
  record: any
  salesforceUrl: string
}

const DesktopShipperErrorModalComponent = (props: DesktopShipperErrorModalComponentProperties) => {
  const display = useMemo(() => {
    if(typeof props.record === 'undefined') return
    const divs = []
    divs.push(<div>Ship Detail: <a href={`${props.salesforceUrl}/${props.record.rowKey}`}>{props.record.rowKey}</a></div>)
    divs.push(<div>Order Number: {props.record.shipDetailOrderNumber}</div>)
    divs.push(<div>DesktopShipper Order ID: {props.record.destktopShipperOrderId}</div>)
    divs.push(<div>DesktopShipper Batch ID: {props.record.desktopShipperBatchId}</div>)
    divs.push(<div style={{color: 'red'}}>{props.record.error}</div>)

    return divs
  }, [props])

  return(
    <Modal show={props.modalVisible} animation={false} onHide={props.closeModal} className='modal-s'>
      <Modal.Body>
        <div style={{textAlign: 'center', fontSize: '2em'}}>{display}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='danger' onClick={props.closeModal}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default DesktopShipperErrorModalComponent
