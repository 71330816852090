import { useCallback, useMemo, useState } from 'react'
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { SalsifyLocalizationsTableRow } from './salsify.interfaces'
import { CommonProperties } from '../common/common.interfaces'
import { DateTime } from 'luxon'
import { notify } from '../notify/notify'

interface SalsifyLocalizationsComponentProperties extends CommonProperties {
  localizations: SalsifyLocalizationsTableRow[]
  salsifyLocalizationsTableService: any
  setLastRefresh: any
}

const SalsifyLocalizationsComponent = (props: SalsifyLocalizationsComponentProperties) => {
  const [newLocalizationPartitionKey, setNewLocalizationPartitionKey] = useState(undefined)
  const [newLocalizationRowKey, setNewLocalizationRowKey] = useState(undefined)
  const [newLocalizationPartitionKeyError, setNewSuffixPartitionKeyError] = useState(undefined)
  const [newLocalizationRowKeyError, setNewLocalizationRowKeyError] = useState(undefined)
  
  const handleNewLocalizationPartitionKeyChange = useCallback((e) => {
    setNewLocalizationPartitionKey(e.target.value)
  }, [props.localizations])

  const handleNewLocalizationRowKeyChange = useCallback((e) => {
    const foundSuffix = props.localizations.find(s => s.rowKey.toLowerCase() === e.target.value.toLowerCase() && s.partitionKey.toLowerCase() === newLocalizationPartitionKey.toLowerCase())
    if(typeof foundSuffix !== 'undefined'){
      setNewLocalizationRowKeyError('This Localization mapping already exists!')
    } else {
      setNewLocalizationRowKeyError(undefined)
    }
    setNewLocalizationRowKey(e.target.value)
  }, [props.localizations, newLocalizationPartitionKey])

  const handleSaveNewLocalization = useCallback((e) => {
    const saveSuffix = async () => {
      try {
        await props.salsifyLocalizationsTableService.upsertEntity({partitionKey: newLocalizationPartitionKey, rowKey: newLocalizationRowKey}, 'Replace')
        notify('success', `${newLocalizationPartitionKey} ${newLocalizationRowKey} saved successfully!`)
        props.setLastRefresh(DateTime.utc().toMillis())
      }catch(error){
        notify('error', `Failed to save ${newLocalizationPartitionKey} ${newLocalizationRowKey}! ${error}`)
        console.log(error)
      }
    }
    saveSuffix()
  }, [newLocalizationPartitionKey, newLocalizationRowKey])

  const handleDeleteLocalization = useCallback((e) => {
    const deleteSuffix = async () => {
      if(confirm('Are you sure you wish to permanently delete this mapping?')){
        const splitId = e.target.id.split('|')
        const pKey = splitId[0]
        const rKey = splitId[1]
        try {
          await props.salsifyLocalizationsTableService.deleteEntity(pKey, rKey)
          notify('success', `${pKey} ${rKey} deleted successfully!`)
          props.setLastRefresh(DateTime.utc().toMillis())
        }catch(error){
          notify('error', `Failed to delete ${pKey} ${rKey}! ${error}`)
          console.log(error)
        }
      }
    }
    deleteSuffix()
  }, [])
  
  const buildLocalizations = useMemo(() => {
    const tr = []
    for(const record of props.localizations){
      tr.push(
        <tr>
          <td>{record.partitionKey}</td>
          <td>{record.rowKey}</td>
          <td><Button onClick={handleDeleteLocalization} variant='danger' id={`${record.partitionKey}|${record.rowKey}`}>Delete</Button></td>
        </tr>
      )
    }
    const saveEnabled =
      typeof newLocalizationPartitionKey !== 'undefined' && newLocalizationPartitionKey.trim().length > 0 &&
      typeof newLocalizationRowKey !== 'undefined' && newLocalizationRowKey.trim().length > 0 &&
      typeof newLocalizationPartitionKeyError === 'undefined' && 
      typeof newLocalizationRowKeyError === 'undefined'
    return (
      <>
      <div>
        <Container>
          <Row>
            <Col><Form.Control onChange={handleNewLocalizationPartitionKeyChange} placeholder='Enter Localization "en-US"...'></Form.Control></Col>
            <Col><Form.Control onChange={handleNewLocalizationRowKeyChange} placeholder='Enter Salesforce Field...'></Form.Control></Col>
            <Col><Button onClick={handleSaveNewLocalization} disabled={!saveEnabled} variant='success'>Save New Localization</Button></Col>
          </Row>
          <Row>
            <Col><div style={{color: 'darkred'}}>{newLocalizationPartitionKeyError}</div></Col>
            <Col><div style={{color: 'darkred'}}>{newLocalizationRowKeyError}</div></Col>
            <Col><div></div></Col>
          </Row>
        </Container>

      </div>
      <hr/>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Locale</th>
            <th>Salesforce Field</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {tr}
        </tbody>
      </Table>
      </>
    )
  }, [props.localizations, newLocalizationPartitionKeyError, handleNewLocalizationPartitionKeyChange, newLocalizationRowKeyError, handleNewLocalizationRowKeyChange, newLocalizationPartitionKey, newLocalizationRowKey, handleSaveNewLocalization, handleDeleteLocalization])

  return buildLocalizations
}

export default SalsifyLocalizationsComponent