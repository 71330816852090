import { useMutation, useQuery } from "@tanstack/react-query";
import { createColumnHelper, getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { Alert, Button, Container, OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";
import { apiRequest } from "../utility/axios";
import { useEffect, useMemo, useState } from "react";
import { useSpinner } from "../spinner/SpinnerContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notify } from "../notify/notify";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { AxiosError } from "axios";
import TsTable from "../components/TsTable";
import { fuzzyFilter, getProcessingPercentage } from "../utility/common";
import { DebouncedInput } from "../components/DebouncedInput";
import { JobStatus, OrderPipelineStatusRow, orderProcessingPercentageMap, PipelineStatusRes } from "./interfaces";
import ConfirmModal from "../components/ConfirmModal";
import { DateTime } from "luxon";

const OrderTracking = () => {
  const { toggleSpinner } = useSpinner();
  const columnHelper = createColumnHelper<OrderPipelineStatusRow>();

  //#region useState
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([
    { id: "timestamp", desc: false },
  ]);
  const [jobStatus, setJobStatus] = useState<JobStatus>({
    isRunning: false,
    processingStatus: "Pending",
    finalOutput: "",
    finalStatus: null,
    processingPrecentage: 0,
  });
  //#endregion useState

  //#region confirm modal
  const [showModal, setShowModal] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [modalCallback, setModalCallback] = useState(null);

  const handleExpediteOrders = () => {
    setModalMsg("Are you sure you want to expedite orders?");
    setModalCallback(() => handleConfirmExpedite);
    setShowModal(true);
  };

  const handleTerminateOrders = () => {
    setModalMsg("Are you sure you want to terminate expedite orders?");
    setModalCallback(() => handleConfirmTerminate);
    setShowModal(true);
  };

  const handleConfirmExpedite = async () => {
    setJobStatus((prev) => ({ ...prev, finalStatus: "started" }));
    const body = {
      expedite: true,
    };
    expediteMutation.mutate(body);
  };

  const handleConfirmTerminate = async () => {
    const body = {
      terminateWorkflow: true,
    };
    terminateWorkflow.mutate(body);
  };

  const handleCancel = () => {
    setShowModal(false);
  };
  //#endregion confirm modal

  const expediteMutation = useMutation({
    mutationFn: async (body: { expedite: boolean }) => {
      const response = await apiRequest<any>("POST", `/api/orderPipeline`, body);
      return response;
    },
    onMutate: () => {
      toggleSpinner(true);
    },
    onSuccess: async () => {
      setShowModal(false);
      await orderPipelineQuery.refetch();
      await orderPipelineStatusQuery.refetch();
      notify("success", "Processing");
    },
    onError: (error) => {
      notify("error", error.message);
      toggleSpinner(false);
    },
    onSettled: () => {
      toggleSpinner(false);
    },
  });

  const terminateWorkflow = useMutation({
    mutationFn: async (body: { terminateWorkflow: boolean }) => {
      const response = await apiRequest<any>("POST", `/api/orderPipeline`, body);
      return response;
    },
    onMutate: () => {
      toggleSpinner(true);
    },
    onSuccess: async (response) => {
      setShowModal(false);
      await orderPipelineQuery.refetch();
      notify("success", response.message);
    },
    onError: (error: AxiosError) => {
      if (error.response) {
        notify("error", JSON.stringify(error.response.data));
        console.error("Error response:", error.response.data);
      } else if (error.request) {
        // The request was made but no response was received
        notify("error", "No response from the server.");
        console.error("Error request:", error.request);
      }
    },
    onSettled: () => {
      toggleSpinner(false);
    },
  });

  const orderPipelineQuery = useQuery<OrderPipelineStatusRow[]>({
    queryKey: ["orderPipelineStatusTable"],
    queryFn: async () => {
      const response = await apiRequest<OrderPipelineStatusRow[]>("GET", `/api/orderPipeline?value=table`);
      return response;
    },
    // refetchInterval: 60000 * 0.5, // Auto refetch every 30 sec
  });

  const orderPipelineStatusQuery = useQuery<PipelineStatusRes>({
    queryKey: ["orderPipelineStatus"],
    queryFn: async () => {
      const response = await apiRequest<PipelineStatusRes>("GET", `/api/orderPipeline?value=status`);
      return response;
    },
    refetchInterval: 6000, // Auto refetch every 10 sec
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.orderPipelineId, {
        id: "orderPipelineId",
        cell: (info) => {
          return <div className="text-center">{info.getValue()}</div>;
        },
        header: () => <span>Order Pipeline Job ID</span>,
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.timestamp, {
        id: "timestamp",
        cell: (info) => {
          const formattedDate = DateTime.fromISO(info.getValue())
            .setZone("America/Chicago") // Convert to Central Time (CT)
            .toFormat("yyyy-MM-dd HH:mm:ss");
          return <div className="text-center">{formattedDate}</div>;
        },
        header: () => <span>Last Modified Time</span>,
        enableSorting: true,
      }),
      columnHelper.accessor((row) => row.purgeHistoryDeleteUri, {
        id: "purgeHistoryUri",
        cell: (info) => {
          return <div className="text-center">{info.getValue()}</div>;
        },
        header: () => <span>Purge History</span>,
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.terminatePostUri, {
        id: "terminateUri",
        cell: (info) => {
          return <div className="text-center">{info.getValue()}</div>;
        },
        header: () => <span>Terminate</span>,
        enableSorting: false,
      }),
    ],
    []
  );

  const table = useReactTable({
    data: orderPipelineQuery.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    enableMultiSort: true,
    enableSorting: true,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: "fuzzy", //apply fuzzy filter to the global filter (most common use case for fuzzy filter)
    filterFns: {
      fuzzy: fuzzyFilter, //define as a filter function that can be used in column definitions
    },
    state: {
      globalFilter,
      sorting,
    },
    onSortingChange: setSorting,
  });

  //#region useEffect
  useEffect(() => {
    toggleSpinner(orderPipelineQuery.isPending || orderPipelineQuery.isFetching || orderPipelineQuery.isLoading);
  }, [orderPipelineQuery]);

  useEffect(() => {
    const status = orderPipelineStatusQuery?.data?.status;
    const processingStatus = orderPipelineStatusQuery?.data?.processingStatus;
    const finalOutput = orderPipelineStatusQuery?.data?.finalOutput;

    if (status) {
      const isRunning = status === "Running" || status === "Pending";
      const processingPrecentage = getProcessingPercentage(processingStatus ?? "Pending", orderProcessingPercentageMap);
      const finalStatus = finalOutput && jobStatus.finalStatus === "started" ? status : jobStatus.finalStatus;

      // Check if we need to update the state to avoid unnecessary re-renders
      setJobStatus((prev) => {
        if (
          prev.isRunning === isRunning &&
          prev.processingStatus === processingStatus &&
          prev.processingPrecentage === processingPrecentage &&
          prev.finalOutput === finalOutput &&
          prev.finalStatus === finalStatus
        ) {
          return prev; // No need to update, prevent re-render
        }

        return {
          ...prev,
          isRunning,
          processingStatus,
          processingPrecentage,
          finalOutput,
          finalStatus,
        };
      });
    }
  }, [orderPipelineStatusQuery?.data?.status, orderPipelineStatusQuery?.data?.processingStatus, orderPipelineStatusQuery?.data?.finalOutput]);

  useEffect(() => {
    const handleJobStatus = async () => {
      if (jobStatus.finalStatus === "Completed") {
        if (jobStatus.finalOutput.message) {
          try {
            await orderPipelineQuery.refetch();
            notify("success", jobStatus.finalOutput?.message);
          } catch (error) {
            notify("error", `Failed to refetch: ${error.message}`);
          }
        }
        if (jobStatus.finalOutput.error) {
          notify("error", jobStatus.finalOutput.error);
        }
      }

      if (jobStatus.finalStatus === "Failed") {
        notify("error", jobStatus.finalOutput);
      }
    };

    handleJobStatus(); // Call the async function
  }, [jobStatus.finalStatus]);
  //#endregion useEffect

  if (!orderPipelineQuery) return <div>Loading...</div>;

  return (
    <>
      <Container fluid className="p-3 fs-4">
        <Alert variant="warning" className="fs-5">
          <strong>Order Workflow</strong>
        </Alert>
        <DebouncedInput
          value={globalFilter ?? ""}
          onChange={(value) => setGlobalFilter(String(value))}
          className="fs-4 mb-3 shadow-sm p-3"
          placeholder="Search"
          type="text"
          aria-describedby="search"
        />
        <div className="d-flex mb-3 w-100">
          <div className="d-flex gap-1 align-items-center">
            {jobStatus.isRunning && (
              <Button size="lg" variant="danger" onClick={handleTerminateOrders}>
                Terminate
              </Button>
            )}
            {!jobStatus.isRunning && (
              <Button size="lg" onClick={handleExpediteOrders}>
                Expedite
              </Button>
            )}
            {jobStatus.isRunning && (
              <OverlayTrigger overlay={<Tooltip className="fs-6">Active Expedite Job is running.</Tooltip>}>
                <FontAwesomeIcon icon={faCircleInfo} />
              </OverlayTrigger>
            )}
          </div>
          {jobStatus.isRunning && (
            <OverlayTrigger overlay={<Tooltip className="fs-6">{jobStatus.processingStatus}</Tooltip>}>
              <ProgressBar className="w-100 ms-2 my-auto" animated now={jobStatus.processingPrecentage} />
            </OverlayTrigger>
          )}
        </div>
        <TsTable table={table} />
      </Container>

      <ConfirmModal showModal={showModal} modalMsg={modalMsg} modalCallback={modalCallback} handleCancel={handleCancel} />
    </>
  );
};

export default OrderTracking;
