import { useEffect, useState } from 'react'
import { TableClient } from '@azure/data-tables'
import { DATA_SERVICES_BLOBS, DATA_SERVICES_TABLES } from '../common/common.constants'
import { InteractiveBrowserCredential } from '@azure/identity'
import { TENANT_ID, CLIENT_ID } from '../authConfig'
import { DateTime } from 'luxon'
import { BlobServiceClient } from '@azure/storage-blob'
import { SalsifyConfigurationCombinedTableRow, SalsifyConfigurationTableRow, SalsifyLocalizationsTableRow, SalsifySuffixesTableRow, SalsifyTableRow } from './salsify.interfaces'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { blobToString } from '../blobs/blobs.service'
import SalsifySuffixesComponent from './SalsifySuffixesComponent'
import SalsifySearchComponent from './SalsifySearchComponent'
import SalsifyLocalizationsComponent from './SalsifyLocalizationsComponent'
import SalsifyErrorsComponent from './SalsifyErrorsComponent'
import SalsifyConfigurationComponent from './SalsifyConfigurationComponent'
import { useSpinner } from '../spinner/SpinnerContext'


const SalsifyComponent = () => {
  const { showSpinner, hideSpinner } = useSpinner();
  /** Use to control the refresh */
  const [lastRefresh, setLastRefresh] = useState(undefined)
  /** Use to control the view loading */
  const [lastLoaded, setLastLoaded] = useState(undefined)
  const [toSalsify, setToSalsify]: [SalsifyTableRow[], any] = useState([])
  const [fromSalsify, setFromSalsify]: [SalsifyTableRow[], any] = useState([])
  const [localizations, setLocalizations]: [SalsifyLocalizationsTableRow[], any] = useState([])
  const [configs, setConfigs]: [SalsifyConfigurationTableRow[], any] = useState([])
  const [mpns, setMpns] = useState([])
  const [suffixes, setSuffixes]: [SalsifySuffixesTableRow[], any] = useState([])

  const credential = new InteractiveBrowserCredential({
    tenantId: TENANT_ID,
    clientId: CLIENT_ID,
    authorityHost: 'https://login.microsoft.com'
  })
  
  const salsifyTableService = new TableClient(DATA_SERVICES_TABLES, 'salsify', credential)
  const salsifyLocalizationsTableService = new TableClient(DATA_SERVICES_TABLES, 'salsifyLocalizations', credential)
  const salsifyConfigsTableService = new TableClient(DATA_SERVICES_TABLES, 'salsifyConfiguration', credential)
  const salsifySuffixesTableService = new TableClient(DATA_SERVICES_TABLES, 'salsifySuffixes', credential)
  const blobServiceClient = new BlobServiceClient(DATA_SERVICES_BLOBS, credential)

  useEffect(() => {
    const refresh = async () => {
      try {
        showSpinner()

        const toSalsifyRecords = []
        const fromSalsifyRecords = []
        const localizationsRecords = []
        const configRecords = []
        const suffixRecords = []

        const toSalsifyQuery = salsifyTableService.listEntities({queryOptions: {filter: `PartitionKey eq 'to-salsify' and status eq 'error'`}})
        for await(const record of toSalsifyQuery){
          toSalsifyRecords.push(record)
        }
        const fromSalsifyQuery = salsifyTableService.listEntities({queryOptions: {filter: `PartitionKey eq 'from-salsify' and status eq 'error'`}})
        for await (const record of fromSalsifyQuery){
          fromSalsifyRecords.push(record)
        }
        const localizationsQuery = salsifyLocalizationsTableService.listEntities()
        for await(const record of localizationsQuery){
          localizationsRecords.push(record)
        }
        const suffixesQuery = salsifySuffixesTableService.listEntities()
        for await(const record of suffixesQuery){
          suffixRecords.push(record)
        }
        const configQuery = salsifyConfigsTableService.listEntities()
        for await(const record of configQuery){
          configRecords.push(record)
        }
        const containerService = blobServiceClient.getContainerClient('storage')
        const blobClient = containerService.getBlobClient('product-service/products/salsify-mpns.json')
        const data = await blobClient.download()
        const downloaded = await blobToString(await data.blobBody)
        let parsed = JSON.parse(downloaded)
        parsed = parsed.sort()
        setMpns(parsed)
        setFromSalsify(fromSalsifyRecords)
        setToSalsify(toSalsifyRecords)
        setLocalizations(localizationsRecords)
        setConfigs(configRecords)
        setSuffixes(suffixRecords)
        setLastLoaded(DateTime.now().setZone('local').toLocaleString())
      }catch(error){
        console.log(error)
      }
      hideSpinner()
      
    }
    refresh()
  }, [lastRefresh])


  return (
    <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
      <h1>Salsify</h1>
      <hr/>
      <Tabs>
        <TabList>
          <Tab key='searchTab'>Search</Tab>
          <Tab key='errorsTab'>Errors [{fromSalsify?.length ?? 0 + toSalsify?.length ?? 0}]</Tab>
          <Tab key='localizationsTab'>Localizations</Tab>
          <Tab key='suffixesTab'>Suffixes</Tab>
          <Tab key='configsTab'>Configuration</Tab>

        </TabList>
        <TabPanel key='searchTabPanel'>
          <SalsifySearchComponent mpns={mpns} salsifyTableService={salsifyTableService} />
        </TabPanel>
        <TabPanel key='errorsTabPanel'>
          <SalsifyErrorsComponent fromSalsify={fromSalsify} toSalsify={toSalsify} />
        </TabPanel>
        <TabPanel key='localizationsTabPanel'>
          <SalsifyLocalizationsComponent localizations={localizations} salsifyLocalizationsTableService={salsifyLocalizationsTableService} setLastRefresh={setLastRefresh} />
        </TabPanel>
        <TabPanel key='suffixesTabPanel'>
          <SalsifySuffixesComponent salsifySuffixesTableService={salsifySuffixesTableService} setLastRefresh={setLastRefresh} suffixes={suffixes} />
        </TabPanel>
        <TabPanel key='configsTabPanel'>
          <SalsifyConfigurationComponent configs={configs as SalsifyConfigurationCombinedTableRow[]} salsifyConfigsTableService={salsifyConfigsTableService} setLastRefresh={setLastRefresh} />
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default SalsifyComponent
