import { Col, Container, Nav, Navbar, Offcanvas, Row } from "react-bootstrap"
import { useLocation } from "react-router-dom"
import { Nav_Item } from "../layout/interfaces" 
import { useUserContext } from "../user/UserContext";

interface SectionProps {
  title: string;
  items: Nav_Item[];
}

interface SideMenuProps {
  navList: Nav_Item[];
}

const highlight = (link: string) => {
  const currPath = useLocation().pathname
  return {color: currPath === link ? 'green' : 'blue'}
}

const Section: React.FC<SectionProps> = ({ title, items }) => {
  return (
    <>
      <strong>{title}</strong>
      {items && items.length > 0 ? (
        items.map((item, index) => 
          <Nav.Link href={item.link} key={index} style={highlight(item.link)}>
            <strong>{item.displayName}</strong>
          </Nav.Link>
        )) : (
          <div>
            <em>Request access from an admin...</em>
          </div>
        )
      }
    </>
  )
}

const SideMenu: React.FC<SideMenuProps> = ({ navList }) => {
  const {userData} = useUserContext()
  const roles = userData?.roles
  let dev: Nav_Item[] | null
  let marketing: Nav_Item[] | null
  let fulfillment: Nav_Item[] | null
  let purchasing: Nav_Item[] | null
  let hr: Nav_Item[] | null
  let products: Nav_Item[] | null
  let monitoring: Nav_Item[] | null
  let customerSupport: Nav_Item[] | null

  if (roles) {
    let displayNavList = navList.filter(list => roles.some(role => list.userLevel.includes(role)) || list.userLevel.length === 0)
    dev = displayNavList.filter(item => item.cat === 'dev')
    marketing = displayNavList.filter(item => item.cat === 'marketing')
    fulfillment = displayNavList.filter(item => item.cat === 'fulfillment')
    purchasing = displayNavList.filter(item => item.cat === 'purchasing')
    hr = displayNavList.filter(item => item.cat === 'hr')
    products = displayNavList.filter(item => item.cat === 'products')
    monitoring = displayNavList.filter(item => item.cat === 'monitoring')
    customerSupport = displayNavList.filter(item => item.cat === 'customer support')
  }

  return (
    <Navbar key='offcanvas' expand={false} bg='white' style={{width: '100%'}} collapseOnSelect>
      <Navbar.Toggle aria-controls={`basic-navbar-nav`}  className='navbar-toggler'/>

      <Navbar.Offcanvas placement='end'>

        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Crystal Ball</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body style={{fontSize: 'larger'}}>
          <Container>
            <Nav className="justify-content-end flex-grow-1 pe-3" navbarScroll>
              <Row>
                <Col>
                  <Nav.Link href='/' style={highlight('/')}>
                      <strong>Home</strong>
                  </Nav.Link>  
                  <hr/>
                  <Section title="Software Development" items={dev}/>
                  <hr/>
                  <Section title="Marketing" items={marketing}/>
                  <hr/>
                  <Section title="Fulfillment" items={fulfillment}/>
                  <hr/>
                  <Section title="Customer Support" items={customerSupport}/>

                </Col>

                <Col>
                  <Section title="Purchasing" items={purchasing}/>
                  <hr/>
                  <Section title="Human Resources" items={hr}/>
                  <hr/>
                  <Section title="Products" items={products}/>
                  <hr/>
                  <Section title="Monitoring" items={monitoring}/>
                </Col>
              </Row>

              <Row>
                <Col>
                  <hr/>
                  <Nav.Item style={{opacity: .5}}>
                    {userData?.friendlyUserName}
                  </Nav.Item>
                  <Nav.Link href='/logout' style={{color: 'blue'}}>
                    Logout
                  </Nav.Link>
                </Col>
              </Row>     
            </Nav>
          </Container>
        </Offcanvas.Body>
      </Navbar.Offcanvas>
    </Navbar>
  )
}

export default SideMenu