import { Form, Row, Col, Container } from "react-bootstrap"
import { useAmazonDeliveryMetricsContext } from "../../contexts/amazonDeliveryMetricsContext";
import { fedexDelayReason } from "../../utilities/fedexDelayReasons";

const AmazonDeliveryReasonFilterComponent = ({}) => {

  const { 
    currentData,
    setSelectedReason
  } = useAmazonDeliveryMetricsContext();

  const handleReasonChange = (e) => {
    setSelectedReason(e.target.value);
  };

  // Extract unique reasons from currentData and map to predefined reasons
  const matchedReasons = Array.from(
    new Set(
      currentData?.flatMap((item) =>
        item.scanEvents
          ?.map((event) => event.exceptionDescription?.toLowerCase())
          .filter((reason) => reason)
      )
    )
  ).map((reason) => {
    // Match with fedexDelayReason
    return fedexDelayReason.find(
      (fedexReason) => fedexReason.reason.toLowerCase() === reason
    );
  });

  const validReasons = matchedReasons.filter((reason) => reason);

  return(
  <Container>
    <Row className="justify-content-evenly">
      <Col>
        <Form>
          <Row className="mb-3">
            <Col>
              <Form.Group controlId="reason">
                <Form.Select onChange={handleReasonChange}>
                <option value="">Select Reason</option>
                {validReasons.map((reason) => (
                      <option key={reason.id} value={reason.id}>
                        {reason.reason}
                      </option>
                ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  </Container>
  )
}

export default AmazonDeliveryReasonFilterComponent;