import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface ConfirmModalProps {
  showModal: boolean;
  modalMsg: string;
  modalCallback: () => void;
  handleCancel: () => void;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({ showModal, modalMsg, modalCallback, handleCancel }) => {
  return (
    <Modal show={showModal} onHide={handleCancel} centered={true} backdrop="static" style={{ fontSize: '16px' }}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Action</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalMsg}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={modalCallback}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
