import { Navigate, useRoutes } from 'react-router-dom';
import BeaconComponent from '../beacon/BeaconComponent';
import HomeComponent from '../HomeComponent';
import CarrierInvoiceAuditComponent from '../audit/carriers/invoices/CarrierInvoiceAuditComponent';
import JobsComponent from '../jobs/JobsComponent';
import DesktopShipperComponent from '../desktopshipper/DesktopShipperComponent';
import OrdersComponent from '../orders/OrdersComponent';
import SalsifyComponent from '../salsify/SalsifyComponent';
import ProductHSCodesDebugComponent from '../debug/product-hs-codes/ProductHSCodesDebugComponent';
import AdminComponent from '../admin/AdminComponent';
import InventoryComponent from '../inventory/InventoryComponent';
import WNAComponent from '../wna/WNAComponent';
import ProtectedRoute from '../protectedRoute/ProtectedRoute';
import Layout from '../layout/Layout';
import { Nav_Item } from '../layout/interfaces';
import Logout from '../auth/Logout';
import Login from '../auth/Login';
import { useUserContext } from '../user/UserContext';
// import AuditComponent from '../audit/AuditComponent';
import { useEffect, useMemo } from 'react';
import { useSpinner } from '../spinner/SpinnerContext';
import AccountHealth from '../accountHealth/AccountHealth';
import NobodyShouldKnowThis from '../nobody_should_know_this/NobodyShouldKnowThis';
import AmazonPageSpeedViews from '../amazonPageSpeedViews/AmazonPageSpeedViews';
import BazaarvoiceOrdersComponent from '../bazaarvoice/BazaarvoiceOrdersComponent';
import ConfigComponent from '../configs/ConfigComponent';
import ShipDetailResetComponent from '../shipDetails/ShipDetailResetComponent';
import AmazonDeliveryMetricsWrapper from '../amazonDeliveryMetrics/pages/AmazonDeliveryMetrics';
import SupplierContactForm from '../supplierContactForm/SupplierContactForm';

// TODO: this can be pulled from AZ table: crystalBallRoles, hardcode for now SW 7/17/2024
export const navList: Nav_Item[] = [
  {
    link: '/beacon',
    displayName: 'Beacon',
    description: 'Software Development tool to monitor our Azure applications and view errors.',
    userLevel: ['beacon', 'admin'],
    cat: 'dev'
  },
  {
    link: '/admin',
    displayName: 'Administration',
    description: 'Can set roles for Crystal Ball users through the UI. Developers have access to all Crystal Ball apps.',
    userLevel: ['userAdmin', 'admin'],
    cat: 'dev'
  },
  {
    link: '/salsify',
    displayName: 'Salsify',
    description: 'Provides access to Salsify sync MPNs, suffixes, localizations, configuration, and view any errors.',
    userLevel: ['salsify', 'admin'],
    cat: 'marketing'
  },
  {
    link: '/desktopshipper',
    displayName: 'DesktopShipper',
    description: 'Provides a view into the state of our DesktopShipper orders and can manage the batch configurations.',
    userLevel: ['desktopshipper', 'admin'],
    cat: 'fulfillment'
  },
  {
    link: '/orders',
    displayName: 'Orders',
    description: '"Bifrost" View orders in production compared to the sandbox as well as warehouse routing details.',
    userLevel: ['orders', 'admin'],
    cat: 'fulfillment'
  },
  {
    link: '/carrierInvoiceAudit',
    displayName: 'Carrier Invoice Audit',
    description: 'Provides access to the carrier invoices audit screen where you can compare UPS and FedEx invoices with our Mission Control data.',
    userLevel: ['carrierinvoiceaudit', 'admin'],
    cat: 'fulfillment'
  },
  // {
  //   link: '/audit',
  //   displayName: 'Audit',
  //   description: 'Provides access to the audit screen.',
  //   userLevel: ['audit', 'admin'],
  //   cat: 'fulfillment'
  // },
  {
    link: '/productHsCodesDebug',
    displayName: 'HS Codes Debug',
    description: 'Provides access to debug Product HS Codes processed through the Mission Control Service.',
    userLevel: ['producthscodes', 'admin'],
    cat: 'purchasing'
  },
  {
    link: '/postings',
    displayName: 'Postings',
    description: 'Provides access to modify job postings on the SunnydazeDecor.com website careers page.',
    userLevel: ['postings', 'admin'],
    cat: 'hr'
  },
  {
    cat: 'customer support',
    description: 'Bazaarvoice Promotional Order management application.',
    displayName: 'Bazaarvoice Orders',
    link: '/bazaarvoiceOrders',
    userLevel: ['bazaarvoiceOrders', 'admin']
  },
  {
    link: '/configs',
    displayName: 'Configurations',
    description: 'Can modify configurations for various processes.',
    userLevel: ['configs', 'admin'],
    cat: 'dev'
  },
  {
    link: '/shipDetailReset',
    cat: 'fulfillment',
    description: 'Provide Order Number/IDs to reset ship details to various states for reprocessing.',
    displayName: 'Ship Detail Reset',
    userLevel: ['admin']
  },
  {
    link: '/inventory',
    displayName: 'Inventory Mapping',
    description: 'Search our inventory mappings by SKU to see the mappings and last time it was mapped for various integrations.',
    userLevel: [],
    cat: 'products'
  },
  {
    link: '/wna',
    displayName: 'Wholesale North America',
    description: 'Public application for external users with a client id and secret to list our wholesale products. Does not require an Azure login.',
    userLevel: [],
    cat: 'products'
  },
  {
    link: '/accountHealth',
    displayName: 'Account Health',
    description: 'Order Counts from Channels vs MC',
    userLevel: [],
    cat: 'monitoring'
  },
  {
    link: '/amazonPageSpeedViews',
    displayName: 'Amazon Page Speed Views',
    description: 'Page speed view Data from Amazon',
    userLevel: [],
    cat: 'monitoring'
  },
  {
    link: '/amazonDeliveryMetrics',
    displayName: 'Amazon Delivery Metrics',
    description: 'Delivery metrics from Amazon',
    userLevel: [],
    cat: 'monitoring'
  }
]

const Router = () => {
  const {userData, loading} = useUserContext();
  const { showSpinner, hideSpinner } = useSpinner();
  const roles = userData?.roles || [];

  useEffect(() => {
    loading ? showSpinner() : hideSpinner()
  }, [loading])

  const memoizedRoles = useMemo(() => roles, [roles]);

  const routes = [
    {
      path: '/',
      element: <ProtectedRoute element={<Layout navList={navList} />} />,
      children: [
        { path: '/', element: <HomeComponent navList={navList} /> },
        { path: 'beacon', element: <BeaconComponent /> },
        { path: 'postings', element: <JobsComponent /> },
        { path: 'desktopshipper', element: <DesktopShipperComponent /> },
        { path: 'admin', element: <AdminComponent /> },
        { path: 'salsify', element: <SalsifyComponent /> },
        { path: 'carrierInvoiceAudit', element: <CarrierInvoiceAuditComponent /> },
        { path: 'productHsCodesDebug', element: <ProductHSCodesDebugComponent /> },
        { path: 'bazaarvoiceOrders', element: <BazaarvoiceOrdersComponent /> },
        { path: 'orders', element: <OrdersComponent /> },
        { path: 'configs', element: <ConfigComponent/>},
        { path: 'shipDetailReset', element: <ShipDetailResetComponent></ShipDetailResetComponent>},
        // { path: 'audit', element: <AuditComponent /> },
        { path: 'inventory', element: <InventoryComponent /> },
        { path: 'wna', element: <WNAComponent /> },
        { path: 'accountHealth', element: <AccountHealth /> },
        { path: 'amazonPageSpeedViews', element: <AmazonPageSpeedViews /> },
        { path: 'amazonDeliveryMetrics', element: <AmazonDeliveryMetricsWrapper /> },
      ].filter(route => {
        const navItem = navList.find(item => item.link.replace('/', '') === route.path);
        if (!navItem) {
          return true; // Allow route if no navItem is found
        }
        let hasAccess = memoizedRoles.some(role => navItem.userLevel.includes(role)) || navItem.userLevel.length === 0;
        return hasAccess
      }),
    },
    { path: '/login', element: <Login /> },
    { path: '/logout', element: <Logout /> },
    { path: '/nobody_should_know_this', element: <NobodyShouldKnowThis /> },
    { path: '/supplier_contact_form', element: <SupplierContactForm /> },
    { path: '*', element: <Navigate to="/" replace /> } // Catch-all route to redirect to home
  ];

  const element = useRoutes(routes)

  if (loading) return <></>

  return element;
};

export default Router;
