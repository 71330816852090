import { toast } from 'react-toastify';

export const notify = (type: 'info' | 'success' | 'warning' | 'error' | 'default', message: string) => {
    toast(message, {
        type: type,
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

