import { TableClient } from '@azure/data-tables'
import { InteractiveBrowserCredential } from '@azure/identity'
import { TENANT_ID, CLIENT_ID } from '../authConfig'
import { DATA_SERVICES_TABLES } from '../common/common.constants'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DateTime } from 'luxon'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { Button, Card, Form } from 'react-bootstrap'
import { useSpinner } from '../spinner/SpinnerContext'
import { notify } from '../notify/notify'

const AdminComponent = () => {
  const { showSpinner, hideSpinner } = useSpinner();
  const [usersAndRoles, setUsersAndRoles] = useState(undefined)
  const [userMap, setUserMap] = useState(undefined)
  const [needsRefresh, setNeedsRefresh] = useState(undefined)

  const credential = new InteractiveBrowserCredential({
    tenantId: TENANT_ID,
    clientId: CLIENT_ID,
    authorityHost: 'https://login.microsoft.com'
  })
  const rolesTableClient = new TableClient(DATA_SERVICES_TABLES, 'crystalBallRoles', credential)

  useEffect(() => {
    const load = async () => {
      showSpinner()
      const userResults = rolesTableClient.listEntities({queryOptions: {filter: `PartitionKey eq 'user'`}})
      const roleResults = rolesTableClient.listEntities({queryOptions: {filter: `PartitionKey eq 'role'`}})
      const userRecords = []
      const roleRecords = []
      let userMapTemp = new Map()
      for await(const user of userResults){
        userRecords.push(user)
        if(!userMapTemp.has(user.rowKey)){
          userMapTemp.set(user.rowKey, user)
        }        
      }
      for await(const role of roleResults){
        roleRecords.push(role)
      }
      setUsersAndRoles({users: userRecords, roles: roleRecords})
      setUserMap(userMapTemp)
      hideSpinner()
    }
    load()
  }, [needsRefresh])

  const handleUpdateUser = useCallback((e) => {
    const splitId = e.target.id.split('___')
    const userRowKey = splitId[0]
    const fieldRowKey = splitId[1]
    const user = {...userMap.get(userRowKey)} as any

    if(fieldRowKey === 'roles'){
      const roleRowKey = splitId[2]
      const userRoles = JSON.parse(user.roles)
      if(e.target.checked){
        if(!userRoles.includes(roleRowKey)){
          userRoles.push(roleRowKey)
          user.roles = JSON.stringify(userRoles)
        }
      } else {
        const newUserRoles = []
        for(const role of userRoles){
          if(role === roleRowKey) continue
          newUserRoles.push(role)
        }
        user.roles = JSON.stringify(newUserRoles)
      }
    } else {
      user.username = e.target.value
    }
    const newMap = new Map(userMap)
    newMap.set(userRowKey, {...user})
    setUserMap(newMap)
  }, [userMap])

  const handleSaveUsers = useCallback((e) => {
    const save = async () => {
      let refresh = false
      for(const key of userMap.keys()){
        const user = userMap.get(key) as any
        const originalUser = usersAndRoles.users.find(u => u.rowKey === user.rowKey)
        if(JSON.stringify(user) !== JSON.stringify(originalUser)){
          await rolesTableClient.upsertEntity({...user}, 'Replace')
          notify('success', `${user.username ?? decodeURIComponent(user.rowKey)} updated successfully! Have them refresh Crystal Ball to see the changes.`)
          refresh = true
        }
      }
      if(refresh) setNeedsRefresh(DateTime.utc().toMillis())
    }
    save()
  }, [usersAndRoles, userMap])

  const buildUsersView = useMemo(() => {
    if(typeof usersAndRoles === 'undefined' || typeof userMap === 'undefined') return

    const userCards = []
    for(const key of userMap.keys()){
      const user = userMap.get(key)
      const originalUser = usersAndRoles.users.find(u => u.rowKey === user.rowKey)
      const hasChanged = JSON.stringify(user) !== JSON.stringify(originalUser)
      const userRoles = JSON.parse(user.roles)
      const isNew = userRoles.length === 1 && userRoles[0] === 'new'
      const roleChecks = []
      for(const role of usersAndRoles.roles){
        roleChecks.push(
          <>
          <Form.Check type='checkbox' label={(
            <>
            <div>{role.title}</div>
            <Form.Text className="text-muted">
              {role.description} {role.rowKey === 'admin' ? <em>This is set manually by developers.</em> : ''}
            </Form.Text>
            </>
          )} onChange={handleUpdateUser} key={`${user.rowKey}___roles___${role.rowKey}`} id={`${user.rowKey}___roles___${role.rowKey}`} checked={userRoles.includes(role.rowKey)} disabled={role.rowKey === 'admin'} value={role.rowKey}></Form.Check>
          </>
        )
      }
      userCards.push(
        <Card key={`${user.rowKey}___card`} style={{borderColor: isNew && !hasChanged ? 'red' : hasChanged ? 'blue' : ''}}>
          <Card.Header key={`${user.rowKey}___card_header`}>
            <Card.Title key={`${user.rowKey}___card_header_title`}>
              <div key={`${user.rowKey}___card_header_title_content`} style={{color: 'red'}}>{isNew ? 'NEW!' : ''}</div> {user.username ?? decodeURIComponent(user.rowKey)} {user.username ?  `(${decodeURIComponent(user.rowKey)})` : ''}
            </Card.Title>
          </Card.Header>
          <Card.Body key={`${user.rowKey}___card_body`} style={{fontSize: '1.2em'}}>
            <Form key={`${user.rowKey}___form`}>
              <Form.Group key={`${user.rowKey}___form_group_username`}>
                <Form.Label key={`${user.rowKey}___form_group_username_label`}>Username</Form.Label>
                <Form.Control key={`${user.rowKey}___form_group_username_control`} id={`${user.rowKey}___username`} onChange={handleUpdateUser} type="text" placeholder="Enter a readable username..." defaultValue={user.username ?? decodeURIComponent(user.rowKey)} />
                <Form.Text key={`${user.rowKey}___form_group_username_mutedtext`} className="text-muted">
                  Enter a readable username...
                </Form.Text>
              </Form.Group>
              <hr/>
              <Form.Group key={`${user.rowKey}___form_group_roles`}>
                <Form.Label key={`${user.rowKey}___form_group_roles_label`}>Roles</Form.Label>
                <Form.Group key={`${user.rowKey}___form_group_roles_checkboxes`} className="mb-3">
                  {roleChecks}
                </Form.Group>
              </Form.Group>
            </Form>
            <Button key={`${user.rowKey}_quick_save`} variant='success' onClick={handleSaveUsers} title='Saves all modified users.'>Quick Save</Button>
          </Card.Body>
        </Card>
      )
    }

    return userCards
  }, [usersAndRoles, userMap])

  const buildRolesView = useMemo(() => {
    if(typeof usersAndRoles === 'undefined') return

    const roleCards = []
    for(const role of usersAndRoles.roles){
      const usersWithRole = []
      for(const user of usersAndRoles.users){
        const userRoles = JSON.parse(user.roles)
        if(userRoles.includes(role.rowKey)){
          usersWithRole.push(user)
        }
      }

      roleCards.push(
        <>
        <Card key={`${role.rowKey}___card`}>
          <Card.Header key={`${role.rowKey}___card_header`}>
            <Card.Title key={`${role.rowKey}___card_header_title`}>{role.title}</Card.Title>
          </Card.Header>
          <Card.Body key={`${role.rowKey}___card_body`}>
            {role.description}
            {
              usersWithRole.length === 0 ? '' : (
                <>
                  <hr/>
                  <ul key={`${role.rowKey}___card_body_users`}>
                    {
                      usersWithRole.map((u) => {
                        return (
                          <li key={`${role.rowKey}___card_body_user___${u.rowKey}`}>{u.username ?? decodeURIComponent(u.rowKey)}</li>
                        )
                      })
                    }
                  </ul>
                </>
              )
            }

          </Card.Body>
        </Card>
        <hr/>
        </>
      )
    }

    return (
      <>
      {roleCards}
      {/* <Button title='Currently not supported.'>Add Role</Button> */}
      </>
    )
  }, [usersAndRoles])

  return(
    <div style={{paddingLeft: '15px', paddingRight: '15px'}}>
      <h1>Administration</h1>
      <hr/>
      <Tabs>
        <TabList>
          <Tab key='usersTab'>Users</Tab>
          <Tab key='rolesTab'>Roles</Tab>
        </TabList>
        <TabPanel key='usersTabPanel'>
          <Button variant='success' onClick={handleSaveUsers} title='Saves all modified users.'>Save</Button>
          <br/>
          <br/>
          {buildUsersView}<br/>
          <Button variant='success' onClick={handleSaveUsers} title='Saves all modified users.'>Save</Button>
        </TabPanel>
        <TabPanel key='rolesTabPanel'>
          {buildRolesView}
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default AdminComponent
