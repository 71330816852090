import { useQuery } from "@tanstack/react-query"
import { DeliveryMetrics } from "../../common/common.types"
import { apiRequest } from "../../utility/axios"

const useAmazonFedExDeliveryMetricsGet = (initialStartDate, initialEndDate) => {

  const startDate = initialStartDate
  const endDate = initialEndDate

  const { isLoading: isLoadingCurrent, isPending: isPendingCurrent, error: currentError, data: currentData, isFetching: isFetchingCurrent, refetch: refetchCurrent } = useQuery<DeliveryMetrics[]>({
    queryKey: [startDate, endDate],
    queryFn: async (): Promise<DeliveryMetrics[]> => {      
      const response = await apiRequest("GET", `/api/amazonFedExDeliveryMetrics`, null, {params: {startDate, endDate}})
      return response as DeliveryMetrics[]
    },
  })

  return {
    isLoadingCurrent,
    isPendingCurrent,
    currentError,
    currentData,
    isFetchingCurrent,
    refetchCurrent,
  }
}

export default useAmazonFedExDeliveryMetricsGet